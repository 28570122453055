//
/*
  - pick 2 item/period to change -> show double entry table of impact from these 2
*/

import * as modelAppHelpers from '../modelogic/helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:sensitivity')
// debugLog.disable()

/*
  @param
    .scenarioId // Mandatory
    .variableChanges: [array of positive percentage values]
    .instance
*/
export function calculateSensitivity (params = {}) {
  debugLog.log('calculateSensitivity', params)

  // We need a scenario to run the analysis on
  if (!params.scenarioId) return

  const sensitivityAnalysisParams = {
    changed: [],
    createdScenarios: []
  }

  sensitivityAnalysisParams.fullVariableChanges = defineValueChanges(params)

  // Ensure we have items
  const items = params.instance?.data?.dataset?.items
  if (!items) return

  // Go through each items and their formula, to find variables which are hard coded
  items.forEach(function (oneItem) {
    const itemScenarioData = oneItem.byScenario[params.scenarioId]

    itemScenarioData.c_stepFormulasChanges.forEach(function (oneStepFormulaChange, stepIndex) {
      const isHardCoded = !oneStepFormulaChange.c_refObj
      if (oneStepFormulaChange && isHardCoded) {
        // Hard Coded value

        sensitivityAnalysisParams.changed.push({
          temId: params.instance.helpers.getItemId(oneItem),
          stepIndex: stepIndex
        })

        const variableChangeParams = {
          itemId: params.instance.helpers.getItemId(oneItem),
          stepIndex: stepIndex,
          fullVariableChanges: sensitivityAnalysisParams.fullVariableChanges,
          instance: params.instance,
          scenarioId: params.scenarioId
        }
        const createdScenarios = runVariableChanges(variableChangeParams)
        sensitivityAnalysisParams.createdScenarios = sensitivityAnalysisParams.createdScenarios.concat(...createdScenarios)
      }
    })
  })

  return sensitivityAnalysisParams
}

function defineValueChanges (params) {
  if (!Array.isArray(params.variableChanges) || params.variableChanges.length === 0) {
    params.variableChanges = [0.01, 0.02, 0.03, 0.04, 0.05, 0.1, 0.2]
    // params.variableChanges = [0.05,0.2]
    // params.variableChanges = [0.1]
  }

  // Define the variable changes as both +/-
  let fullVariableChanges = []
  params.variableChanges.forEach(function (oneChange) {
    fullVariableChanges.push(-oneChange)
    fullVariableChanges.push(oneChange)
  })
  fullVariableChanges = fullVariableChanges.sort(function (v1, v2) { return v1 - v2 })
  debugLog.log('fullVariableChanges', fullVariableChanges)

  return fullVariableChanges
}

/*
  We create a scenario for each change (being a trio of item/step/variable)
*/
function runVariableChanges (params) {
  debugLog.log('runVariableChanges', params)

  const createdScenarios = []

  params.fullVariableChanges.forEach(function (oneVariableChange) {
    const createdScenario = createSensitivityScenario({
      itemId: params.itemId,
      stepIndex: params.stepIndex,
      scenarioIdBase: params.scenarioId,
      variableChange: oneVariableChange,
      instance: params.instance
    })
    createdScenarios.push(createdScenario)
  })

  return createdScenarios
}

/*
  Copies initial value, 2 levels
  params.itemId: item to update value of
  params.stepIndex: stepIndex of the formula to update
  params.scenarioIdBase
  params.variableChange
*/
function createSensitivityScenario (params) {
  // debugLog.log( 'createSensitivityScenario', params )

  const scenarioIdBase = params.scenarioIdBase
  const variableChange = params.variableChange
  const scenarioIdCopy = 'copy:' + params.itemId + ' @ ' + params.stepIndex + ' => ' + params.variableChange
  params.scenarioIdCopy = scenarioIdCopy
  // debugLog.log( scenarioIdBase, scenarioIdCopy, variableChange )

  // Ensure the expected item to change exists
  let changedItem
  if (params.itemId) {
    const items = params.instance.data.dataset.items
    changedItem = items.find(function (oneItem) {
      return params.instance.helpers.getItemId(oneItem) === params.itemId
    })
    if (!changedItem) {
      debugLog.warn('!! no item')
      return
    }
  }

  // Create a duplicate scenario, changing the hard coded value by the needed value
  const scenarioCopyParamsSource = {
    scenarioIdBase: scenarioIdBase,
    variableChange: variableChange,
    stepIndex: params.stepIndex,
    itemId: params.itemId,
    changedItem: changedItem // Reference to the changedItem being changed
  }

  const newScenario = {
    _id: scenarioIdCopy,
    name: changedItem.name + ' @ ' + params.stepIndex + ' => ' + params.variableChange,
    c_type: 'sensitivityValues',
    c_isAutoScenario: true,
    c_params: scenarioCopyParamsSource // Use the reference to object; should be ok
  }
  params.instance.update.scenario.add(newScenario)

  createCopiedScenarioFormulas(params)

  // Calculate the copied scenario
  params.instance.calculateValues({ scenarioId: scenarioIdCopy })

  return newScenario

  // Create a change analysis scenario comparing the values
  // calculateScenarioDeltas({
  //   scenarioId1: scenarioIdBase,
  //   scenarioId2: scenarioIdCopy,
  //   scenarioSourceParams: scenarioCopyParamsSource,
  //   instance: params.instance
  // })
}

/*
  @params
    .scenarioIdBase
*/
function createCopiedScenarioFormulas (params) {
  debugLog.log('createCopiedScenarioFormulas', params)
  // Find the formulas of the origin scenario
  const datasetValueFormulas = params.instance.data.dataset.valueFormulas
  const valueFormulas = datasetValueFormulas.filter(function (oneFormula) {
    return oneFormula.definition.scenarioId === params.scenarioIdBase
  })
  // debugLog.log('valueFormulas', valueFormulas)

  // Replicate every formula to copied scenario
  valueFormulas.forEach(function (oneValueFormula) {
    // debugLog.log('oneFormula', oneValueFormula)

    const copiedFormula = {
      c_isAutoFormula: true
    }
    Object.keys(oneValueFormula).forEach(function (key) {
      if (key.substr(0, 2) === 'c_') return // Do not copy the calculated properties
      if (key === 'definition') return // Do not copy the property with are from the prep
      if (key === '_scenario') return // Do not copy the original scenario ref
      if (key === '_id') return

      copiedFormula[key] = oneValueFormula[key]
    })

    // Mark the scenario of the copied formula to the copied scenario
    copiedFormula._scenario = params.scenarioIdCopy
    copiedFormula.tempId = modelAppHelpers.generateRandomString()
    copiedFormula._id = copiedFormula.tempId // _id needed for circular dependency checks
    copiedFormula.c_originId = oneValueFormula._id

    // Check if the formula is the one to update
    const formulaStartingPeriod = oneValueFormula.definition.first_period
    const periodSeries = params.instance.data.timeseries.periodSeries
    const isMatchingStepIndex = periodSeries.indexOf(formulaStartingPeriod) === params.stepIndex
    const isMatchingItem = oneValueFormula.definition.itemId === params.itemId

    if (isMatchingStepIndex && isMatchingItem) {
      const baseValue = +oneValueFormula.formula
      const updatedValue = baseValue * (1 + params.variableChange)
      copiedFormula.formula = '' + updatedValue // Make it a string
    }

    params.instance.formulas.add(copiedFormula)
  })
}
