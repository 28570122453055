
import * as helpers from '../frontend/helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelogic:helpers')
debugLog.disable()

// **** Helpers for the modelApp modules

// Allowing different property names
// mongoDB: ._id; manual datasets: .id
export function getItemId (oneItem) {
  return oneItem.itemId || oneItem._id || oneItem.id || oneItem.tempId
}

// Expose function
export function generateRandomString (numberCharacters = 8) {
  const string = helpers.generateRandomString(numberCharacters)
  return string
}
