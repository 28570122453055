
import * as axios from 'axios'

import * as userModule from './user.js'
import * as menuModule from '../page-modules/page-menu.js'
import * as socketHelper from './socket.js'

function ajaxRequestInterceptor () {
  axios.interceptors.request.use(function (config) {
    const socketId = socketHelper.socketId()
    // Set default Axios headers: only from the next request: we could put that in the socket module
    // axios.defaults.headers.common['socketid'] = socketId
    // console.log('socket, header socketid', socketId)

    if (socketId) {
      config.headers.socketid = socketId
    }

    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })
}

//
// Analyse AJAX responses
function ajaxInterceptor () {
  axios.interceptors.response.use(
    function (successRes) {
      return successRes
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          console.warn('Un-authenticated user')
          userModule.logoutUser()
          menuModule.refresh()
          return 401
        }
        if (error.response.status === 403) {
          console.warn('Un-authorised')
          // userModule.logoutUser()
          menuModule.refresh()
          return 403
        }
      }
      console.error('AJAX, error', error)
      return Promise.reject(error)
    }
  )
}

ajaxRequestInterceptor()
ajaxInterceptor()
