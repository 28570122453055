
import * as bootstrap from 'bootstrap'

// Modelapp, Web UI
import * as modelappWebUIModule from '../../modelapp-ui/web-ui.js'

// This Component
import * as modelSharingComponent from '../models/model-sharing.js'
import * as modelCopyComponent from '../../model-copy/copy-modal.js'
import * as modelTimeframe from '../../model-timeframe-picker/picker-modal.js'

// Data
import * as userModule from '../user.js' // Serves as userData Storage

// Other Components
import * as moduleItemFormulaEditor from '../itemformulas/itemformula-editor'
import * as moduleScenarioPicker from '../scenarios/scenario-picker'
import * as moduleItemEditor from '../items/item-editor'

import * as exportCsv from '../../export/export-csv.js'

// HTML templates
import htmlModelHeader from './header.html'
import htmlModelLayout from './layout.html'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('model:general')
// debugLog.disable()

export function updateModelHeader (modelData) {
  debugLog.log('updateModelHeader', modelData)
  if (!modelData) return

  const DOMmodelHeader = document.getElementById('modelHeader')
  if (!DOMmodelHeader) return
  DOMmodelHeader.innerHTML = htmlModelHeader

  // Update values in the header
  const modelName = modelData.name
  DOMmodelHeader.querySelector('#modelNameDiv').innerText = modelName

  const userData = userModule.userData()
  if (userData || modelData?.isPublic || modelData?.userRights?.canEdit) {
    DOMmodelHeader.querySelector('#copyModelDiv').toggleAttribute('hidden', false)
  }

  DOMmodelHeader.querySelector('#isPublicDiv').innerText = 'isPublic? ' + modelData.isPublic

  const modalTargetId = '#' + modelSharingComponent.modalDOMElementId
  DOMmodelHeader.querySelector('#buttonShareModel').setAttribute('data-bs-target', modalTargetId)
  modelSharingComponent.insertModal(modelData)

  const modalTargetIdTimeframe = '#' + modelTimeframe.modalDOMElementId
  DOMmodelHeader.querySelector('#buttonModelViewTimeframe').setAttribute('data-bs-target', modalTargetIdTimeframe)
  modelTimeframe.insertModal()

  const modalTargetIdCopy = '#' + modelCopyComponent.modalDOMElementId
  DOMmodelHeader.querySelector('#buttonCopyModel').setAttribute('data-bs-target', modalTargetIdCopy)
  modelCopyComponent.insertModal()

  // Hide un-needed button
  if (appData?.settings?.mode === 'local') {
    DOMmodelHeader.querySelectorAll('.fullMode').forEach(function (oneElement) {
      oneElement.toggleAttribute('hidden', true)
    })
  }

  listenExport()
}

//
function listenExport () {
  const DOMelement = document.getElementById('buttonExportCsv')
  if (!DOMelement) return

  const isListenerAdded = DOMelement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  DOMelement.addEventListener('click', function (clickEvent) {
    debugLog.log('export')

    const params = {
      modelInstance: appData.modelInstance
    }
    exportCsv.exportCsv(params)
  })

  DOMelement.setAttribute('isListenerAdded', true)
}

//
export function load (params = {}) {
  debugLog.log('model load')
  // debugLog.log('model module load ** similar to loadModel ??', params)

  const DOMelement = document.getElementById('content')
  DOMelement.innerHTML = htmlModelLayout

  if (!appData.modelInstance) return

  moduleItemEditor.loadEditor({
    instance: appData.modelInstance
  })
  moduleItemFormulaEditor.loadEditor()
  moduleScenarioPicker.load({
    targetElement: 'scenarioRow',
    instance: appData.modelInstance
  })
  updateModelHeader(appData.modelInstance.data.model)

  modelappWebUIModule.runAppCalculations()

  // load modal
  const myModalEl = document.getElementById('modelModal')
  const modalComponent = new bootstrap.Modal(myModalEl)
  appData.modelInstance.context.modelModalElement = modalComponent
}
