
import * as axios from 'axios' // Make a dedicated instance to avoid the app interceptor (which add headers rejected by AlphaVantage)

// HTML templates
import htmlListedSearch from './listed.html'

import * as alphaSettings from './listed-settings.js'
import * as listedCompaniesModel from './listed-model.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('listed:alphavantage:search')
// debugLog.disable()

const alphaVantageAxiosInstance = axios.create()
const ALPHAVANTAGE_API_KEY = alphaSettings.ALPHAVANTAGE_API_KEY
const endpoints = alphaSettings.endpoints

export function showPage () {
  debugLog.log('showPage', showPage)
  const containerElement = document.getElementById('content')
  if (!containerElement) return

  containerElement.innerHTML = htmlListedSearch

  searchFormListener()
}

function searchFormListener () {
  const formElement = document.getElementById('searchForm')
  if (!formElement) return

  formElement.addEventListener('submit', function (submitEvent) {
    submitEvent.preventDefault()

    debugLog.log('submit', submitEvent)

    const formElement = document.getElementById('searchTicker')
    const searchQuery = formElement.value
    debugLog.log('searched:', searchQuery)

    runSearch({
      searchQuery: searchQuery
    })
  })

  searchActions()
}

function searchActions () {
  const containerElement = document.getElementById('searchResults')

  containerElement.addEventListener('click', function (clickEvent) {
    const symbolElement = clickEvent.target.closest('.symbol')
    if (!symbolElement) return

    const symbol = symbolElement.innerText
    listedCompaniesModel.runPnLQuery({
      symbol: symbol
    })
  })
}

function runSearch (params) {
  debugLog.log('runSearch', params)
  const searchQuery = params.searchQuery

  let queryUrl = endpoints.search
  queryUrl = queryUrl.replace('[APIKEY]', ALPHAVANTAGE_API_KEY)
  queryUrl = queryUrl.replace('[SEARCH]', searchQuery)

  return alphaVantageAxiosInstance.get(queryUrl)
    .then(function (response) {
      debugLog.log(response)
      showSearchResults(response.data)
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

function showSearchResults (resultsData) {
  const containerElement = document.getElementById('searchResults')
  containerElement.innerHTML = '' // Reset

  const results = resultsData.bestMatches
  if (!results) return

  results.forEach(function (oneResult) {
    debugLog.log('oneResult', oneResult)
    showOneResult(oneResult)
  })
}

function showOneResult (resultData) {
  const containerElement = document.getElementById('searchResults')

  const htmlContentArray = []
  htmlContentArray.push('<hr>')
  Object.entries(resultData).forEach(function (oneProperty) {
    debugLog.log('oneProperty', oneProperty)
    if (oneProperty[0].includes('symbol')) {
      const targetUrl = '/app/model/listed:' + oneProperty[1]
      htmlContentArray.push('<h3 class="symbol"><a href="' + targetUrl + '">' + oneProperty[1] + '</a></h3>')
    } else {
      htmlContentArray.push('<br>' + oneProperty[0] + ':' + oneProperty[1])
    }
  })

  const resultElement = document.createElement('div')
  resultElement.innerHTML = htmlContentArray.join('')
  containerElement.append(resultElement)
}
