
import * as pageRouter from '../frontend/page-router.js'
import * as modelModule from '../frontend/models/control-models.js'

import htmlCopyModal from './copy-modal.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modal:copyModel')
// debugLog.disable()

export const modalDOMElementId = 'modelCopyModal'

export function insertModal () {
  debugLog.log('insertModal')
  copyModal()
}

function copyModal () {
  // Check if already done
  const DOMmodal = document.getElementById(modalDOMElementId)
  debugLog.log('DOMmodal', DOMmodal)
  if (DOMmodal) return DOMmodal

  return insertModalDOM()
}

function insertModalDOM () {
  // Insert the modal in the doc
  const DOMparent = document.getElementById('content')
  const modalElementContainer = document.createElement('div')
  modalElementContainer.innerHTML = htmlCopyModal
  DOMparent.appendChild(modalElementContainer)

  // Hide elements if needed
  if (appData?.settings?.mode === 'local') {
    modalElementContainer.querySelectorAll('.fullMode').forEach(function (oneElement) {
      oneElement.toggleAttribute('hidden', true)
    })
  }

  // Add listener
  confirmModelCopyListener()

  const DOMmodal = document.getElementById(modalDOMElementId)
  return DOMmodal
}

function confirmModelCopyListener () {
  const DOMelement = document.getElementById('modelCopyModalConfirm')
  if (!DOMelement) return

  DOMelement.addEventListener('click', function (event) {
    const copySettings = readCopyChoices()
    executeCopy(copySettings)
  })
}

function readCopyChoices () {
  const copySettings = {}

  const copyHardedCodedValuesDOM = document.getElementById('copyHardedCodedValues')
  copySettings.copyHardedCodedValues = copyHardedCodedValuesDOM.checked

  const copyFormulasDOM = document.getElementById('copyFormulas')
  copySettings.copyFormulas = copyFormulasDOM.checked

  return copySettings
}

function executeCopy (copySettings) {
  const params = {
    modelId: appData.modelInstance.data.model._id,
    copySettings: copySettings
  }
  modelModule.copyModel(params)
    .then(function (createdModel) {
      debugLog.log('copied?', createdModel)

      showReady({
        copiedModel: createdModel
      })
    })

  showLoading()
}

function showLoading () {
  const DOMmodal = document.getElementById(modalDOMElementId)
  debugLog.log('DOMmodal', DOMmodal)
  const modalBodyElement = DOMmodal.querySelector('.modal-body')
  modalBodyElement.innerHTML = 'Copy ongoing. Please wait a few seconds.'
}

function showReady (params) {
  const DOMmodal = document.getElementById(modalDOMElementId)
  debugLog.log('DOMmodal', DOMmodal)
  const modalBodyElement = DOMmodal.querySelector('.modal-body')

  // Go to the page of the copied model
  const createdModelId = params.copiedModel._id
  const nextPage = [pageRouter.settings.baseRoute, pageRouter.settings.oneModel].join('').replace(':modelId', createdModelId)
  debugLog.log('go to:', nextPage)

  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', nextPage)
  linkElement.innerText = 'Access now'

  modalBodyElement.innerHTML = 'Copied model is ready: '

  modalBodyElement.append(linkElement)
}
