
// Converts True-ish to TRUE
export function toBoolean (value) {
  if (value === true || value === 'true' || value === 1) return true

  // All other cases, returns false
  return false
}

export const periodicityMap = {
  day: {
    number: 1,
    timeUnit: 'day',
    format: 'DD MMM YYYY'
  },
  week: {
    number: 7,
    timeUnit: 'day',
    format: 'DD MMM YYYY'
  },
  month: {
    number: 1,
    timeUnit: 'month',
    format: 'MMM YYYY'
  },
  quarter: {
    number: 3,
    timeUnit: 'month',
    format: 'MMM YYYY'
  },
  year: {
    number: 12,
    timeUnit: 'month',
    format: 'YYYY'
  }
}
