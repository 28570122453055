
import * as envVar from '../env.js'
import * as userModule from '../frontend/user.js'

import htmlProfileMe from './profile-me.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('moduleUI:userProfile')
// debugLog.disable()

const contentDOMid = 'content'

export function load (params = {}) {
  createPageStructure()
}

//
function createPageStructure () {
  debugLog.log('createPageStructure')

  const DOMelement = document.getElementById(contentDOMid)
  if (!DOMelement) return

  DOMelement.innerHTML = htmlProfileMe

  const userData = userModule.userData()

  if (userData) {
    showUserProfile()
    showUserLoginMethods()
    showUserAPIAccess()

    const DOMelement = document.getElementById('loggedInUser')
    if (!DOMelement) return

    DOMelement.toggleAttribute('hidden', false)
  } else {
    debugLog.log('no loggedin user')
    const DOMelement = document.getElementById('loggedOutUser')
    if (!DOMelement) return

    DOMelement.toggleAttribute('hidden', false)
  }
}

//
async function generateApiToken () {
  const queryUrl = [envVar.apiUrl, 'user', 'me', 'apitoken'].join('/')

  axios.get(queryUrl)
    .then(function (response) {
      debugLog.log('generateApiToken response', response)
    })
    .catch(function (error) {
      debugLog.error('generateApiToken error', error)
    })
    .then(function () {
      debugLog.log('generateApiToken in any case')

      // For Simplicity: reload the page (instead of just updating the API Token)
      location.reload()
    })
}

// async function generateGuestUser () {
//   const queryUrl = [envVar.apiUrl, 'user', 'guest'].join('/')

//   axios.get(queryUrl)
//     .then(function (response) {
//       debugLog.log('generateGuestUser response', response)
//     })
//     .catch(function (error) {
//       debugLog.error('generateGuestUser error', error)
//     })
//     .then(function () {
//       debugLog.log('generateGuestUser in any case')

//       // For Simplicity: reload the page (instead of just updating the API Token)
//       location.reload()
//     })
// }

//
function showUserProfile () {
  const DOMelement = document.getElementById('userProfileProperties')
  if (!DOMelement) return

  const userData = userModule.userData()
  if (!userData) {
    debugLog.warn('no user data')
    return
  }

  const html = []
  html.push(['<li>', 'ID: ', userData._id, '</li>'].join(''))
  html.push(['<li>', 'Email: ', userData.email, '</li>'].join(''))
  html.push(['<li>', 'Name: ', userData.name, '</li>'].join(''))

  DOMelement.innerHTML = html.join('')
}

//
function showUserLoginMethods () {
  debugLog.log('showUserLoginMethods')
  const DOMelement = document.getElementById('googleLoginInfo')
  if (!DOMelement) return

  const userData = userModule.userData()
  debugLog.log('userData', userData)
  if (!userData) {
    debugLog.warn('no user data')
    return
  }

  const thirdpartyAuth = userData.thirdpartyAuth
  debugLog.log('thirdpartyAuth', thirdpartyAuth)

  // Search for Google
  if (thirdpartyAuth) {
    thirdpartyAuth.forEach(function (oneThirdParty) {
      if (oneThirdParty.providerName === 'google') {
        userData.isGoogleAuthenticated = true
        userData.googlePicUrl = oneThirdParty.providerData.picture
      }
    })
  }

  const html = []
  if (userData.isGoogleAuthenticated) {
    html.push('Connected')
    html.push('<img src="', userData.googlePicUrl, '"/>')
  } else {
    html.push('<a href="/auth/google" class="btn btn-primary"><span class="fab fa-google"></span> Connect Google</a>')
  }

  DOMelement.innerHTML = html.join('')
}

//
function showUserAPIAccess () {
  const DOMelement = document.getElementById('APIaccessInfo')
  if (!DOMelement) return

  const userData = userModule.userData()
  if (!userData) {
    debugLog.warn('no user data')
    return
  }

  const html = []
  if (userData.isGuest) {
    html.push('Connect with Google to access the API')
  } else {
    html.push(['API Token (Keep secret):', userData.apiToken].join(''))
    html.push('<p><span class="btn btn-sm btn-primary" id="userAPIaccessGenerate">Generate new API Token</span>')
  }

  html.push('<p>Access the API Documentation on <a href="https://www.notion.so/API-909e5e0ce6f74baea0c2c6e226f4f645" target="_blank">Notion</a></p>')

  DOMelement.innerHTML = html.join('')

  addListener()
}

//
function addListener () {
  // Create Model
  const generateButton = document.getElementById('userAPIaccessGenerate')
  if (generateButton) {
    generateButton.addEventListener('click', function (event) {
      debugLog.log('click generateButton')

      generateApiToken()
    })
  }
}
