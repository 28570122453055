
import * as bootstrap from 'bootstrap'
import dayjs from 'dayjs'

import * as generalHelpers from '../general-helpers.js'

import * as modelappWebUIModule from '../modelapp-ui/web-ui.js'
import * as modelModule from '../frontend/models/control-models.js'

import htmlTimeframePicker from './picker-modal.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modal:timeframePicker')
// debugLog.disable()

export const modalDOMElementId = 'modelViewTimeframeModal'

export function insertModal () {
  pickerModal()
}

function pickerModal () {
  // Check if already done
  const DOMmodal = document.getElementById(modalDOMElementId)
  debugLog.log('DOMmodal', DOMmodal)
  if (DOMmodal) return DOMmodal

  return insertModalDOM()
}

function insertModalDOM () {
  // Insert the modal in the doc
  const DOMparent = document.getElementById('content')
  const modalElementContainer = document.createElement('div')
  modalElementContainer.innerHTML = htmlTimeframePicker
  DOMparent.appendChild(modalElementContainer)

  // Hide elements if needed
  if (appData?.settings?.mode === 'local') {
    modalElementContainer.querySelectorAll('.fullMode').forEach(function (oneElement) {
      oneElement.toggleAttribute('hidden', true)
    })
  }

  const DOMmodal = document.getElementById(modalDOMElementId)

  // Initialise the Element as a Bootstrap Modal
  const modalComponent = new bootstrap.Modal(DOMmodal)
  if (!modalComponent) {
    debugLog.warn('modalComponent was not available')
    return
  }

  DOMmodal.addEventListener('shown.bs.modal', function (event) {
    onModalOpen()
  })

  // Add listener
  validatePickListener()
  listenTimeframeChanges()

  return DOMmodal
}

function onModalOpen () {
  // Access the timeframe settings
  const requestedSettings = appData.modelInstance.data.timeseries.requestedSettings
  const usedSettings = appData.modelInstance.data.timeseries.usedSettings

  // Show the current starting value
  const viewSettingsStarting = requestedSettings.startingView || usedSettings.startingView
  const DOMtimeframeViewStarting = document.getElementById('timeframeViewStarting')
  DOMtimeframeViewStarting.value = viewSettingsStarting

  // Show the current number of values
  const viewSettingsPeriods = requestedSettings.periodsView || usedSettings.periodsView
  const DOMtimeframeViewNumberPeriods = document.getElementById('timeframeViewNumberPeriods')
  DOMtimeframeViewNumberPeriods.value = viewSettingsPeriods

  const DOMtimeframeViewPeriodicity = document.getElementById('pickPeriodicity')

  // Ensure no active periodicity
  const DOMoptions = DOMtimeframeViewPeriodicity.querySelectorAll('option')
  DOMoptions.forEach(function (oneOption) {
    oneOption.toggleAttribute('selected', false)
  })

  // Show the current periodicity
  const viewSettingsPeriodicity = requestedSettings.periodicity || usedSettings.periodicity

  const DOMoption = DOMtimeframeViewPeriodicity.querySelector('option[value=' + viewSettingsPeriodicity + ']')
  DOMoption.toggleAttribute('selected', true)

  checkImpliedEndPeriod()
}

function validatePickListener () {
  const DOMelement = document.getElementById('timeframePickerValue')
  if (!DOMelement) return

  DOMelement.addEventListener('click', function (event) {
    const periodSettings = readTimeframeChoices()
    updateTimeframe(periodSettings)
  })

  const DOMelementSave = document.getElementById('timeframePickerValueSave')
  if (!DOMelementSave) return

  DOMelementSave.addEventListener('click', function (event) {
    const periodSettings = readTimeframeChoices()
    updateTimeframe(periodSettings)
    saveTimeframeAsDefault(periodSettings)
  })
}

function listenTimeframeChanges () {
  const DOMtimeframeViewPeriodicity = document.getElementById('pickPeriodicity')
  DOMtimeframeViewPeriodicity.addEventListener('change', function () {
    checkImpliedEndPeriod()
  })

  const DOMtimeframeViewStarting = document.getElementById('timeframeViewStarting')
  DOMtimeframeViewStarting.addEventListener('change', function () {
    checkImpliedEndPeriod()
  })

  const DOMtimeframeViewNumberPeriods = document.getElementById('timeframeViewNumberPeriods')
  DOMtimeframeViewNumberPeriods.addEventListener('change', function () {
    checkImpliedEndPeriod()
  })
}

function checkImpliedEndPeriod () {
  const periodSettings = readTimeframeChoices()

  const mappedPeriodicity = generalHelpers.periodicityMap[periodSettings.periodicity]
  if (!mappedPeriodicity) return

  const numberPeriods = (periodSettings.periods - 1) * mappedPeriodicity.number

  const startDate = dayjs(periodSettings.starting, 'YYYY-MM-DD')
  const endDate = startDate.add(numberPeriods, mappedPeriodicity.timeUnit)
  const formattedEndDate = endDate.format(mappedPeriodicity.format)
  debugLog.log(startDate, endDate)

  const feedbackElement = document.getElementById('timeframePeriodEndImplied')
  feedbackElement.innerText = 'ie. until ' + formattedEndDate
  return formattedEndDate
}

/*
  starting: starting value (YYYY-MM-DD; or relative?)
  periodicity: month, etc...
  periods: 12 for eg
*/
function updateTimeframe (periodSettings) {
  appData.modelInstance.updateTimeSeriesSettings(periodSettings)
  modelappWebUIModule.runAppCalculations()
}

function readTimeframeChoices () {
  const periodSettings = {}

  const DOMtimeframeViewPeriodicity = document.getElementById('pickPeriodicity')
  periodSettings.periodicity = DOMtimeframeViewPeriodicity.selectedOptions[0].value

  const DOMtimeframeViewStarting = document.getElementById('timeframeViewStarting')
  periodSettings.starting = DOMtimeframeViewStarting.value

  const DOMtimeframeViewNumberPeriods = document.getElementById('timeframeViewNumberPeriods')
  periodSettings.periods = parseInt(DOMtimeframeViewNumberPeriods.value)

  return periodSettings
}

function saveTimeframeAsDefault (periodSettings) {
  const params = {
    modelId: appData.modelInstance.data.model._id,
    periodSettings: periodSettings
  }
  modelModule.updateModel(params).then(function () {
    debugLog.log('UPDATED')
  })
}
