
import * as data from './data.js'
import * as modelAppHelpers from './helpers.js'
import * as prepFormulas from './prep-formula.js'
import * as evalIndex from './eval-index.js'
import * as periods from './periods.js'
import * as logicDimensions from './logic-dimensions.js'
import * as sensitivityModule from './logic-sensitivity.js'
import * as autoCompare from './auto-compare.js'
import * as linkedFinancialData from './linked-financial-data.js'
// ***

// Should remove this
import * as envVar from '../env.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:parentInstance')
// debugLog.disable()

/*
  ... load data
  ... update specific data: frontend interaction, backend sync, sockets...

  ... run calculations
    ... for a scenario
    ... for all scenarios of model
    ... when changing some data

  so
  modelApp = new XX()
  await modelApp.initModel(modelDataReceived)

  // NOT DONE
  modelApp.loadFromDB() // Refresh data for modelId
  modelApp.setTimeSeries() // -> define the timeseries to calculate later on
  modelApp.identifyDimensions()

  this
    .data
      .model: {}, // The model returned from DB: includes base model data, and related _items, etc...
      .dataset: {
        items: [],
        valueFormulas: [],
        scenarios: []
      }
      .timeseries: {
        periodSeries,
        usedSettings,
        requestedSettings
      }
      .dimensionData: {}
*/
export function Modelapp () {
  debugLog.log('load Modelapp')
  // this.data = {
  //   dimensions: {}
  // }

  const instance = this

  const instanceData = {}
  this.data = instanceData

  // Object to store adhoc data
  this.context = {}

  // Store the apiURL; this should be moved out and set within the context
  this.context.apiUrl = envVar.apiUrl

  // async function to allow loading of linked data
  // call as await instance.initModel
  this.initModel = async function (params = {}) {
    debugLog.log('initModel', params)

    // Stores the data
    const storedData = data.storeReceivedData(params.modelData)
    instanceData.model = storedData.model
    instanceData.dataset = storedData.dataset

    instanceData.evaluationRuns = 0 // Keep track of number of calculation done and ensure we calculate items only the number of times they are needed

    // Generate initial timeSeries
    const timeSeriesSettings = instanceData.model?.periodSettings || {}
    this.updateTimeSeriesSettings(timeSeriesSettings)

    // Identify initial dimensions
    this.identifyDimensions()

    // Load linked Data
    await linkedFinancialData.loadLinkedData({ instance: instance })
  }

  instanceData.stats = {
    calculateValuesCalls: 0
  }

  this.calculateValues = function (params) {
    debugLog.log('calculateValues')
    instanceData.stats.calculateValuesCalls++
    if (!params.scenarioId) {
      debugLog.warn('missing scenarioId')
      return
    }

    prepFormulas.associateFormulasToItems({
      instance: instance,
      scenarioId: params.scenarioId
    })

    instanceData.evaluationRuns++
    evalIndex.calculateValues({
      instance: instance,
      scenarioId: params.scenarioId
    })

    instanceData.isCalculatedDataSyncedWithTimeSeries = true
    instanceData.isCalculatedDataSyncedWithItems = true
    instanceData.isCalculatedDataSyncedWithFormulas = true
  }

  this.calculateSensitivity = function (params) {
    const instance = this
    debugLog.log('calculateSensitivity', params)
    const sensitivityParams = {
      instance: instance,
      scenarioId: params.scenarioId,
      variableChanges: params.variableChanges // Optional
      // targetItemId: params.targetItemId // Optional
    }
    const sensitivityReturned = sensitivityModule.calculateSensitivity(sensitivityParams)

    sensitivityReturned.createdScenarios.forEach(function (oneScenario) {
      autoCompare.calculateScenarioDeltas({
        scenarioId1: params.scenarioId,
        scenarioId2: oneScenario._id,
        scenarioTypes: 'sensitivityDelta',
        isCalculateToRun: true,
        instance: instance
      })
    })

    // TODO: now that we have the changes and delta calculated, should we extract the sensitivity here?
    // Currently done in the UI module; with the last of period step: targetStepIndex

    // TODO: same for params.targetItemId currently not used

    return sensitivityReturned

    // to show the results:
    //  showSensitivityTable(params)
  }

  this.autoCompare = function (params) {
    debugLog.log('autoCompare', params)
    const autoScenarioParams = {
      scenarioId1: params.scenarioId1,
      periodRef1: params.periodRef1,
      function1: params.function1,
      scenarioId2: params.scenarioId2,
      periodRef2: params.periodRef2,
      baseItem: params.baseItem,
      formulaType: params.formulaType
    }
    let autoScenario = instance.helpers.findAutoScenario(autoScenarioParams)
    debugLog.log('autoCompare autoScenario', autoScenario)
    if (autoScenario) {
      debugLog.log('autoCompare : remove')
      // Avoid multiple equivalent auto scenarios
      instance.update.scenario.remove(autoScenario)
    }

    autoScenarioParams.scenarioTypes = 'autoCompare'
    autoScenarioParams.forcedFormat = params.forcedFormat
    autoScenarioParams.instance = instance
    autoScenario = autoCompare.calculateScenarioDeltas(autoScenarioParams)
    debugLog.log('autoCompare autoScenario created', autoScenario)

    if (params.runCalculations) {
      debugLog.log('runCalculations')
      instance.calculateValues({
        scenarioId: autoScenario._id
      })
    }
    return autoScenario
  }

  /*
    Used when we decide to look at a different timeseries
  */
  this.updateTimeSeriesSettings = function (params) {
    debugLog.log('updateTimeSeriesSettings', params)
    instanceData.timeseries = periods.generateSeries(params, this)
    instanceData.isCalculatedDataSyncedWithTimeSeries = false // Marker that data are not updated anymore
  }

  /*
    Used when dimensions might have changed
  */
  this.identifyDimensions = function () {
    instanceData.dimensionData = logicDimensions.identifyDimensions({
      instance: instance
    })
  }

  /*
    Manipulation of items
  */
  this.items = {
    add: function (item) {
      data.itemAdd({
        dataset: instanceData.dataset,
        item: item
      })
      instanceData.isCalculatedDataSyncedWithItems = false
    },
    remove: function (item) {
      data.itemRemove({
        dataset: instanceData.dataset,
        item: item
      })
      instanceData.isCalculatedDataSyncedWithItems = false
    },
    update: function () {
    // TODO; Instead of refreshDataset
      instanceData.isCalculatedDataSyncedWithItems = false
    }
  }

  /*
    Manipulation of formulas
  */
  this.formulas = {
    add: function (itemFormula) {
      instanceData.isCalculatedDataSyncedWithFormulas = false
      data.formulaAdd({
        dataset: instanceData.dataset,
        itemFormula: itemFormula
      })
    },
    remove: function (itemFormula) {
      data.itemFormulaRemove({
        dataset: instanceData.dataset,
        itemFormula: itemFormula
      })
      instanceData.isCalculatedDataSyncedWithItems = false
    },
    update: function () {
      // TODO instead of refreshDataset
      instanceData.isCalculatedDataSyncedWithFormulas = false
    }
  }

  this.update = {
    scenario: {
      add: function (newScenario) {
        instanceData.dataset.scenarios.push(newScenario)
      },
      remove: function (scenario) {
        data.scenarioRemove({
          dataset: instanceData.dataset,
          scenario: scenario
        })
      }
    }
  }

  // Expose sub modules functions
  this.helpers = {
    getItemId: modelAppHelpers.getItemId,
    getPeriodAbsolute: function (params) {
      const periodsParams = {
        periodSeries: instance.data.timeseries.periodSeries,
        stepIndex: params.stepIndex
      }
      const periodAbs = periods.getPeriodAbsolute(periodsParams)
      return periodAbs
    },
    moveDate: function (dateYYYYMMDD, periodicity, whichWay) {
      return periods.moveDate(dateYYYYMMDD, periodicity, whichWay)
    },
    endOfMonth: function (dateYYYYMMDD) {
      return periods.endOfMonth(dateYYYYMMDD)
    },
    prepareFormulaObj: data.prepareFormulaObj,
    findAutoScenario: function (params) {
      const params2 = Object.assign({}, params)
      params2.instance = instance
      return data.findAutoScenario(params2)
    },
    evaluateItemStepValue: evalIndex.evaluateItemStepValue,

    // Helpers related to dimensions
    dimensions: {
      getDimensionValue: function (params) {
        return logicDimensions.getDimensionValue(params)
      }
    }
  }
}
