
import * as editorComponent from './index.js'
import * as editorSubPicker from './sub-picker.js'

export function includePickerPeriodElement (params) {
  console.log('includePickerPeriodElement', params)

  const pickerElement = document.createElement('span')
  pickerElement.classList.add('editor', 'pickerSection', 'pickerPeriod')
  pickerElement.setAttribute('contentEditable', true)

  pickerElement.innerText = params.editorData.subs[params.subId].valuePeriods
  params.editorSubElement.append(pickerElement)

  const listenerParams = Object.assign({
    pickerElement: pickerElement
  }, params)

  pickerListener(listenerParams)
}

//
//
function showPopup (params = {}) {
  const editorSubElement = params.editorSubElement
  if (!editorSubElement) return

  // Ensure there is, and just 1, a popup element
  let pickerPopupElement = editorSubElement.querySelector('.pickerPeriodPopup')
  if (!pickerPopupElement) {
    pickerPopupElement = createElement(params)
  }
}

//
//
function createElement (params = {}) {
  editorSubPicker.removePreviousPickersPopup()

  const picker = document.createElement('div')
  picker.classList.add('pickerPopup', 'pickerPeriodPopup')

  const content = []
  content.push('<div>One previous period: 0, -1, -2</div>')
  content.push('<div>Range of previous periods: -3:0</div>')
  content.push('<div>Absolute periods: @1</div>')
  content.push('<div class="close">Close</div>')

  picker.innerHTML = content.join('')
  params.editorSubElement.append(picker)

  return picker
}

//
//
function pickerListener (params = {}) {
  console.log('pickerListener', params)
  const pickerElement = params.pickerElement
  // const editorSubElement = params.editorSubElement
  // const subId = editorSubElement.getAttribute('d-subId')

  pickerElement.addEventListener('focus', function (focusEvent) {
    console.log('period picker focus')
    // Show popup
    showPopup(params)
  })

  pickerElement.addEventListener('keyup', function (keyEvent) {
    console.log('keyup')

    // Stores the value in the Data store
    const typed = pickerElement.innerText
    // subData.valuePeriods = typed
    params.editorData.subs[params.subId].valuePeriods = typed

    editorComponent.readEditorContent({ editorData: params.editorData })
  })
}
