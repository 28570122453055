
// https://www.chartjs.org/docs/latest/getting-started/integration.html
import Chart from 'chart.js/auto'

// import * as moduleScenarioPicker from '../frontend/scenarios/scenario-picker.js'
import * as colorHelpers from '../color-helpers.js'

import * as chartWithxAxis from './chart-withxaxis.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('webui:chart')
// debugLog.disable()

const settings = {
  chartCanvasId: 'chartCanvas',
  chartValues: {}
}

/*
  @params
    .item
    .instance
*/
export function showChart (params) {
  debugLog.log('showChart', params)
  generateChartContainer(params)

  const modelInstance = params.instance

  // Labels colors
  const baseColor = [0, 0, 0]
  const baseColorString = baseColor.join(',')

  const labelColors = []
  modelInstance.data.timeseries.periodSeriesViewIsFuture.forEach(function (oneIsFuture) {
    const opacity = oneIsFuture === false ? 0.9 : 0.6
    labelColors.push(['rgba(', baseColorString, ',', opacity, ')'].join(''))
  })

  const chartOptions = {
    type: 'bar',
    data: {
      labels: modelInstance.data.timeseries.periodSeriesView,
      datasets: []
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            // display: false,
            drawOnChartArea: false,
            drawTicks: true
          }
        },
        x: {
          // backgroundColor: '#F2FEA3', // Color of the axis label area
          grid: {
            display: false
          },
          // weight: 10, // Distance from axis
          // title: { // Title of the X-axis
          //   display: true
          // },
          ticks: {
            color: labelColors // Color of the text
            // display: true // Display x axis label
          }
        }
      }
    }
  }

  // Add the bars
  if (params.item) {
    const barDataset = generateBarDataset(params)
    chartOptions.data.datasets.push(barDataset)

    settings.chartValues = {
      type: 'item',
      item: params.item
    }
  }

  if (params.itemDimensionData) {
    generateMultiItemsChart({
      instance: modelInstance,
      chartOptions: chartOptions,
      itemDimensionData: params.itemDimensionData
    })

    settings.chartValues = {
      type: 'multiItems',
      itemDimensionData: params.itemDimensionData
    }
  }

  if (params.includeTrend) {
    const trendlineDataset = generateTrendlineDataset(params)
    if (trendlineDataset) {
      chartOptions.data.datasets.push(trendlineDataset)
      chartOptions.options.scales.yRight = {
        beginAtZero: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
          drawTicks: true
        }
      }
    }
  }

  const ctx = document.getElementById(settings.chartCanvasId)
  const myChart = new Chart(ctx, chartOptions)
  debugLog.log(myChart)
  showElement()
}

function generateMultiItemsChart (params) {
  debugLog.log('generateMultiItemsChart', params)
  const modelInstance = params.instance
  const chartOptions = params.chartOptions

  // find the items to cover
  const itemDimensionData = params.itemDimensionData

  modelInstance.data.dataset.items.forEach(function (oneItem, arrayIndex) {
    // debugLog.log('oneItem', oneItem)
    if (!oneItem.dimensions) return

    const targetDimension = oneItem.dimensions.find(function (oneDimension) {
      return oneDimension.name === itemDimensionData.name
    })

    if (!targetDimension) return
    if (!(targetDimension.value === itemDimensionData.value)) return

    const color = colorHelpers.generateColor() || '369f96'
    const colorRGB = colorHelpers.colorHexToRGBarray(color)
    // debugLog.log('colorRGB', colorRGB)

    const oneChartDataset = generateLineDataset({
      instance: modelInstance,
      item: oneItem,
      label: oneItem.name,
      scenarioId: appData.settings.view.scenarioId,
      baseColor: colorRGB, // [13, 110, 253],
      hideZeros: true
    })
    chartOptions.data.datasets.push(oneChartDataset)
  })
}

function generateLineDataset (params) {
  debugLog.log('generateLineDataset', params)
  const modelInstance = params.instance
  const item = params.item
  const scenarioId = params.scenarioId
  const itemScenarioData = item.byScenario[scenarioId]

  const viewOffsetIndex = modelInstance.data.timeseries.periodSeriesViewOffset
  let itemValues = itemScenarioData.c_calculatedValues.slice(viewOffsetIndex)

  if (params.hideZeros) {
    itemValues = itemValues.map(function (oneValue) {
      return Number.isFinite(oneValue) ? oneValue : null
    })
  }

  const baseColor = params.baseColor || [13, 110, 253]
  const baseColorString = baseColor.join(',')

  const backgroundColor = []
  const borderColor = []
  modelInstance.data.timeseries.periodSeriesViewIsFuture.forEach(function (oneIsFuture) {
    const opacity = oneIsFuture === false ? 1 : 0.4
    backgroundColor.push(['rgba(', baseColorString, ',', opacity, ')'].join(''))
    borderColor.push(['rgba(', baseColorString, ',', opacity, ')'].join(''))
  })

  const dataset = {
    label: params.label,
    type: 'line',
    data: itemValues,
    yAxisID: params.yAxisId || 'y',
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderWidth: 1,
    pointRadius: 1,
    fill: {
      target: 'origin',
      above: ['rgba(', baseColorString, ',', 0.02, ')'].join(''),
      below: ['rgba(', baseColorString, ',', 0.2, ')'].join('')
    } // true
  }

  return dataset
}

function generateBarDataset (params) {
  const modelInstance = params.instance
  const item = params.item
  const scenarioId = appData.settings.view.scenarioId
  const itemScenarioData = item.byScenario[scenarioId]

  const viewOffsetIndex = modelInstance.data.timeseries.periodSeriesViewOffset
  const itemValues = itemScenarioData.c_calculatedValues.slice(viewOffsetIndex)

  const baseColor = [13, 202, 240]
  const baseColorString = baseColor.join(',')

  const backgroundColor = []
  modelInstance.data.timeseries.periodSeriesViewIsFuture.forEach(function (oneIsFuture) {
    const opacity = oneIsFuture === false ? 1 : 0.4
    backgroundColor.push(['rgba(', baseColorString, ',', opacity, ')'].join(''))
  })

  const dataset = {
    label: item.name,
    data: itemValues,
    backgroundColor: backgroundColor,
    borderColor: 'rgba(8, 165, 196, 1)',
    borderWidth: barStyling({ itemScenarioData })
  }

  return dataset
}

//
//
function generateTrendlineDataset (params) {
  const trendScenario = findTrendScenario(params)
  if (!trendScenario) return false

  const dataset = generateLineDataset({
    instance: params.instance,
    item: params.item,
    scenarioId: trendScenario._id,
    baseColor: [13, 110, 253],
    label: 'Trend',
    yAxisId: 'yRight'
  })
  return dataset
}

/*
  Evaluate styling needed
  - hard coded value: border
  - formula value: no border
*/
function barStyling (params) {
  // debugLog.log('barStyling', barStyling)
  const itemScenarioData = params.itemScenarioData

  const barStyles = {
    borderWidth: []
  }
  itemScenarioData.c_stepFormulas.forEach(function (oneFormula, arrayIndex) {
    if (oneFormula.c_isHardCoded) {
      if (itemScenarioData.c_stepFormulasChanges[arrayIndex]) {
        barStyles.borderWidth.push(2)
      } else {
        barStyles.borderWidth.push(1)
      }
    } else {
      barStyles.borderWidth.push(0)
    }
  })
  // debugLog.log('barStyles', barStyles)
  return barStyles.borderWidth
}

function findTrendScenario (params) {
  // debugLog.log('findTrendScenario', params)
  const modelInstance = params.instance
  const scenarioId = appData.settings.view.scenarioId

  let trendScenario = modelInstance.helpers.findAutoScenario({
    scenarioId1: scenarioId,
    periodRef1: -1,
    scenarioId2: scenarioId,
    periodRef2: 0
  })
  trendScenario = false // Force comparison to be re-calculated to avoid chart missing periods after change of views

  // debugLog.log('trendScenario', trendScenario)
  if (!trendScenario) {
    // debugLog.log('create', scenarioId)
    trendScenario = modelInstance.autoCompare({
      scenarioId1: scenarioId,
      periodRef1: -1,
      scenarioId2: scenarioId,
      periodRef2: 0,
      formulaType: 'delta',
      forcedFormat: {
        shortcut: '+%'
      },
      runCalculations: true
    })
  }
  // debugLog.log('trendScenario', trendScenario)

  return trendScenario
}

//
//
function showElement () {
  appData.modelInstance.context.modelModalElement.show()
}

function generateChartContainer (params) {
  const DOMelementTitle = document.querySelector('#modelModal .modal-title')
  if (!DOMelementTitle) return
  DOMelementTitle.innerText = 'Chart'

  const DOMelement = document.querySelector('#modelModal .modal-body')
  if (!DOMelement) return
  DOMelement.innerHTML = '' // Reset

  // <canvas id="myChart" width="400" height="400"></canvas>
  const chartCanvasElement = document.createElement('canvas')
  chartCanvasElement.setAttribute('id', settings.chartCanvasId)

  DOMelement.append(chartCanvasElement)

  // Add other dimensions which could be charted
  const titleElement = document.createElement('h4')
  titleElement.innerText = 'Other comparison charts available'
  DOMelement.appendChild(titleElement)

  const optionList = document.createElement('ul')
  optionList.setAttribute('id', 'chartAutoDimensionList')
  optionList.classList.add('list-group')
  DOMelement.appendChild(optionList)

  const dimensionsForList = generateListOfDimensions(params)
  dimensionsForList.forEach(function (oneDimensionToList) {
    const choiceOption = document.createElement('li')

    // UI: Name = Value | [yAxis] [xAxis]
    const htmlArray = [oneDimensionToList.name, ' = ', oneDimensionToList.value]
    htmlArray.push('<button class="btn btn-sm btn-info" d-dimensionName="' + oneDimensionToList.name + '" d-dimensionValue="' + oneDimensionToList.value + '" d-axis="y">yAxis</button>')
    htmlArray.push('<button class="btn btn-sm btn-info" d-dimensionName="' + oneDimensionToList.name + '" d-dimensionValue="' + oneDimensionToList.value + '" d-axis="x">xAxis</button>')

    choiceOption.innerHTML = htmlArray.join('')
    choiceOption.classList.add('list-group-item')

    // choiceOption.innerText = oneDimensionToList.name + ' = ' + oneDimensionToList.value
    // choiceOption.setAttribute('d-dimensionName', oneDimensionToList.name)
    // choiceOption.setAttribute('d-dimensionValue', oneDimensionToList.value)
    // choiceOption.classList.add('list-group-item', 'clickable')

    optionList.appendChild(choiceOption)
  })
  listenToAutoChoice(params)

  // Selector for trend
  // const trendSelectorElement = document.createElement('div')
  // trendSelectorElement.setAttribute('id', 'chartTrendSelector')
  // DOMelement.append(trendSelectorElement)
  // generateTrendOptions()
}

function listenToAutoChoice (params) {
  const chartAutoDimensionListElement = document.getElementById('chartAutoDimensionList')

  chartAutoDimensionListElement.addEventListener('click', function (clickEvent) {
    const clickedOption = clickEvent.target.closest('button')
    // const clickedOption = clickEvent.target.closest('li')
    debugLog.log('clickedOption', clickedOption)

    const dimensionName = clickedOption.getAttribute('d-dimensionName')
    const dimensionValue = clickedOption.getAttribute('d-dimensionValue')
    const chartAxis = clickedOption.getAttribute('d-axis')

    const filterDimension = {
      name: dimensionName,
      value: dimensionValue
    }
    debugLog.log('filterDimension', filterDimension, 'chartAxis', chartAxis)

    if (chartAxis === 'y') {
      debugLog.log('y Axis chart')
      showChart({
        itemDimensionData: filterDimension,
        instance: params.instance,
        chartType: 'line',
        includeTrend: false
      })
    } else if (chartAxis === 'x') {
      debugLog.log('x Axis chart')
      settings.chartValues = settings.chartValues || {}
      settings.chartValues.xAxis = filterDimension

      showChartWithxAxis({
        instance: params.instance,
        filters: {
          xFilter: filterDimension,
          yFilter: settings.chartValues.itemDimensionData
        }
      })
    }
  })
}

function generateListOfDimensions (params) {
  debugLog.log('generateListOfDimensions', params)
  const modelInstance = params.instance
  const dimensionsData = modelInstance.data?.dimensionData?.dimensions
  if (!dimensionsData) return

  const uniqueDimensions = []
  const dimensionsForList = []

  Object.keys(dimensionsData).forEach(function (oneDimensionName) {
    const thisDimensionData = dimensionsData[oneDimensionName]
    thisDimensionData.values.forEach(function (oneValue) {
      const itemDimensionData = {
        name: oneDimensionName,
        value: oneValue
      }
      // debugLog.log('itemDimensionData', itemDimensionData)

      const dimensionValueString = itemDimensionData.name + '::' + oneValue
      if (!uniqueDimensions.includes(dimensionValueString)) {
        uniqueDimensions.push(dimensionValueString)

        dimensionsForList.push(itemDimensionData)
      }
    })
  })

  return dimensionsForList
}

/*
  @params
    .item
    .instance
*/
export function showChartWithxAxis (params) {
  debugLog.log('showChartWithxAxis', params)

  // Update the url
  const urlDetails = {
    details: 'chart',
    options: {
      x: params.filters.xFilter,
      y: params.filters.yFilter
    }
  }
  history.pushState({}, '', '#' + JSON.stringify(urlDetails))

  generateChartContainer(params)

  const modelInstance = params.instance

  const chartGroupedValues = chartWithxAxis.prepareChart({
    instance: params.instance,
    scenarioId: appData.settings.view.scenarioId,

    xFilter: params.filters.xFilter,
    yFilter: params.filters.yFilter
  })

  const chartOptions = {
    type: 'line',
    data: {
      datasets: []
    },
    options: {
      scales: {
        y: {
          // beginAtZero: true,
          grid: {
            // display: false,
            drawOnChartArea: false,
            drawTicks: true
          },
          title: {
            display: true,
            text: params.filters.yFilter.value
          }
        },
        x: {
          // Linear axis: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html
          type: 'linear',
          grace: '10%',

          // backgroundColor: '#F2FEA3', // Color of the axis label area
          grid: {
            display: false
          },
          // weight: 10, // Distance from axis
          title: { // Title of the X-axis
            display: true,
            text: params.filters.xFilter.value
          },
          ticks: {
            // color: labelColors // Color of the text
            // display: true // Display x axis label
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            footer: function (tooltipContexts) {
              // debugLog.log(arguments)
              const footerValues = []
              tooltipContexts.forEach(function (oneTooltipItem) {
                // oneTooltipItem.dataIndex
                const tooltipData = oneTooltipItem.dataset.data[oneTooltipItem.dataIndex]
                const instanceDataIndex = tooltipData.context.dataIndex
                const timeseriesValue = modelInstance.data.timeseries.periodSeriesView[instanceDataIndex]
                footerValues.push(timeseriesValue)
              })
              return footerValues.join('')
            }
          }
        }
      },
      onClick: function (clickEvent, activeElements, chart) {
        debugLog.log('click', arguments)
        activeElements.forEach(function (oneChartElement, activeElementIndex) {
          debugLog.log(activeElementIndex, 'dataset/index:', oneChartElement.datasetIndex, oneChartElement.index)
          const shownData = chart.data.datasets[oneChartElement.datasetIndex].data[oneChartElement.index]
          debugLog.log(shownData, shownData.context.dataIndex)
          debugLog.log(shownData.context.dataIndex, modelInstance.data.timeseries.periodSeriesView[shownData.context.dataIndex])
        })
        // activeElements // []; with { datasetIndex, index}
        // chart.data.datasets[datasetIndex].data[index]
        // appData.modelInstance.data.timeseries.periodSeriesView
      }
    }
  }

  Object.keys(chartGroupedValues).forEach(function (oneChartLineName) {
    const color = colorHelpers.generateColor() || '369f96'
    const colorRGB = colorHelpers.colorHexToRGBarray(color)

    const datasetData = {
      label: oneChartLineName,
      type: 'line',
      backgroundColor: [], // color,
      borderColor: [], // color,
      borderWidth: 2, // property does not allow array or script
      segment: {},
      pointRadius: [], // 2,
      // pointBackgroundColor: '#123456', // [], // Inside the dots
      data: []
    }

    const numberTargetValues = chartGroupedValues[oneChartLineName].xAxisValues.length
    const colorPctRange = [0.1, 1]
    const colorPctStep = (colorPctRange[1] - colorPctRange[0]) / numberTargetValues

    const sizeRange = [2, 6]
    const sizeStep = (sizeRange[1] - sizeRange[0]) / numberTargetValues

    chartGroupedValues[oneChartLineName].xAxisValues.forEach(function (onexAxisValue, arrayIndex) {
      if (!Number.isFinite(onexAxisValue)) return

      datasetData.data.push({
        x: onexAxisValue,
        y: chartGroupedValues[oneChartLineName].yAxisValues[arrayIndex],
        context: {
          dataIndex: arrayIndex,
          chartGroupedValues: params.chartGroupedValues
        }
      })

      const opacity = colorPctRange[0] + (colorPctStep * arrayIndex)
      // const opacity = 1
      const stepColor = ['rgba(', colorRGB.join(','), ',', opacity, ')'].join('')
      datasetData.backgroundColor.push(stepColor)
      datasetData.borderColor.push(stepColor)

      const dotSize = sizeRange[0] + (sizeStep * arrayIndex)

      // datasetData.borderWidth.push(Math.floor(dotSize))
      datasetData.pointRadius.push(dotSize)
    })

    datasetData.segment.borderWith = function (context) {
      // segment.borderWith is not available unfortunately
      // debugLog.log('borderWith', context)
      const index = context.p0DataIndex
      const dotSize = sizeRange[0] + (sizeStep * index)
      // debugLog.log(index, 'dotSize', dotSize, sizeRange[0], ' + ', (sizeStep * index))
      // datasetIndex
      return dotSize
    }
    datasetData.segment.borderColor = function (context) {
      // debugLog.log('borderColor', context)
      const index = context.p0DataIndex
      const opacity = colorPctRange[0] + (colorPctStep * index)
      const stepColor = ['rgba(', colorRGB.join(','), ',', opacity, ')'].join('')
      return stepColor
    }

    chartOptions.data.datasets.push(datasetData)
  })

  debugLog.log('chartOptions', chartOptions)

  const ctx = document.getElementById(settings.chartCanvasId)
  const myChart = new Chart(ctx, chartOptions)
  debugLog.log(myChart)
  showElement()
}

// function generateTrendOptions () {
//   const optionElements = moduleScenarioPicker.makeOptionElements({
//     viewPeriodicity: appData.modelInstance.data.timeseries.usedSettings.periodicity
//   })

//   const DOMelement = document.getElementById('chartTrendSelector')
//   if (!DOMelement) return

//   DOMelement.innerHTML = '' // Reset

//   optionElements.forEach(function (optionElement) {
//     DOMelement.appendChild(optionElement)
//   })
// }
