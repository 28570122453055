
import * as XLSX from 'xlsx'

import * as helperModule from '../frontend/helpers.js'
// import * as modelappWebUIModule from '../modelapp-ui/web-ui.js'

import * as modelogic from '../modelogic/index.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('file:upload')
// debugLog.disable()

export function handleFile (e) {
  debugLog.log('handle', e)
  const files = e.target.files
  const f = files[0]
  const reader = new FileReader()
  debugLog.log('reader', reader)
  reader.onload = function (e) {
    const data = new Uint8Array(e.target.result)
    const workbook = XLSX.read(data, { type: 'array' })

    debugLog.log('workbook', workbook)

    const sheetName = workbook.SheetNames[0]
    const sheetData = workbook.Sheets[sheetName]
    const jsonData = XLSX.utils.sheet_to_json(sheetData, {
      raw: false,
      header: 1
    })
    debugLog.log('XLSX.utils.sheet_to_json', jsonData)

    const jsonData2 = XLSX.utils.sheet_to_json(sheetData, {
      raw: true, // Keeps numbers as number
      header: 1
    })
    debugLog.log('XLSX.utils.sheet_to_json', jsonData2)

    const jsonData3 = XLSX.utils.sheet_to_json(sheetData) // Array of object
    debugLog.log('XLSX.utils.sheet_to_json', jsonData3)

    createInstanceModel(workbook)
  }
  reader.readAsArrayBuffer(f)
}

async function createInstanceModel (workbook) {
  // Create a temp instance/model
  const modelInstance = new modelogic.Modelapp()
  appData.modelInstance = modelInstance
  await modelInstance.initModel({
    modelData: {
      name: 'File Data'
    }
  })

  modelInstance.update.scenario.add({
    _id: 'data',
    name: 'data'
  })

  appData.settings = appData.settings || {}
  appData.settings.view = appData.settings.view || {}
  appData.settings.view.scenarioId = 'data'

  const sheetName = workbook.SheetNames[0]
  const sheetData = workbook.Sheets[sheetName]
  const jsonData2 = XLSX.utils.sheet_to_json(sheetData, {
    raw: true, // Keeps numbers as number
    header: 1
  })
  debugLog.log('XLSX.utils.sheet_to_json', jsonData2)

  if (!jsonData2) {
    debugLog.warn('no valid data')
    return
  }

  const headerInfo = analyseHeader(jsonData2[0])
  const validPeriodicity = evaluatePeriodicity({
    headerInfo: headerInfo,
    modelInstance: modelInstance
  })

  if (!validPeriodicity) {
    debugLog.warn('no valid header periodicity found')
    return
  }

  modelInstance.updateTimeSeriesSettings({
    starting: headerInfo.timeseries[0],
    periods: headerInfo.timeseries.length,
    periodicity: validPeriodicity
  })

  jsonData2.forEach(function (oneRow, rowIndex) {
    if (rowIndex === 0) return // First row is header
    addOneItem({
      rowData: oneRow,
      headerInfo: headerInfo
    })
  })

  page('/app/model/fileLoaded')
}

function valueAsDate (value) {
  debugLog.log('valueAsDate', value)
  const valueNumber = parseFloat(value)
  if (!Number.isFinite(valueNumber)) return

  const adjustedTimestamp = (valueNumber - 25569) * 86400 * 1000
  const dateYYYYMMDD = new Date(adjustedTimestamp).toJSON().substr(0, 10)

  const firstAcceptableDate = new Date('0000-01-01')
  const valueDate = new Date(dateYYYYMMDD)
  const lastAcceptableDate = new Date('2100-01-01')

  if (firstAcceptableDate <= valueDate && valueDate <= lastAcceptableDate) {
    return dateYYYYMMDD
  } else {
    return false
  }
}

function analyseHeader (headerRow) {
  debugLog.log('analyseHeader', headerRow)
  const headerInfo = {
    firstValueColumn: false,
    timeseries: [],
    values: [],
    parsedDate: []
  }

  headerRow.forEach(function (oneCellValue, arrayIndex) {
    const cellValueAsDate = valueAsDate(oneCellValue)
    headerInfo.values.push(oneCellValue)
    headerInfo.parsedDate.push(cellValueAsDate)

    if (arrayIndex === 0) return // First column should be item

    if (cellValueAsDate) {
      headerInfo.timeseries.push(cellValueAsDate)

      if (!headerInfo.firstValueColumn) headerInfo.firstValueColumn = arrayIndex
    }
  })

  debugLog.log('headerInfo', headerInfo)
  return headerInfo
}

function addOneItem (params) {
  const oneItemRow = params.rowData
  const headerInfo = params.headerInfo

  const modelInstance = appData.modelInstance

  const scenarioId = appData.settings.view.scenarioId

  const itemTempId = helperModule.generateRandomString()
  const newItem = {
    tempId: itemTempId,
    name: '' + oneItemRow[0] // Ensure it's a string
  }
  modelInstance.items.add(newItem)

  debugLog.log('row', oneItemRow[0])

  // Formulas
  oneItemRow.forEach(function (oneValue, arrayIndex) {
    debugLog.log(arrayIndex, oneValue)
    if (arrayIndex === 0) return // that's the item name
    if (arrayIndex < headerInfo.firstValueColumn) return

    const startingPeriod = headerInfo.parsedDate[arrayIndex]
    debugLog.log('startingPeriod', startingPeriod)

    const newItemValue = {
      tempId: helperModule.generateRandomString(),
      formula: oneValue,
      itemId: itemTempId,
      scenarioId: scenarioId,
      periods: {
        first_period: startingPeriod,
        repeat_periods: 1
      }
    }
    debugLog.log('newItemValue', newItemValue)
    modelInstance.formulas.add(newItemValue)
  })
}

function evaluatePeriodicity (params) {
  const potentialPeriodicities = ['day', 'week', 'month', 'quarter', 'year']

  const headerInfo = params.headerInfo
  const modelInstance = params.modelInstance

  let validPeriodicity = false

  potentialPeriodicities.forEach(function (onePeriodicityOption) {
    if (validPeriodicity) return

    modelInstance.updateTimeSeriesSettings({
      starting: headerInfo.timeseries[0],
      periods: headerInfo.timeseries.length,
      periodicity: onePeriodicityOption
    })

    const areArraysEqual = compareArrays({
      array1: headerInfo.timeseries,
      array2: modelInstance.data.timeseries.periodSeries
    })

    if (areArraysEqual) validPeriodicity = onePeriodicityOption
  })
  return validPeriodicity
}

function compareArrays (params) {
  const array1 = params.array1
  const array2 = params.array2

  // We consider them equal, and turn to false as soon as there is a difference
  let areArraysEqual = true

  array1.forEach(function (oneValue, index) {
    if (oneValue === array2[index]) return
    areArraysEqual = false
  })

  return areArraysEqual
}
