
import * as axios from 'axios'
import * as envVar from '../env.js'

import dayjs from 'dayjs'

import htmlDataSync from './data-sync.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('moduleUI:dataSync')
// debugLog.disable()

const contentDOMid = 'content'

export async function load (params = {}) {
  createPageStructure()

  const dataSyncList = await loadDataSyncList()
  if (!dataSyncList) return

  debugLog.log('dataSyncList', dataSyncList)
  dataSyncList.forEach(function (oneDataSync) {
    showOneDataSync(oneDataSync)
  })
}

//
//
function createPageStructure () {
  debugLog.log('createPageStructure')

  const DOMelement = document.getElementById(contentDOMid)
  if (!DOMelement) return

  DOMelement.innerHTML = htmlDataSync
}

//
//
async function loadDataSyncList () {
  const queryUrl = [envVar.apiUrl, 'datasync', 'list'].join('/')

  return axios.get(queryUrl)
    .then(function (response) {
      debugLog.log('loadDataSyncList response', response)
      return response.data?.data
    })
    .catch(function (error) {
      debugLog.error('loadDataSyncList error', error)
    })
}

/*
  {
  api:datasync     scheduledAt: 2021-08-27T04:13:33.924Z,
  api:datasync     request: { symbol: 'ZEN', function: 'syncPrices' },
  api:datasync     createdAt: 2021-08-27T04:13:33.925Z,
  api:datasync     updatedAt: 2021-08-27T04:13:33.925Z
  api:datasync   },

  <li>Scheduled at: XXX
  syncPrices :: symbol
  createdAt; updatedAt
  </li>
*/
function showOneDataSync (oneDataSync) {
  debugLog.log('showOneDataSync', oneDataSync)
  const DOMelement = document.getElementById('dataSyncList')
  debugLog.log('DOMelement', DOMelement)
  if (!DOMelement) return

  const element = document.createElement('li')

  const scheduledElement = document.createElement('div')
  const scheduledAt = dayjs(oneDataSync.scheduledAt).format('YYYY-MM-DD @ hh:mm:ss')
  scheduledElement.innerHTML = scheduledAt + ' <- Scheduled for'

  const requestElement = document.createElement('div')
  requestElement.innerHTML = dataSyncStatusLabel(oneDataSync) + ' <b>' + oneDataSync.request.function + ' :: ' + oneDataSync.request.symbol + '</b>'

  const timestampsElement = document.createElement('div')
  const createdAt = dayjs(oneDataSync.createdAt).format('YYYY-MM-DD @ hh:mm:ss')
  const updatedAt = dayjs(oneDataSync.updatedAt).format('YYYY-MM-DD @ hh:mm:ss')
  timestampsElement.innerHTML = updatedAt + ' <- Updated at <br>' + createdAt + ' <- Created at: '

  const reasonElement = document.createElement('div')
  reasonElement.innerHTML = (oneDataSync.reason) ? JSON.stringify(oneDataSync.reason) : 'no known reason'

  element.appendChild(requestElement)
  element.appendChild(scheduledElement)
  element.appendChild(timestampsElement)
  element.appendChild(reasonElement)
  DOMelement.append(element)
}

function dataSyncStatusLabel (oneDataSync) {
  if (oneDataSync.status === 'done') {
    return '<span class="badge bg-success">Done</span>'
  } else if (oneDataSync.status === 'ongoing') {
    return '<span class="badge bg-warning">Ongoing</span>'
  } else if (oneDataSync.status === 'scheduled') {
    return '<span class="badge bg-info text-dark">Scheduled</span>'
  } else {
    return '<span class="badge bg-secondary">' + oneDataSync.status + '</span>'
  }
}
