
import * as cssGridCell from './css-grid-cell.js'
import * as cssGridHelpers from './css-grid-helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:row')
debugLog.disable()

export const settings = {
  rowClasses: {
    baseStyling: 'gridOneRow',
    gridTemplateColumns: 'gridDefColumns'
  }
}

/*
  @params

    .rowParams
      .data: []
      .rowIndex:

      // Will the enriched
      .cells[]
      .rowStats
        .cellCharacters[] // Will be enriched of additional data
        .columnType: []
      .rowClasses: []

*/
export function generateRowElement (rowParams = {}) {
  debugLog.log('generateRowElement', rowParams)

  rowParams.cells = [] // Stores the calculated info

  rowParams.rowStats = {
    cellCharacters: [],
    columnType: []
  }

  // row Container classes
  const rowClasses = []

  // Default cell class
  rowClasses.push(settings.rowClasses.baseStyling)
  rowClasses.push(settings.rowClasses.gridTemplateColumns)

  // Add the classes to the Element
  const rowElement = document.createElement('div')
  rowElement.classList.add(...rowClasses)

  // Add attributes
  cssGridHelpers.generateAttributes(rowParams)
  rowParams.elementAttributes.forEach(function (oneAttribute) {
    rowElement.setAttribute(oneAttribute[0], oneAttribute[1])
  })

  // Process each cell
  const cells = rowParams.data?.cells || rowParams.data
  cells.forEach(function (oneCell, arrayIndex) {
    const cellParams = {
      data: oneCell,
      cellIndex: arrayIndex
    }

    const cellElement = cssGridCell.generateCellElement({
      cellParams: cellParams
    })

    //
    // Store the stats
    rowParams.rowStats.columnType.push(cellParams.data.columnPosition)
    rowParams.rowStats.cellCharacters.push(cellParams.characters)
    rowParams.cells.push(cellParams)

    rowElement.appendChild(cellElement)
  })

  return rowElement
}
