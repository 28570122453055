
import * as periods from './periods.js'
import * as logicFormula from './logic-formula.js'
import * as logicFranchise from './logic-franchise.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:prep-formula')
debugLog.disable()

/*
  @params
    .scenarioId
    .instance
*/
export function associateFormulasToItems (params = {}) {
  debugLog.log('associateFormulasToItems', params)

  const items = params.instance?.data?.dataset?.items
  if (!items) return

  resetFranchise(params)

  debugLog.log('number items', items.length, items.filter(function (oneItem) { return oneItem !== null }).length)

  items.forEach(function (oneItem) {
    debugLog.log('item', oneItem)
    if (!oneItem) return // item might have been null-ed for previous auto items

    associateFormulasToItsItem({
      item: oneItem,
      scenarioId: params.scenarioId,
      instance: params.instance
    })

    const itemScenarioData = oneItem.byScenario[params.scenarioId]
    debugLog.log('itemScenarioData.c_valueFormulas', oneItem._id, itemScenarioData.c_valueFormulas)
  })
}

/*
  Create new arrays without the automated franchise items
*/
function resetFranchise (params) {
  debugLog.log('resetFranchise', params.instance.data.dataset.items.length)
  const itemsArray = []

  params.instance.data.dataset.items.forEach(function (oneItem, arrayIndex) {
    if (oneItem.isCreatedFromFranchise) return
    itemsArray.push(oneItem)
  })
  params.instance.data.dataset.items = itemsArray

  const formulasArray = []
  params.instance.data.dataset.valueFormulas.forEach(function (oneFormula, arrayIndex) {
    if (oneFormula.isCreatedFromFranchise) return
    formulasArray.push(oneFormula)
  })
  params.instance.data.dataset.valueFormulas = formulasArray
}

/*
  params
    .item
    .scenarioId
    .instance

  Formula depend on a scenario

  It adds properties to the item
    .byScenario[scenarioId]
      .c_valueFormulas[] : array of formulas of the item
      .c_stepFormulas[]: array of formula indexed by period
      .c_stepFormulasChanges[]: array of formula, only when changing

  And formulas are compiled
    .c_calculated // TO BE RENAMED?
    .c_mathParsed
    .c_compiled: [Object]
*/
export function associateFormulasToItsItem (params = {}) {
  debugLog.log('associateFormulasToItsItem', params)
  const oneItem = params.item
  const scenarioId = params.scenarioId
  const instanceData = params.instance.data

  if (!scenarioId) {
    debugLog.warn('!! no scenarioId set', params)
    return
  }

  if (!oneItem) {
    console.warn('!! no item passed', params)
    return
  }

  // create item indexed object
  oneItem.byScenario = oneItem.byScenario || {}
  oneItem.byScenario[scenarioId] = {}

  // Shortcut
  const itemScenarioData = oneItem.byScenario[scenarioId]

  // debugLog.log('oneItem', oneItem, params.instance.helpers.getItemId(oneItem))
  itemScenarioData.c_valueFormulas = instanceData.dataset.valueFormulas.filter(function (oneValueFormula) {
    const isFormulaForItem = oneValueFormula.definition.itemId === params.instance.helpers.getItemId(oneItem)
    const isFormulaForScenario = oneValueFormula.definition.scenarioId === scenarioId
    // debugLog.log( 'oneValueFormula.definition.itemId', oneValueFormula.definition.itemId, 'oneValueFormula.definition.scenarioId', oneValueFormula.definition.scenarioId)
    // debugLog.log('isFormulaForItem && isFormulaForScenario', isFormulaForItem, isFormulaForScenario, (isFormulaForItem && isFormulaForScenario))
    return isFormulaForItem && isFormulaForScenario
  })

  // Define the array of formulas to apply
  const timeSeriesValues = instanceData.timeseries.periodSeries
  itemScenarioData.c_stepFormulas = itemScenarioData.c_stepFormulas || Array(timeSeriesValues.length).fill(false) // Stores the formula
  itemScenarioData.c_stepFormulasChanges = itemScenarioData.c_stepFormulasChanges || Array(timeSeriesValues.length).fill(false) // Stores formula, when new

  // item might have no formula
  if (!itemScenarioData.c_valueFormulas || itemScenarioData.c_valueFormulas.length === 0) return

  // Analyse the formulas for additional info
  itemScenarioData.c_valueFormulas.forEach(function (oneValueFormula) {
    // debugLog.log('oneValueFormula', oneValueFormula)
    oneValueFormula.c_calculated = oneValueFormula.c_calculated || {}

    // Find the arrayIndex for first_period
    const startingIndex = periods.startingPeriodIndex({
      modelTimeSeries: timeSeriesValues,
      targetPeriod: oneValueFormula.definition.first_period
    })
    // debugLog.log('startingIndex', startingIndex)
    oneValueFormula.c_calculated.starting_stepKey = startingIndex

    // Update for end periods in case of formula.definition.repeat_periods
    // last_period // To do
    if (Number.isFinite(oneValueFormula.definition.repeat_periods)) {
      // Calculate the arrayIndex for last_period
      oneValueFormula.c_calculated.ending_stepKey = startingIndex + oneValueFormula.definition.repeat_periods
    }

    try {
      logicFormula.compileFormula(oneValueFormula)
    } catch (error) {
      oneValueFormula.c_compiled = false
      debugLog.warn('!! invalid formula', oneValueFormula)
    }

    // Find the items being referred for formula across rows
    // !! This only works when all items already exists!! not good for templating??? Except if the formulas are at the end... the dataset will have been updated
    // debugLog.log( oneValueFormula )
    if (oneValueFormula.c_refObj) {
      oneValueFormula.c_refObj.forEach(function (oneRef) {
        if (!oneRef.items) return

        oneRef.c_items = filterReferencedItems({
          ref: oneRef,
          instanceData: instanceData
        })
      })
    }

    // Record the formula in the array
    itemScenarioData.c_stepFormulas[oneValueFormula.c_calculated.starting_stepKey] = oneValueFormula
    itemScenarioData.c_stepFormulasChanges[oneValueFormula.c_calculated.starting_stepKey] = oneValueFormula
  })

  // Put a reference to the formula to apply on every step
  timeSeriesValues.forEach(function (oneStep, stepKey) {
    // debugLog.log('oneStep', oneStep)

    // Method v2
    let previousFormula

    if (itemScenarioData.c_stepFormulasChanges[stepKey]) {
      previousFormula = itemScenarioData.c_stepFormulasChanges[stepKey]
    } else if (itemScenarioData.c_stepFormulas[stepKey - 1]) {
      previousFormula = itemScenarioData.c_stepFormulas[stepKey - 1]
    }

    if (!previousFormula) return // No previous formula
    if (previousFormula.c_calculated.ending_stepKey <= stepKey) return // The formula is over
    itemScenarioData.c_stepFormulas[stepKey] = previousFormula

    // Method v1
    // Issue: does not refresh the formula on itemFormula creation
    // The step might have its own formula: nothing to do
    // if (itemScenarioData.c_stepFormulas[stepKey]) return // Already a formula

    // // Otherwise it should be the previous step formula if still applicable
    // const previousFormula = itemScenarioData.c_stepFormulas[stepKey - 1]
    // if (!previousFormula) return // No previous formula
    // if (previousFormula.c_calculated.ending_stepKey <= stepKey) return // The formula is over
    // itemScenarioData.c_stepFormulas[stepKey] = previousFormula
  })

  // Apply the model use
  if (oneItem.c_template_use) {
    debugLog.warn(' -> use template', oneItem.c_template_use)
    logicFranchise.useModel({
      item: oneItem,
      scenarioId: scenarioId,
      instance: params.instance
    })
  }
}

/*
  @params
    .ref
*/
function filterReferencedItems (params) {
  // Find the items to consider
  const itemsFilter = params.ref.items.split(':')
  // debugLog.log( 'itemsFilter', itemsFilter )
  if (itemsFilter.length !== 2) return

  const instanceData = params.instanceData

  const filteredItems = instanceData.dataset.items.filter(function (oneItem) {
    // debugLog.log( oneItem )
    if (!oneItem.dimensions) return false // items can not have an object of dimensions

    let isExcludedDimension = false // Asume good faith

    const isMatchingClassification = oneItem.dimensions.find(function (oneD) {
      const isDimension = oneD.name === itemsFilter[0]
      const isValue = oneD.value === itemsFilter[1]

      // Override the isExcludedDimension property: ie remains at true once set at true
      isExcludedDimension = isExcludedDimension || ['module-template'].includes(oneD.name) // Template items should not be considered

      return isDimension && isValue
    })
    return isMatchingClassification && !isExcludedDimension
  })
  return filteredItems
}
