
// ***********
// !!! ISSUE: for some reason the debug() module does not always work within a function: it gets disabled somehow
// Usage:
// const debugModule = consoleLogger.startDebug('module:submodule')
// consoleLogger.disableDebug('page:footer')

//
// import { debug } from 'debug'

// export function startDebug (moduleName) {
//   // Browser: use the console for log (any namespace of debug)
//   debug.log = console.info.bind(console)
//   const debugModule = debug(moduleName)
//   // Check if the debugger is enabled: debugModule.enabled or debug.enabled(moduleName) (both work)
//   debug.enable(moduleName) // .enable(namespace) .disable(namespace)

//   return debugModule
// }

// export function disableDebug (moduleName) {
//   debug.disable(moduleName)
// }
// *************

// **** WE CREATE OUR OWN COLORED DEBUGGER

/*
  Min of 17, to ensure the color is on 2 characters once in Hex
*/
function generateColor () {
  return [
    '#',
    Math.max(17, Math.floor(Math.random() * 255)).toString(16),
    Math.max(17, Math.floor(Math.random() * 255)).toString(16),
    Math.max(17, Math.floor(Math.random() * 255)).toString(16)
  ].join('')
}

export const DebugLog = class DebugLog {
  constructor (moduleName) {
    this.moduleName = moduleName
    this.color = generateColor()
    this.enabled = true

    this.disable = function () {
      this.enabled = false
    }

    this.enable = function () {
      this.enabled = true
    }

    this.log = function () {
      if (!this.enabled) return

      // Show the stackTrace (can be long)
      // console.trace()

      // Generate a stackTrack; Non Standard: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Stack
      // let e = new Error()
      // console.log(e.stack)

      const styles = []
      // styles.push(['color', this.color].join(':'))
      styles.push(['color', 'white'].join(':'))
      styles.push(['background-color', this.color].join(':'))
      console.log('%c%s', styles.join(';'), [' ', this.moduleName, ' '].join(''), ...arguments)
    }

    this.warn = function () {
      if (!this.enabled) return
      const color = 'color:' + this.color
      console.warn('%c%s', color, this.moduleName, ...arguments)
    }

    this.error = function () {
      if (!this.enabled) return
      const color = 'color:' + this.color
      console.error('%c%s', color, this.moduleName, ...arguments)
    }

    this.table = function () {
      if (!this.enabled) return
      console.table(...arguments)
    }

    this.trace = function () {
      if (!this.enabled) return
      console.trace()
    }

    this.groupLevel = 0

    this.group = function () {
      if (!this.enabled) return
      this.groupLevel++
      console.group()
    }
    this.groupEnd = function () {
      if (!this.enabled) return
      this.groupLevel--
      console.groupEnd()
    }
  }
}
