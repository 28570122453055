//
import * as userModule from '../user.js'

import * as moduleItemController from './control-items.js'
import htmlItemEditor from './editor.html'
import htmlLayout from './layout.html'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('item:editor')
// debugLog.disable()

export function loadEditor (params = {}) {
  debugLog.log('item loadEditor', params)

  params.targetElement = params.targetElement || 'itemRow'

  // Ensure element exists and clean it
  const targetDOMelement = document.getElementById(params.targetElement)
  debugLog.log('targetDOMelement', targetDOMelement)
  if (!targetDOMelement) return

  targetDOMelement.innerHTML = htmlLayout

  const userData = userModule.userData()
  const modelInstance = params.instance
  const userHasRights = !!(modelInstance.data?.model?.userRights?.canEdit)
  debugLog.log('userData', userData, userHasRights)

  const createButton = targetDOMelement.querySelector('button')

  if (userData && userHasRights) {
    debugLog.log('update button')

    createButton.setAttribute('id', moduleItemController.createItemButtonId)
    createButton.toggleAttribute('hidden', false)

    const newDiv = document.createElement('div')
    // newDiv.classList.add('gridArea')
    newDiv.innerHTML = htmlItemEditor
    targetDOMelement.append(newDiv)
    //
  } else {
    createButton.toggleAttribute('hidden', true)
  }

  // Add the listeners
  moduleItemController.listenToEdit(params)

  listenToSearch()
}

function listenToSearch () {
  debugLog.log('listenToSearch')
  const itemSearchBarElement = document.getElementById('itemSearchBar')
  debugLog.log('itemSearchBarElement', itemSearchBarElement)
  if (!itemSearchBarElement) return

  itemSearchBarElement.addEventListener('keyup', function () {
    const searchTerm = itemSearchBarElement.value

    debugLog.log('searchTerm', searchTerm)
    filterGrid({
      searchTerm
    })
  })
}

function filterGrid (params) {
  const searchTerm = params.searchTerm

  appData.modelInstance.data.dataset.items.forEach(function (oneItem) {
    // debugLog.log('oneItem', oneItem._id, oneItem.name, oneItem)
    let isItemMatching = false

    const nameMatch = isValueMatching({
      text: oneItem.name,
      searchTerm: searchTerm
    })
    if (nameMatch) isItemMatching = true

    oneItem.dimensions.forEach(function (oneDimension) {
      const dimensionMatch = isValueMatching({
        text: oneDimension.value,
        searchTerm: searchTerm
      })
      if (dimensionMatch) isItemMatching = true
    })

    if (searchTerm === '') isItemMatching = true

    updateGridAsNeeded({
      itemId: oneItem._id,
      isToShow: isItemMatching
    })
  })
  'NFLX, Revenue'.toLowerCase().includes('revenue')
}

function isValueMatching (params) {
  // debugLog.log('isValueMatching', params)
  if (typeof params.text !== 'string') return false

  const searchTerm = params.searchTerm
  if (!searchTerm) return

  const isMatch = params.text.toLowerCase().includes(searchTerm)
  // debugLog.log('isMatch', isMatch)
  return isMatch
}

function updateGridAsNeeded (params) {
  const itemId = params.itemId
  const leftAreaElement = document.querySelector('.drawnTable .left.content')
  const centerAreaElement = document.querySelector('.drawnTable .center.content')

  const rowElement1 = leftAreaElement.querySelector('[d-itemId="' + itemId + '"]')
  rowElement1.toggleAttribute('hidden', !params.isToShow)

  const rowElement2 = centerAreaElement.querySelector('[d-itemId="' + itemId + '"]')
  rowElement2.toggleAttribute('hidden', !params.isToShow)
}
