import * as axios from 'axios'

import * as envVar from '../env.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('model:data')
// debugLog.disable()

debugLog.log('modelDataModule')

//
//
export function parseApiResponse (apiResponse) {
  debugLog.log('parseApiResponse')
  // Record when we received an API response
  if (apiResponse.status === 200 && apiResponse.data && apiResponse.data.data) {
    debugLog.log('model data is ok')
    return apiResponse.data.data
  } else {
    debugLog.warn('!! no model data', apiResponse)
    return false
  }
}

/*
  @params
    .modelId
*/
export async function loadModel (params) {
  debugLog.log('loadModel from DB')

  const modelId = params.modelId
  if (!modelId) {
    debugLog.warn('!! no modelId given', params)
    return
  }

  const queryUrl = [envVar.apiUrl, 'models', modelId]

  const queryParams = {
    scenarios: true,
    items: true,
    itemsFormulas: true
  }

  return axios.get(queryUrl.join('/'), { params: queryParams })
    .then(function (response) {
      return parseApiResponse(response)
    })
    .catch(function (error) {
      debugLog.error('loadModel', error)
    })
}
