import * as axios from 'axios'

import * as envVar from '../../env.js'
import * as userModule from '../user.js'

console.log('modelInvitedController')

const DOMelementIds = {
  contentContainer: 'content'
}

/*
  @params
    .inviteId
*/
export function load (params = {}) {
  console.log('load invite')

  const DOMelement = document.getElementById(DOMelementIds.contentContainer)
  console.log('DOMelement', DOMelement)
  if (!DOMelement) return

  const element = document.createElement('div')

  const htmlArray = []
  htmlArray.push('<h1>You\'re invited!</h1>')
  htmlArray.push('<div id="invitationDetails">...loading details...</div>')
  element.innerHTML = htmlArray.join('')
  DOMelement.append(element)

  loadInvite(params)
}

function listenAccept () {
  const DOMelement = document.getElementById('acceptInvite')
  console.log('DOMelement', DOMelement)
  if (!DOMelement) return

  DOMelement.addEventListener('click', function (clickEvent) {
    acceptInvite()
  })
}

function showInvite (inviteData) {
  // Not logged
  // Show model name, and ask to log in/sign up

  // Logged
  // email matches
  // email does not match
  const DOMelement = document.getElementById('invitationDetails')
  DOMelement.innerHTML = ''

  const htmlArray = []
  htmlArray.push(JSON.stringify(inviteData))

  if (userModule.userData()) {
    htmlArray.push('<button id="acceptInvite" class="btn btn-primary">Accept</button>')
  } else {
    htmlArray.push(userModule.loginButton())
  }

  htmlArray.push('<div id="feedback"></div>')

  DOMelement.innerHTML = htmlArray.join('')

  listenAccept()
}

async function acceptInvite () {
  const inviteId = localStorage.getItem('inviteId')
  const queryUrl = [envVar.apiUrl, 'invites', inviteId, 'accept']

  return axios.put(queryUrl.join('/'))
    .then(function (response) {
      console.log('response', response)
      localStorage.removeItem('inviteId')

      if (response.data?.data === null) {
        const DOMelement = document.getElementById('feedback')
        const html = []
        html.push('<div class="alert alert-danger" role="alert">')
        html.push('Failed to accept: Ensure your are signed in with the same email than the invited one')
        html.push('</div>')
        DOMelement.innerHTML = html.join('')
        return
      }

      const DOMelement = document.getElementById('feedback')
      DOMelement.innerHTML = 'Success! <a href="/app/model/' + response.data.data._model._id + '">Go to the model ' + response.data.data._model.name + '</a>'

      localStorage.removeItem('inviteId')
    })
    .catch(function (error) {
      console.error('error', error)
    })
}

async function loadInvite (params) {
  const queryUrl = [envVar.apiUrl, 'invites', params.inviteId]

  return axios.get(queryUrl.join('/'))
    .then(function (response) {
      console.log('response', response)
      if (!response.data || !response.data.data) console.warn('no invite')

      showInvite(response.data.data)
    })
    .catch(function (error) {
      console.error('error', error)
    })
}
