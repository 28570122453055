
import * as editorComponent from './index.js'
import * as editorSubPicker from './sub-picker.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('subPicker:item')
// debugLog.disable()

export function includePickerItemElement (params) {
  debugLog.log('includePickerItemElement', params)

  const pickerElement = document.createElement('span')
  pickerElement.classList.add('editor', 'pickerSection', 'pickerItem')
  pickerElement.setAttribute('contentEditable', false)

  pickerElement.innerText = showItemSelection({
    editorData: params.editorData,
    subData: params.subData
  })
  params.editorSubElement.append(pickerElement)

  const listenerParams = Object.assign({
    pickerElement: pickerElement
  }, params)

  pickerListener(listenerParams)
}

//
//
function showPopup (params = {}) {
  const editorSubElement = params.editorSubElement
  if (!editorSubElement) return

  // Ensure there is, and just 1, a popup element
  let pickerPopupElement = editorSubElement.querySelector('.pickerItemPopup')
  if (!pickerPopupElement) {
    pickerPopupElement = createElement(params)
  }

  const listenerParams = Object.assign({
    pickerPopupElement: pickerPopupElement
  }, params)
  pickerPopupListenerSingleItem(listenerParams)
  pickerPopupListenerItemFilter(listenerParams)
}

//
//
function showItemList (params = {}) {
  const editorData = params.editorData

  const editorSettings = editorComponent.data()
  const idRef = editorSettings.itemDefs.id
  const labelRef = editorSettings.itemDefs.label

  const list = []
  editorData?.items?.forEach(function (oneItem) {
    list.push('<li class="list-group-item" d-itemId="' + oneItem[idRef] + '">' + oneItem[labelRef] + '</li>')
  })
  return list.join('')
}

//
//
function showDimensionsList (params = {}) {
  const instanceData = appData.modelInstance.data
  const dimensions = instanceData.dimensionData?.dimensions
  if (!dimensions) return ''

  const list = []
  Object.keys(dimensions).forEach(function (oneDimension) {
    const dimensionData = dimensions[oneDimension]

    dimensionData.values.forEach(function (oneValue) {
      list.push('<li class="list-group-item" d-dimensionName="' + oneDimension + '" d-dimensionValue="' + oneValue + '">' + oneDimension + ':' + oneValue + '</li>')
    })
  })
  return list.join('')
}

//
//
function createElement (params = {}) {
  editorSubPicker.removePreviousPickersPopup()

  const picker = document.createElement('div')
  picker.classList.add('pickerPopup', 'pickerItemPopup')

  const content = []
  content.push('<div>pick One item:</div>')
  content.push('<ul class="list-group itemsList">...loading...</ul>')
  // TODO:
  content.push('<div>or All items within a dimension:</div>')
  content.push('<ul class="list-group itemDimensionsList">...loading...</ul>')
  content.push('<div class="close">Close</div>')

  picker.innerHTML = content.join('')
  params.editorSubElement.append(picker)

  picker.querySelector('.itemsList').innerHTML = showItemList(params)
  picker.querySelector('.itemDimensionsList').innerHTML = showDimensionsList(params)

  return picker
}

//
//
function findPickedItem (params = {}) {
  debugLog.log('findPickedItem', params)
  if (!params.editorData) return
  // if (params.targetItemId === 'na') return

  const editorSettings = editorComponent.data()
  const idRef = editorSettings.itemDefs.id

  const itemData = params.editorData.items.find(function (oneItem) {
    // debugLog.log('oneItem', oneItem)
    return oneItem[idRef] === params.targetItemId // clickedItemId
  })
  return itemData
}

function showItemSelection (params = {}) {
  const subData = params.subData
  const editorData = params.editorData

  if (subData?.itemId && subData?.itemId !== 'na') {
    const editorSettings = editorComponent.data()
    const labelRef = editorSettings.itemDefs.label

    const itemData = findPickedItem({
      editorData: editorData,
      targetItemId: subData.itemId
    })
    if (!itemData) return
    return itemData[labelRef]
  } else if (subData?.items) {
    return 'Items with: ' + subData.items
  }

  return 'Pick an item'
}

//
//
function pickerListener (params = {}) {
  const pickerElement = params.pickerElement

  pickerElement.addEventListener('focus', function (focusEvent) {
    debugLog.log('item picker focus')
    // Show popup
    showPopup(params)
  })
}

//
//
function pickerPopupListenerSingleItem (params = {}) {
  debugLog.log('pickerPopupListenerSingleItem')
  const pickerPopupElement = params.pickerPopupElement
  if (!pickerPopupElement) return

  const editorData = params.editorData
  const editorSubElement = params.editorSubElement
  const subId = editorSubElement.getAttribute('d-subId')

  pickerPopupElement.querySelector('.itemsList').addEventListener('click', function (clickEvent) {
    debugLog.log('pickerPopupListenerSingleItem clicked')
    const clickedItem = clickEvent.target.closest('li')
    if (!clickedItem) return

    const clickedItemId = clickedItem.getAttribute('d-itemId')
    debugLog.log('clickedItemId', clickedItemId)

    debugLog.log('editorData.items', editorData.items)

    const editorSettings = editorComponent.data()
    const idRef = editorSettings.itemDefs.id

    const itemData = findPickedItem({
      editorData: editorData,
      targetItemId: clickedItemId
    })
    debugLog.log('itemData', itemData)

    // Update the data
    const subData = editorData.subs[subId]
    subData.itemId = itemData[idRef]
    subData.items = false // unset

    params.pickerElement.innerText = showItemSelection({
      editorData: editorData,
      subData: subData
    })

    editorComponent.readEditorContent({ editorData: editorData })
  })
}

//
//
function pickerPopupListenerItemFilter (params = {}) {
  debugLog.log('pickerPopupListenerItemFilter')
  const pickerPopupElement = params.pickerPopupElement
  if (!pickerPopupElement) return

  const editorData = params.editorData
  const editorSubElement = params.editorSubElement
  const subId = editorSubElement.getAttribute('d-subId')

  pickerPopupElement.querySelector('.itemDimensionsList').addEventListener('click', function (clickEvent) {
    debugLog.log('pickerPopupListenerItemFilter clicked')
    const clickedItem = clickEvent.target.closest('li')
    if (!clickedItem) return

    const clickedDimension = clickedItem.getAttribute('d-dimensionName')
    const clickedValue = clickedItem.getAttribute('d-dimensionValue')
    debugLog.log('clicked', clickedDimension, clickedValue)

    const filter = [clickedDimension, clickedValue].join(':')

    // Update the data
    const subData = editorData.subs[subId]
    subData.itemId = false // unset
    subData.items = filter

    params.pickerElement.innerText = showItemSelection({
      editorData: editorData,
      subData: subData
    })

    editorComponent.readEditorContent({ editorData: editorData })
  })
}
