
// When building urls, which will be the pattern?
const MODEL_ID_TARGET = 'modelId'

const urlTargets = {
  modelId: 'model',
  scenarioId: 'scenario'
}

/*
  params.
    targetProperty: being the name of the property before the one we want
      modelId
*/
export function readUrl (params) {
  let targetProperty
  if (Object.keys(urlTargets).includes(params.targetProperty)) {
    targetProperty = urlTargets[params.targetProperty]
  } else {
    targetProperty = params.targetProperty
  }

  let propertyValue = false
  const pathArray = window.location.pathname.split('/')
  pathArray.forEach(function (onePath, key) {
    if (onePath === targetProperty) {
      propertyValue = pathArray[key + 1]
    }
  })
  return propertyValue
}

export function modelUrlPattern (modelId) {
  const elements = ['/app', 'MODEL_ID_TARGET', 'MODEL_ID']
  const baseUrl = elements.join('/').replace('MODEL_ID_TARGET', urlTargets[MODEL_ID_TARGET])
  return baseUrl.replace('MODEL_ID', modelId)
}

// Generate a random string with specified length
export function generateRandomString (numberCharacters = 8) {
  let randomString = ''

  while (randomString.length < numberCharacters) {
    randomString += Math.random().toString(36).replace(/[^a-z0-9]+/g, '')
  }
  // Restrict length to the target
  randomString = randomString.substr(0, numberCharacters)
  return randomString
}
