
// import * as datasetSample from './css-grid-data-sample.js'
import * as cssGridRow from './css-grid-row.js'
import * as cssGridScrollable from './css-grid-scrollable'

import * as cssGridContentAnalysis from './css-grid-content-analysis.js'
import * as cssGridStyling from './css-grid-styling.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1')
debugLog.disable()

/*
  https://www.notion.so/CSS-Grid-Layout-6554f6cac59d4cac9ec0fbfc879d4bab
*/

// Analyse each cell to count the characters
// Use CSS custom tag

export const settings = {
  gridClass: 'cssGridLayout',
  rowClasses: cssGridRow.settings.rowClasses
}

/*
                  .columnPosition
                  left        center
  .rowPosition:
          header
          content

  @inputParams{
    gridMode: 1pager or scrollable
    dataset: [ // array of Row Objects
      rows{
        info:[]
        cells: [
          cell{ // Cell Object: oneCell
            .value: output // TODO: could make it: value, with formatter and htmlPrinter
            .columnPosition: left | center | right
            .rowPosition: content | header | headerGroup
            .classes: [] of classe names
            .info: [{key:value}] of data which are passed to the cell attributes as 'd-key="value"'
            .colSpan // TODO not used in v1
          }
        ]
       ]
    ],
    targetSelector: '#xx' // CSS selector of the container
    addClasses: [] // Adhoc class(es) to add to the parent container
  }

  Stats Calculated:
    - characters per cell
    - max characters per column
    - characters per row

  @moduleParams outputs:
    .rows [
      {
        data: [] of input cells
        cells: [{
          data: {} of input cell data
          cellIndex: Int,
          characters: Int, // Calculated in generateCell()
          classes,
          // htmlArray: []
          // html: String
        }]
        rowIndex: Int
        rowStats: {
          cellCharacters: [] // // Calculated in generateCell()
          charactersTotal: Int // Calculated in evaluateCharacters()
          charactersPerType: {left:0, center:0, right:0} // Calculated in evaluateCharacters()
          columnType: []
        },
        rowClasses: [],
        htmlArray: [],
        html: String
      }
    ],
    .tableHTMLdata [] of HTML string
    .rowStats {
      .columnType []
      .charactersMaxPerCol []
      .charactersNeededPerCol [] // Aggregate the content cell to be equal in length
    }

  Output
  - moduleParams.tableHTMLdata [] ; then joined

  Cell Output: CSS Grids by default consider any first level child as grid items, so no need to define a .gridItem class
  <div class="[cellColTypeLeft|Center] [cellRowTypeContent|TODO]">
    <div class="cellInside">value</div>
  </div>
*/
export function drawTable (inputParams) {
  debugLog.log('drawTable', inputParams)

  if (!inputParams.dataset) {
    debugLog.warn('no dataset', inputParams)
    return
  }

  const gridClasses = (inputParams.addClasses || [])
  gridClasses.push(settings.gridClass)

  const tableElement = document.createElement('div')
  tableElement.classList.add(...gridClasses)

  const moduleParams = {
    targetSelectorId: inputParams.targetSelectorId,
    rows: [], // Store the calculated info
    tableElement: tableElement, // The content will be passed as an HTML string
    rowStats: {
      columnType: [],
      charactersMaxPerCol: [],
      charactersNeededPerCol: []
    }
  }

  // inputParams.gridMode = '1pager'
  const gridMode = inputParams.gridMode || 'scrollable'

  if (gridMode === '1pager') {
    tableElement.classList.add('dndDropzone') // To allow drag and drop sorting

    // Generate as a 1 pager: row by row with adjusted font size
    generateTableElementByRow(inputParams, moduleParams)
    cssGridContentAnalysis.evaluateCharacters(moduleParams)
    debugLog.log('moduleParams', moduleParams)
    cssGridStyling.updateGridCss(moduleParams)

    insertInDOM(inputParams, moduleParams)
  //
  } else {
    // Generate as a scrollable table with header and frozen columns
    generateTableElementScrollable(inputParams, moduleParams)

    insertInDOM(inputParams, moduleParams)

    cssGridScrollable.listenGridScrolling({
      DOMGridContainer: document.getElementById(inputParams.targetSelectorId),
      moduleParams
    })
  }

  return moduleParams
}

//
//
function insertInDOM (inputParams, moduleParams) {
  // Locate the target DOM element
  let targetElement = document.getElementById(inputParams.targetSelectorId)
  if (!targetElement) targetElement = document.body
  debugLog.log('insertInDOM', targetElement)

  // Clean the current element
  targetElement.innerHTML = '' // Empty

  // Add the new element
  targetElement.appendChild(moduleParams.tableElement)
}

//
//
function generateTableElementByRow (inputParams, moduleParams) {
  inputParams.dataset.forEach(function (oneRow, arrayIndex) {
    const rowParams = {
      data: oneRow,
      rowIndex: arrayIndex
    }
    moduleParams.rows.push(rowParams)
    const rowElement = cssGridRow.generateRowElement(rowParams)

    moduleParams.tableElement.appendChild(rowElement)
  })
  return moduleParams.tableElement
}

//
//
function generateTableElementScrollable (inputParams, moduleParams) {
  debugLog.log('generateTableElementScrollable')
  moduleParams.scrollableElements = {
    left: {},
    center: {}
    // TODO: right
  }
  moduleParams.cellsByScrollableElement = {
    left: {},
    center: {}
    // TODO: right
  }

  inputParams.dataset.forEach(function (oneRow, arrayIndex) {
    const rowParams = {
      data: oneRow,
      rowIndex: arrayIndex,
      moduleParams
    }
    moduleParams.rows.push(rowParams)
    cssGridScrollable.analyseCellByScrollableElement(rowParams)
  })
  debugLog.log('moduleParams', moduleParams)

  Object.keys(moduleParams.cellsByScrollableElement).forEach(function (xKey) {
    moduleParams.scrollableElements[xKey] = moduleParams.scrollableElements[xKey] || {}

    Object.keys(moduleParams.cellsByScrollableElement[xKey]).forEach(function (yKey) {
      debugLog.group()
      debugLog.log(xKey, yKey, moduleParams.cellsByScrollableElement[xKey][yKey])
      const cellsByScrollableElement = moduleParams.cellsByScrollableElement[xKey][yKey]
      debugLog.log('cellsByScrollableElement', cellsByScrollableElement)

      const domElement = cssGridScrollable.getGridScrollingElement({
        xClass: xKey,
        yClass: yKey,
        scrollableElements: moduleParams.scrollableElements
      })
      if (xKey === 'left' && yKey === 'content') {
        domElement.classList.add('dndDropzone') // To allow drag and drop sorting
      }

      debugLog.log('domElement', domElement)

      cellsByScrollableElement.forEach(function (oneElementRow) {
        debugLog.log('oneElementRow', oneElementRow)
        const oneRowElement = cssGridScrollable.generateScrollableGridRow(oneElementRow)
        domElement.appendChild(oneRowElement)
      })

      const element = moduleParams.scrollableElements[xKey][yKey]
      moduleParams.tableElement.appendChild(element)

      debugLog.groupEnd()
    })
  })

  cssGridTemplateColumns(moduleParams)

  return moduleParams.tableElement
}

function cssGridTemplateColumns (moduleParams) {
  debugLog.log('cssGridTemplateColumns', moduleParams)

  const styleElement = document.createElement('style')
  document.head.appendChild(styleElement)

  let styleString

  // Left
  styleString = cssGridStyling.generateStyle({
    targetColumnClass: '.left',
    columnWidths: [
      'minmax(150px, 1fr)',
      {
        width: 'minmax(60px, 120px)', // 'auto',
        columns: moduleParams.cellsByScrollableElement.left.content[0].cells.length - 2
      },
      '25px'
    ]
  })
  styleElement.sheet.insertRule(styleString)

  // Center
  styleString = cssGridStyling.generateStyle({
    targetColumnClass: '.center',
    columnWidths: [{
      width: '120px',
      columns: moduleParams.cellsByScrollableElement.center.content[0].cells.length
    }]
  })
  styleElement.sheet.insertRule(styleString)
}

// Demo the table
// export function demo () {
//   cssGridApp.drawTable({
//     dataset: datasetSample.datasetSimple(), // Array of data
//     targetSelector: '#gridTest', // CSS selector of the container
//     addClasses: []
//   })
// }
