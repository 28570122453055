
// import ColorScheme from 'color-scheme'
import Color from 'color'

/*
  Min of 17, to ensure the color is on 2 characters once in Hex
*/
export function generateColor () {
  return [
    '#',
    Math.max(17, Math.floor(Math.random() * 255)).toString(16),
    Math.max(17, Math.floor(Math.random() * 255)).toString(16),
    Math.max(17, Math.floor(Math.random() * 255)).toString(16)
  ].join('')
}

export function colorHexToRGBarray (colorHex) {
  return Color(colorHex).rgb().array()
}

/*
  Does not generate enough colors
*/
// function generateColors () {
//   // https:// www.npmjs.com/package/color-scheme
//   const scheme = new ColorScheme()
//   scheme.from_hue(255) // Start the scheme
//     .scheme('triade') // Use the 'triade' scheme, that is, colors selected from 3 points equidistant around the color wheel
//     // .variation('soft')// Use the 'soft' color variation

//   const colors = scheme.colors()
//   return colors
// }
