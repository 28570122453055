
// Ideas
// Allow drag and drop of elements to quickly update a formula https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
// editorSub.setAttribute('draggable', true)

/*
  div contentEditable=false #USER_DEFINED .form-control .formulaEditor
    // **** For typing formula
    div tabindex=NUM contentEditable=true .editorSub .editor .formulaTyping 'flex', 'extendLastChild', 'flexWrap'

    // **** For selection formula
    div .editorSub .referenceSelector contentEditable=false d-subId=UniqueID
      span tabindex=NUM .editor .pickerSection .pickerItem
      span tabindex=NUM .editor .pickerSection .pickerPeriod contentEditable=true
      span tabindex=NUM .editor .pickerSection .pickerClose .close
      div .pickerPopup .pickerPeriodPopup
        div
        div .close
      div .pickerPopup .pickerItemPopup
        div
        ul .itemsList .list-group
          li d-itemId=ID .list-group-item
        div .close

    Component Data

    [editorId]: {
      items: [],
      subId: // number to generate IDs
      subs: []
      tabIndexStart:
      editorDOMelement
      editorId
    }

  // Format formula given to it
  // Returns valid state of input
  // Allows to click items anywhere in the page to pick items
  // Returns the parsed value to use somewhere else
*/

import * as helpers from '../frontend/helpers.js'

import * as logicFormula from '../modelogic/logic-formula.js'
import * as evalIndex from '../modelogic/eval-index.js'

import * as editorSubTyping from './sub-typing.js'
import * as editorSubPicker from './sub-picker.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('formulaEditor:index')
// debugLog.disable()

const settings = {
  refChar: ['@'],
  itemDefs: {
    id: '_id',
    label: 'name'
  },

  // To manage tab index across editors
  tabIndexMin: 100,
  tabIndexEditorSpread: 100,

  editors: {}
}

const sampleItems = [
  {
    _id: '100',
    name: 'customers'
  },
  {
    _id: '200',
    name: 'quantity'
  }
]

// 100 * 50 + @item + 25

function readerId (editorId) {
  return editorId + 'Reader'
}

function parserId (editorId) {
  return editorId + 'Parsed'
}

function generateRandomId () {
  return 'ed' + helpers.generateRandomString()
}

export function data () {
  return settings
}

export function returnEditorData (editorId) {
  return settings.editors[editorId]
}

/*
  .editorId : ID for editor
  .containerId: DOM element hosting the editor

  .initialFormula: string of the initial value formula
*/
export function load (params = {}) {
  console.log('load', params)
  const editorId = params.editorId || generateRandomId()
  const containerId = params.containerId || 'content'

  const DOMelement = document.getElementById(containerId)
  if (!DOMelement) return

  // Store the editor
  settings.editors[editorId] = {
    subId: false,
    subs: [],
    includeDebug: params.includeDebug
  }

  const editorData = settings.editors[editorId]

  const numberEditors = Object.keys(settings.editors).length
  editorData.tabIndexStart = settings.tabIndexMin + numberEditors * settings.tabIndexEditorSpread

  // Use sample items for debug; otherwise the items passed
  editorData.items = params.includeDebug ? sampleItems : []
  editorData.items = params.items ? params.items : editorData.items

  // The editor field
  const editor = document.createElement('div')
  editor.setAttribute('contentEditable', false) // The children will be
  editor.setAttribute('id', editorId)
  editor.classList.add('form-control', 'formulaEditor', 'flex', 'extendLastChild', 'flexWrap')
  editorData.editorDOMelement = editor
  editorData.editorId = editorId

  if (!params.isElementToBeAppended) {
    DOMelement.innerHTML = ''
  }
  DOMelement.append(editor)

  if (params.initialFormula) {
    showEditorContent({
      editorId: editorId,
      formula: params.initialFormula
    })
  } else {
    // No initial formula: load an empty typing element
    editorSubTyping.createSubTyping({
      editorData: editorData
    })
  }

  // Do a reading, allowing to active features such as tabIndex and focus
  readEditorContent({ editorData: editorData })
  debugLog.log('abc1')

  // For development and debug:
  if (params.includeDebug) {
    generateDebugElement({
      editorData: editorData,
      DOMelement: DOMelement
    })
  }

  debugLog.log('editorId', editorId)
  return editorId
}

function showEditorContent (params) {
  debugLog.log('showEditorContent', params)
  const editorId = params.editorId
  const formulaString = params.formula

  const editorData = settings.editors[editorId]

  const formulaObj = {
    formula: formulaString
  }
  editorData.initialFormulaObj = formulaObj
  parseFormula(formulaObj)

  editorData.editorDOMelement.innerHTML = '' // Reset
  generateSubs({
    editorData: editorData
  })
}

export function clearEditor (params) {
  debugLog.log('clearEditor', params)
  const editorId = params.editorId

  showEditorContent({
    editorId: editorId,
    formula: ''
  })
}

//
//
function parseFormula (formulaObj) {
  if (typeof formulaObj.formula !== 'string' || formulaObj.formula.trim().length === 0) return

  formulaObj.formulaSections = []

  formulaObj.formula.split(/[{}]/).forEach(function (oneFormulaPart) {
    if (oneFormulaPart.trim().length === 0) return
    try {
      const jsonElement = '{' + oneFormulaPart + '}'
      const parsed = JSON.parse(jsonElement)
      formulaObj.formulaSections.push(parsed)
    } catch (error) {
      formulaObj.formulaSections.push(oneFormulaPart)
    }
  })
}

//
//
function generateSubs (params = {}) {
  debugLog.log('generateSubs', params)
  const editorData = params.editorData

  let precedingSub
  let isLastSubTypeOk = false

  function ensurePrecedingSubExists () {
    if (precedingSub) return
    precedingSub = editorSubTyping.createSubTyping({
      editorData: editorData
    })
  }

  function ensureFollowingSubExists () {
    if (isLastSubTypeOk) return
    precedingSub = editorSubTyping.createSubTyping({
      editorData: editorData
    })
  }

  editorData.initialFormulaObj?.formulaSections?.forEach(function (oneSection) {
    debugLog.log('oneSection', oneSection)
    if (typeof oneSection === 'string') {
      precedingSub = editorSubTyping.createSubTyping({
        editorData: editorData,
        content: oneSection
      })
      isLastSubTypeOk = true
    } else if (typeof oneSection === 'object') {
      ensurePrecedingSubExists()

      precedingSub = editorSubPicker.createSubSelector({
        editorData: editorData,
        precedingSub: precedingSub,
        insertFollowingSub: false,
        subPickerSection: oneSection
      })

      isLastSubTypeOk = false
    } else {
      debugLog.warn('what is it?', typeof oneSection, oneSection)
    }
  })
  ensureFollowingSubExists()
}

//
//
function generateDebugElement (params) {
  debugLog.log('generateDebugElement')
  const editorId = params.editorData.editorId
  const editorParsedId = parserId(editorId)
  const parser = document.createElement('div')
  parser.setAttribute('id', editorParsedId)
  parser.classList.add('formulaEditorReader')
  params.DOMelement.append(parser)

  const editorReaderId = readerId(editorId)
  const reader = document.createElement('div')
  reader.setAttribute('id', editorReaderId)
  reader.classList.add('formulaEditorReader')
  params.DOMelement.append(reader)
}

/*
  Evaluate the content for editors to be created
*/
function parseContent (editorId) {
  const DOMelement = document.getElementById(editorId)
  if (!DOMelement) return

  DOMelement.querySelectorAll('.editorSub').forEach(function (oneSub) {
    // The selectors are not to be analysed
    if (oneSub.classList.contains('referenceSelector')) return

    const subContent = oneSub.innerText
    const contentChars = subContent.split('')

    const analysedContent = []

    let keepGoing = true
    contentChars.forEach(function (oneChar, charPosition) {
      // debugLog.log(charPosition, oneChar)
      if (!keepGoing) return

      if (settings.refChar.includes(oneChar)) {
        // Update current element to include the text up to the character
        oneSub.innerText = analysedContent.join('')

        const remainingText = subContent.substr(charPosition + 1) // +1 to remove the special character
        // debugLog.log('remainingText', remainingText)

        // And create a new element for the remaining one
        editorSubPicker.createSubSelector({
          editorData: settings.editors[editorId],
          // editorId: editorId,
          precedingSub: oneSub,
          followingContent: remainingText,
          insertFollowingSub: true
        })

        // Stop the loop
        keepGoing = false
        return
      }
      analysedContent.push(oneChar)
    })
  })
}

/*
  @params
    .editorData
*/
export function readEditorContent (params = {}) {
  debugLog.log('readEditorContent')

  if (!params.editorData) return

  const DOMelement = params.editorData.editorDOMelement
  if (!DOMelement) return

  const editorId = params.editorData.editorId
  if (!editorId) return

  parseContent(editorId)

  const formulaStringParts = []

  const editorData = settings.editors[editorId]
  let tabIndex = editorData.tabIndexStart

  DOMelement.querySelectorAll('.editorSub').forEach(function (oneSub, arrayKey) {
    debugLog.log('.editorSub', arrayKey, oneSub)

    // Set Tabindex for keyboard navigation
    if (oneSub.classList.contains('referenceSelector')) {
      oneSub.querySelectorAll('.pickerSection').forEach(function (onePickerSection) {
        tabIndex++
        onePickerSection.setAttribute('tabindex', tabIndex)
      })

      const subId = oneSub.getAttribute('d-subId')
      formulaStringParts.push(editorSubPicker.referenceSubToFormula({
        editorData: editorData,
        subId: subId
      }))
    } else {
      tabIndex++
      oneSub.setAttribute('tabindex', tabIndex)

      formulaStringParts.push(oneSub.innerText)
    }
  })

  const formulaString = formulaStringParts.join(' ').trim()
  const formulaInfo = readFormulaRepeat(formulaString)
  const formulaObj = formulaInfo
  debugLog.log('formulaObj', formulaObj)
  logicFormula.compileFormula(formulaObj)
  debugLog.log('compiledFormula', formulaObj)
  try {
    formulaObj._id = 'anyId'
    const evaluationParams = {
      stepFormula: formulaObj,
      params: {
        stepIndex: params.stepIndex,
        scenarioId: params.scenarioId,
        instance: params.instance
      }
    }
    // formulaObj.evaluated = formulaObj.c_compiled.evaluate()
    const cellValue = evalIndex.evaluateStepFormula(evaluationParams)
    formulaObj.evaluated = cellValue
  } catch (error) {
    debugLog.warn('caught: not evaluated', error)
  }
  debugLog.log('.evaluate()', formulaObj.evaluated)

  editorData.formulaObj = formulaObj

  if (editorData.includeDebug) {
    readForDebug(params)
  }
}

/*
  Eg.
  '40 * 3 repeat:-199 100*5'.match(/repeat:([0-9]+)/, '')
  '40 * 3 repeat:99 100*5'.replace(/(repeat:([0-9]+))/, '')
*/
function readFormulaRepeat (formulaString) {
  const formulaInfo = {
    formula: formulaString,
    repeat: false
  }
  const repeatInput = formulaString.match(/repeat:([0-9]+)/, '')
  debugLog.log('readFormulaRepeat', repeatInput)

  if (repeatInput) {
    formulaInfo.repeat = +(repeatInput[1]) // The 2nd element of the array will be the number value
    formulaInfo.formula = formulaString.replace(/(repeat:([0-9]+))/, '').trim()
  }
  return formulaInfo
}

function readForDebug (params = {}) {
  debugLog.log('readForDebug', params)
  const htmlArray = []

  const editorId = params.editorData.editorId

  const DOMelement = params.editorData.editorDOMelement
  DOMelement.querySelectorAll('.editorSub').forEach(function (oneSub, arrayKey) {
    // For development and debug
    htmlArray.push('<div>')
    htmlArray.push('<br>Key:' + arrayKey)
    htmlArray.push('<br>Text:' + oneSub.innerText)
    htmlArray.push('<br>Length:' + oneSub.innerText.length)

    // Is there a way to know where the focus (caret) is?
    const isFocused = oneSub.getAttribute('focused')
    const isFocused2 = oneSub.getAttribute(':focus')
    htmlArray.push('<br>isFocused:' + isFocused + '-' + isFocused2)
    htmlArray.push('<br>classes:' + oneSub.classList)
    htmlArray.push('</div>')
  })

  const editorReaderId = readerId(editorId)
  const DOMelementReader = document.getElementById(editorReaderId)
  if (!DOMelementReader) return

  const selection = window.getSelection()
  htmlArray.push('<div>')
  htmlArray.push('<br>selection:' + selection)
  htmlArray.push('</div>')

  DOMelementReader.innerHTML = htmlArray.join('')

  // Show the parsed formula
  const editorParsedId = parserId(editorId)
  const DOMelementParsed = document.getElementById(editorParsedId)
  if (!DOMelementParsed) return

  DOMelementParsed.innerHTML = params.editorData?.formulaObj?.formula
}
