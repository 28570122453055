
import * as logicFormula from './logic-formula.js'
import * as logicFormulaEval from './logic-formula-eval.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:evalIndex')
debugLog.disable()

// Track formula which have been called to avoid circular loop
let calculatedFormulaIds
let calculationOrder

const settings = {
  isDebugEnabled: debugLog.enabled
}

//
/*
  calculationParams
    .scenarioId
    .instance
*/
export function calculateValues (calculationParams) {
  console.time('calculateValues')
  debugLog.log('calculateValues', calculationParams)

  resetValues(calculationParams)

  const instanceData = calculationParams.instance.data

  // Reset
  calculationOrder = 0

  instanceData.dataset.items.forEach(function (oneItem) {
    const itemId = calculationParams.instance.helpers.getItemId(oneItem)
    debugLog.log('calculateValues for item:', itemId, oneItem)

    instanceData.timeseries.periodSeries.forEach(function (oneStep, stepKey) {
      debugLog.log('Within item timeSeries, item:', itemId, oneItem.name, ' @', stepKey, oneStep)

      resetCircularDependencyArray()

      evaluateItemStepValue({
        item: oneItem,
        stepIndex: stepKey,
        scenarioId: calculationParams.scenarioId,
        instance: calculationParams.instance
      })
    })
  })
  console.timeEnd('calculateValues')
}

function resetValues (calculationParams) {
  const instanceData = calculationParams.instance.data
  const scenarioId = calculationParams.scenarioId

  instanceData.dataset.items.forEach(function (oneItem) {
    const itemScenarioData = oneItem.byScenario[scenarioId]
    if (!itemScenarioData) return

    itemScenarioData.c_calculatedValues = false
    itemScenarioData.c_evaluationOrder = false
    itemScenarioData.c_evaluationRun = false // We should keep the values to avoid multiple calculations for circulare dependencies?; added this reset to allow force calculations for debug
  })
}

// For Debug
function debugCurrentValues (params) {
  const instanceData = params.instance.data
  debugLog.log('calculateValuesCalls', instanceData.stats.calculateValuesCalls)
  debugLog.log('parentParams', params.parentParams)

  const scenarioId = params.scenarioId

  // Values for every items:
  const values = []
  instanceData.dataset.items.forEach(function (oneItem) {
    const itemScenarioData = oneItem.byScenario[scenarioId]
    values.push(itemScenarioData.c_calculatedValues)
  })
  debugLog.log('item values:', values)
}

/*
  params
    .item
    .stepIndex: key within the timeseries.periodSeries
    .scenarioId
    .instance
    .forceCalculation: false (default); true to avoid checking if value is available: useful for debugging for eg
    .resetCalculation: false (default)
    .showDebug: force to enable debug
*/
export function evaluateItemStepValue (params = {}) {
  if (params.showDebug) enableDebug()
  if (params.resetCalculation && !params.isCalculationResetCalculation) {
    resetValues(params)
    resetCircularDependencyArray()
    params.isCalculationResetCalculation = true // We only need to reset at the start of the evaluation
  }

  debugLog.group()
  debugLog.log('evaluateItemStepValue, calculationOrder:', calculationOrder)
  debugLog.log('item:', params.instance.helpers.getItemId(params.item), params.item?.name, '@ step', params.stepIndex)
  debugLog.log('params:', params)
  debugCurrentValues(params)

  // Item
  const oneItem = params.item
  if (!oneItem) {
    debugLog.warn('no item', params)
    debugLog.groupEnd()
    return false
  }

  const scenarioId = params.scenarioId
  const itemScenarioData = oneItem.byScenario[scenarioId]

  if (!itemScenarioData) {
    debugLog.warn('!! Not available', params)
    debugLog.groupEnd()
    return false
  }

  const instanceData = params.instance.data

  // Define the array holding Values if needed
  const timeSeriesValues = instanceData.timeseries.periodSeries
  itemScenarioData.c_calculatedValues = itemScenarioData.c_calculatedValues || Array(timeSeriesValues.length).fill(null)
  itemScenarioData.c_linkedData = itemScenarioData.c_linkedData || Array(timeSeriesValues.length).fill(null)
  itemScenarioData.c_evaluationRun = itemScenarioData.c_evaluationRun || Array(timeSeriesValues.length).fill(0)
  itemScenarioData.c_evaluationOrder = itemScenarioData.c_evaluationOrder || Array(timeSeriesValues.length).fill(null)
  itemScenarioData.c_evaluationNote = itemScenarioData.c_evaluationNote || Array(timeSeriesValues.length).fill(null)

  const stepFormula = getStepFormula(params)

  const availableValue = useAlreadyCalculatedValue(params)
  if (availableValue.isAvailable) {
    debugLog.log('availableValue', availableValue)
    debugLog.groupEnd()
    return {
      value: availableValue.targetValue,
      stepFormula: stepFormula
    }
  }

  let cellValue = null
  let cellHasValue = false

  // We update the calculation order; now that we know we're gonna calculate for this cell
  calculationOrder++

  // We need to create array here, as .fill([]) would use the same array for all elements
  itemScenarioData.c_evaluationOrder[params.stepIndex] = itemScenarioData.c_evaluationOrder[params.stepIndex] || []
  itemScenarioData.c_evaluationOrder[params.stepIndex].push(calculationOrder)

  itemScenarioData.c_evaluationNote[params.stepIndex] = itemScenarioData.c_evaluationNote[params.stepIndex] || {}

  const itemName = '' + oneItem.name // Ensure it's a string
  if (itemName?.substr(0, 3) === '---') {
    // Hack to show empty lines
    cellValue = null
  //
  } else if (params.stepIndex < 0 || !stepFormula) {
    debugLog.log('evalCase: initial 0, calculationOrder:', calculationOrder)
    cellValue = null
  //
  } else if (!stepFormula.c_compiled) {
    debugLog.log('evalCase: not compiled!!!, calculationOrder:', calculationOrder)
    cellValue = null
  //
  } else if (stepFormula) {
    debugLog.log('evalCase: use stepFormula, calculationOrder:', calculationOrder, stepFormula)
    cellValue = evaluateStepFormula({
      stepFormula: stepFormula,
      params: params
    })
    // debugLog.log('html:', stepFormula.c_mathParsed.toHTML())
    cellHasValue = true
  //
  } else {
    debugLog.warn('other cases, calculationOrder:', calculationOrder)
    cellValue = null
  }

  // We use the linked Data, except if overriden
  const linkedData = useLinkedDataIfRequired({
    stepIndex: params.stepIndex,
    oneItem,
    instance: params.instance
  })
  itemScenarioData.c_linkedData[params.stepIndex] = linkedData

  // debugLog.log('linked or value? Linked:', linkedData?.value, 'value:', cellValue)
  const availableLinkedData = (linkedData && Number.isFinite(linkedData.value)) ? linkedData.value : false
  // const isValueAvailable = Number.isFinite(cellValue)
  if (availableLinkedData && !cellHasValue) {
    cellValue = availableLinkedData
  }

  itemScenarioData.c_calculatedValues[params.stepIndex] = cellValue // Store the value
  itemScenarioData.c_evaluationRun[params.stepIndex] = instanceData.evaluationRuns // Record that the cell has been evaluated already for this calculation run

  debugLog.log('calculationOrder:', calculationOrder, params.instance.helpers.getItemId(oneItem), oneItem.name, ' >>> cellValue =', cellValue)
  debugLog.groupEnd()

  if (params.showDebug) disableDebug()

  const cellValueObj = {
    value: cellValue,
    stepFormula: stepFormula
  }

  if (params.showDebug) {
    cellValueObj.debugHTMLElement = generateVisualEvaluation(cellValueObj)
  }

  return cellValueObj
}

function useLinkedDataIfRequired (params) {
  // debugLog.log('useLinkedDataIfRequired', params)
  const oneItem = params.oneItem
  const instance = params.instance
  const stepIndex = params.stepIndex
  const marketData = oneItem.link?.data
  if (!marketData) return

  // Periods we're targetting
  const marketDataType = oneItem.link.targetProperty
  let viewPeriodDate
  if (marketDataType === 'price') {
    viewPeriodDate = instance.data.timeseries.periodSeriesCalculationTo[stepIndex]
  } else {
    viewPeriodDate = instance.helpers.getPeriodAbsolute({
      stepIndex
    })
  }

  // Exact search
  const targetIndex = marketData.dates.indexOf(viewPeriodDate)
  if (targetIndex >= 0) {
    return {
      targetIndex: targetIndex,
      viewPeriodDate: viewPeriodDate,
      value: marketData.values[targetIndex]
    }
  }

  // Approximate
  const approxParams = Object.assign({}, params)
  approxParams.viewPeriodDate = viewPeriodDate
  return findApproximateData(approxParams)
}

function findApproximateData (params) {
  debugLog.log('findApproximateData', params)
  const oneItem = params.oneItem
  // const instance = params.instance
  // const stepIndex = params.stepIndex
  const marketData = oneItem.link?.data

  const viewPeriodDate = params.viewPeriodDate

  const marketDataType = oneItem.link.targetProperty
  // const periodicity = instance.data.timeseries.usedSettings.periodicity

  const dateMinOfData = marketData.dates[0]
  const dateMaxOfData = marketData.dates[marketData.dates.length - 1]
  debugLog.log('dates:', dateMinOfData, ' to ', dateMaxOfData, ' targetting:', viewPeriodDate)
  debugLog.log('(dateMinOfData < viewPeriodDate && viewPeriodDate < dateMaxOfData)', (dateMinOfData < viewPeriodDate && viewPeriodDate < dateMaxOfData))

  if (['price', 'adjPrice'].includes(marketDataType)) {
    // For price, we return the last value available until the period; keeping the price going in the future
    let indexForPreviousPrice = false
    marketData.dates.forEach(function (oneDateForData, i) {
      if (oneDateForData <= viewPeriodDate) indexForPreviousPrice = i
    })
    return {
      isApproximate: true,
      marketDataType: marketDataType,
      // viewPeriodDateNext: viewPeriodDateNext,
      viewPeriodDate: viewPeriodDate,
      targetIndex: indexForPreviousPrice,
      value: marketData.values[indexForPreviousPrice]
    }
  //
  } else if (['pnl', 'bs'].includes(marketDataType)) {
    // Return data, until the last fetch data only
    if (dateMinOfData < viewPeriodDate && viewPeriodDate < dateMaxOfData) {
      let indexForValue

      marketData.datesFrom.forEach(function (oneDateForData, i) {
        // debugLog.log(i, oneDateForData)
        if (oneDateForData <= viewPeriodDate && viewPeriodDate <= marketData.dates[i]) {
          indexForValue = i
        }
      })

      const approxData = marketData.values[indexForValue]

      return {
        isApproximate: true,
        marketDataType: marketDataType,
        viewPeriodDate: viewPeriodDate,
        targetIndex: indexForValue,
        value: approxData
      }
    }
  }

  return {}
}

function getStepFormula (params) {
  const scenarioId = params.scenarioId
  const oneItem = params.item
  const itemScenarioData = oneItem.byScenario[scenarioId]
  const stepFormula = itemScenarioData.c_stepFormulas[params.stepIndex]

  return stepFormula
}

function useAlreadyCalculatedValue (params) {
  const availableValue = {
    isAvailable: false
  }

  // If we need to force the calculation, we cut here (ie we don't return the previous value)
  if (params.forceCalculation || params.parentParams?.forceCalculation) return availableValue

  const scenarioId = params.scenarioId
  const oneItem = params.item
  const itemScenarioData = oneItem.byScenario[scenarioId]
  const instanceData = params.instance.data

  // Verify if the cell has been evaluated already; Stop the evaluation if already done
  debugLog.log('evaluatedRun', itemScenarioData.c_evaluationRun[params.stepIndex], 'vs. evaluationRuns', instanceData.evaluationRuns)
  if (itemScenarioData.c_evaluationRun[params.stepIndex] >= instanceData.evaluationRuns) {
    const targetValue = itemScenarioData.c_calculatedValues[params.stepIndex]
    debugLog.log('-> already evaluated', params.instance.helpers.getItemId(oneItem), oneItem.name, ' @ ', params.stepIndex, ' = ', targetValue)

    availableValue.targetValue = targetValue
    availableValue.isAvailable = true
    return availableValue
  }
  return availableValue
}

//
export function evaluateStepFormula (stepFormulaParams) {
  debugLog.log('evaluateStepFormula', stepFormulaParams)
  const stepFormula = stepFormulaParams.stepFormula
  const params = stepFormulaParams.params

  const scenarioId = params.scenarioId
  const stepIndex = params.stepIndex

  if (!isSafeFromCircularDependency({
    stepFormula: stepFormula,
    params: params
  })) {
    debugLog.groupEnd()
    return false // on Circular Dependency, we show that the value is an error: false (instead of null/undefined when value is jut not available)
  }

  // Check and return the dependency values, if any
  // debugLog.log( 'stepFormula', stepFormula )

  debugLog.log('ref to evaluate:', stepFormula.c_refObj?.length)

  const evalScope = {}
  stepFormula.c_refObj?.forEach(function (oneRef) {
    debugLog.log('oneRef', oneRef)
    const refValue = logicFormulaEval.evalOneFormulaReferenceValue({
      oneRef,
      scenarioId,
      instance: params.instance,
      stepIndex,
      parentParams: params
    })
    debugLog.log('eval-ed refValue', refValue)
    evalScope[refValue.evalRefId] = refValue.value
    oneRef.value = refValue.value
  })
  // debugLog.log('evalScope', evalScope)
  debugLog.log('ready to evaluate:', stepFormula, evalScope)
  const evaluationObj = logicFormula.evaluate({
    formulaObj: stepFormula,
    evalScope: evalScope
  })
  const cellValue = evaluationObj.value

  const scenarioParams = stepFormula.c_deltaScenario
  const hideErrors = !!((scenarioParams && scenarioParams?.alertErrors === false))
  debugLog.log(evaluationObj, 'evaluated scenarioParams', scenarioParams, 'hideErrors', hideErrors)
  if (evaluationObj.isError && !hideErrors) {
    const oneItem = params.item
    oneItem.byScenario = oneItem.byScenario || {}
    oneItem.byScenario[scenarioId] = oneItem.byScenario[scenarioId] || {}
    const itemScenarioData = oneItem.byScenario[scenarioId]
    itemScenarioData.c_evaluationNote = itemScenarioData.c_evaluationNote || {}
    itemScenarioData.c_evaluationNote[stepIndex].isError = true
    itemScenarioData.c_evaluationNote[stepIndex].note = evaluationObj.note
  }
  return cellValue
}

/*
  Function to check formulas which have been called already
*/
function isSafeFromCircularDependency (params = {}) {
  debugLog.log('isSafeFromCircularDependency', calculatedFormulaIds, params)
  const stepFormula = params.stepFormula
  const stepIndex = params.params.stepIndex
  const stepPeriodAbs = params.params?.instance?.helpers?.getPeriodAbsolute({
    stepIndex: stepIndex
  }) || 1 // Might not be defined when editing a formula

  const oneItem = params.params.item
  const modelInstance = params.params.instance
  const itemId = modelInstance.helpers.getItemId(oneItem)
  const scenarioId = params.params.scenarioId || 'noScenario'

  // A value is for an item/scenario/period reference
  const cellRef = [itemId, scenarioId, stepPeriodAbs].join('.')

  if (calculatedFormulaIds.has(cellRef)) {
    debugLog.warn('circular stepFormula for formula:', cellRef, calculatedFormulaIds)
    // debugLog.log('params:', params.params)
    stepFormula.c_isCircular = true

    oneItem.byScenario = oneItem.byScenario || {}
    oneItem.byScenario[scenarioId] = oneItem.byScenario[scenarioId] || {}
    const itemScenarioData = oneItem.byScenario[scenarioId]
    itemScenarioData.c_evaluationNote = itemScenarioData.c_evaluationNote || {}
    itemScenarioData.c_evaluationNote[stepIndex].isCircular = true

    return false
  }

  // Recorded that this formula has been called for evaluation
  calculatedFormulaIds.add(cellRef)

  return true
}

function resetCircularDependencyArray () {
  calculatedFormulaIds = new Set()
}

function generateVisualEvaluation (cellValueObj) {
  debugLog.log('generateVisualEvaluation', cellValueObj)
  const evalElement = document.createElement('div')

  const finalValueElement = document.createElement('div')
  finalValueElement.innerHTML = '<h3>Value:</h3><p>' + cellValueObj.value + '</p>'
  evalElement.appendChild(finalValueElement)

  const mathJSparams = {
    parenthesis: 'all',
    implicit: 'show'
  }

  const fullFormulaElement = document.createElement('div')
  fullFormulaElement.classList.add('wrapText')
  fullFormulaElement.innerHTML = '<h3>Full Formula:</h3>' + cellValueObj.stepFormula?.c_mathParsed?.toHTML(mathJSparams)
  evalElement.appendChild(fullFormulaElement)

  const parsedFormulaElement = document.createElement('div')
  parsedFormulaElement.classList.add('wrapText')
  parsedFormulaElement.innerHTML = '<h3>Parsed Formula:</h3>' + cellValueObj.stepFormula?.c_mathParsedTransformed?.toHTML(mathJSparams)
  evalElement.appendChild(parsedFormulaElement)

  const valueDetailsElement = document.createElement('ul')
  cellValueObj.stepFormula?.c_refObj?.forEach(function (oneRef) {
    const oneRefElement = document.createElement('li')
    oneRefElement.innerText = oneRef.refId + ' = ' + oneRef.value
    valueDetailsElement.appendChild(oneRefElement)
  })
  evalElement.appendChild(valueDetailsElement)

  debugLog.log('evalElement', evalElement)

  return evalElement
}

function enableDebug () {
  settings.isDebugEnabled = debugLog.enabled
  debugLog.enable()
}

function disableDebug () {
  if (settings.isDebugEnabled) {
    debugLog.disable()
  }
}
