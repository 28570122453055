
import * as editorComponent from './index.js'
import * as editorSubPicker from './sub-picker.js'

/*
  Module for the formula editor managing typing elements
*/

export function createSubTyping (params = {}) {
  const editorSubElement = generateTypingSub({
    editorData: params.editorData,
    content: params.content || ''
  })

  params.editorData.editorDOMelement.append(editorSubElement)
  editorSubElement.focus()

  return editorSubElement
}

//
//
export function generateTypingSub (params = {}) {
  const editorSubElement = document.createElement('span')
  editorSubElement.classList.add('editorSub', 'formulaTyping', 'editor')
  editorSubElement.setAttribute('contentEditable', true)
  editorSubElement.innerText = params.content

  listener({
    editorData: params.editorData,
    DOMelement: editorSubElement
  })

  return editorSubElement
}

//
//
function listener (params = {}) {
  const DOMelement = params.DOMelement
  if (!DOMelement) return

  DOMelement.addEventListener('keyup', function (keyEvent) {
    console.log('Typing, key:', keyEvent.key, keyEvent.code, keyEvent.charCode, keyEvent.keyCode, keyEvent.which)

    // We don't want new lines
    // TODO: how should we manage that?
    if (keyEvent.key === 'Enter') {
      console.warn('New line')
    }

    editorComponent.readEditorContent({ editorData: params.editorData })
  })

  DOMelement.addEventListener('focus', function (focusEvent) {
    console.log('focusEvent')

    // No need to show pickers popup when in a typing Sub
    editorSubPicker.removePreviousPickersPopup()
  })
}
