
// Calculation Modules
import * as modelAppFormatting from '../modelogic/formatting.js'

// CSS Grid Module
import * as cssGridApp from '../cssgrid-app.v1/css-grid.js'

// Web Frontend UIs
import * as modelappWebUISensitivity from './web-ui-sensitivity'
import * as webUIListeners from './web-ui-listeners.js'
import * as webUIItemSorting from './web-ui-item-sorting.js'
import * as webUIItemChart from './web-ui-chart.js'

import * as moduleItemController from '../frontend/items/control-items.js'
import * as itemFormulaModule from '../frontend/itemformulas/control-itemformulas.js'
import * as moduleScenarioPicker from '../frontend/scenarios/scenario-picker.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('model:webui')
// debugLog.disable()

//
// **** Manage Franchise Templates
//
// TODO: use the modelInstance
function isFranchiseColumnToBeShown (instance) {
  const dimensionsData = instance.data.dimensionData
  if (dimensionsData.templates && dimensionsData.templates.length > 0) return true

  return false
}

function franchiseTemplateColumnHeader (params = {}) {
  // debugLog.log('franchiseTemplateColumnHeader', params)
  if (!isFranchiseColumnToBeShown(params.instance)) return

  // Column for item info (eg. template)
  params.headerObj.push({
    columnPosition: 'left',
    rowPosition: 'header',
    value: 'Template'
  })
}

//
function franchiseTemplateColumnContent (params = {}) {
  // debugLog.log('franchiseTemplateColumnContent', params)
  if (!isFranchiseColumnToBeShown(params.instance)) return

  const oneItem = params.oneItem

  const rowCellInfo = {
    value: oneItem.c_template ? oneItem.c_template : '',
    columnPosition: 'left',
    rowPosition: 'content',
    info: [
      {
        itemId: params.instance.helpers.getItemId(oneItem)
      }
    ]
  }
  params.rowObj.cells.push(rowCellInfo)
}
//
// **** END Manage Franchise Templates
//

//
// **** Manage Dimensions
//
function areDimensionsColumnToBeShown (params) {
  // debugLog.log('areDimensionsColumnToBeShown', params)
  const dimensionsData = params.instance.data.dimensionData
  if (!dimensionsData.dimensions) return false

  const dimensions = Object.keys(dimensionsData.dimensions)
  if (dimensions.length === 0) return false

  return dimensions
}

//
function dimensionsColumnHeaders (params = {}) {
  const dimensions = areDimensionsColumnToBeShown(params)
  if (!dimensions) return

  dimensions.forEach(function (oneDimensionKey) {
    params.headerObj.push({
      columnPosition: 'left',
      rowPosition: 'header',
      value: oneDimensionKey
    })
  })
}

//
function dimensionsColumnContent (params = {}) {
  const dimensions = areDimensionsColumnToBeShown(params)
  if (!dimensions) return

  const oneItem = params.oneItem
  dimensions.forEach(function (oneDimensionKey) {
    const itemDimensionData = oneItem.dimensions.find(function (oneDimension) {
      return oneDimension.name === oneDimensionKey
    })

    const cellData = {
      value: (itemDimensionData?.value) ? itemDimensionData?.value : '',
      columnPosition: 'left',
      rowPosition: 'content',
      cellId: generateCellId(),
      info: [
        {
          itemId: params.instance.helpers.getItemId(oneItem),
          cellId: generateCellId(false)
        }
      ],
      contextMenu: [{
        label: 'Turn to Chart',
        action: function () {
          debugLog.log('called chart')
          webUIItemChart.showChart({
            itemDimensionData: itemDimensionData,
            instance: appData.modelInstance,
            chartType: 'line',
            includeTrend: false
          })
        }
      }]
    }
    params.rowObj.cells.push(cellData)
  })
}
//
// **** END Manage Dimensions
//

/*
  Prepare data for CSS Grid layout
  @params
    .scenarioId
*/
function drawTable (params) {
  const dataset = []

  const headerObj = drawHeader(params)
  dataset.push(headerObj)

  sortContentRows(params)

  const rowsObj = drawContentRows(params)
  dataset.push(...rowsObj)

  const drawParams = {
    dataset: dataset,
    targetSelectorId: 'modelContainer',
    addClasses: ['drawnTable']
  }
  cssGridApp.drawTable(drawParams)

  return dataset
}

function sortContentRows (params) {
  const sortedItemIds = appData.modelInstance?.data?.model?.settings?.sortedItemIds
  if (!sortedItemIds) return

  const items = params.instance.data.dataset.items
  items.forEach(function (oneItem) {
    const itemId = params.instance.helpers.getItemId(oneItem)
    const sortedIndex = sortedItemIds.indexOf(itemId)

    oneItem.c_sortedIndex = sortedIndex
  })

  items.sort(function (i1, i2) {
    // if ()
    return i1.c_sortedIndex - i2.c_sortedIndex
  })
}

//
//
function drawContentRows (params) {
  const rows = []

  // Reset
  cellCounter = 0

  const items = params.instance.data.dataset.items
  items.forEach(function (oneItem) {
    const rowObj = {
      cells: []
    }

    const itemId = params.instance.helpers.getItemId(oneItem)

    rowObj.info = [
      {
        isItem: true
      },
      {
        itemId: itemId
      }
    ]

    const cellValue = oneItem.name || itemId

    // Item Name
    const rowCell = {
      value: cellValue,
      columnPosition: 'left',
      rowPosition: 'content',
      classes: [],
      cellId: generateCellId(),
      isIncludeDragHandle: true,
      info: [{
        itemId: itemId,
        cellId: generateCellId(false)
      }],
      contextMenu: [
        {
          label: 'Calculate sensitivity',
          action: function () {
            debugLog.log('clicked calculateSensitivity')
            appData.modelInstance.calculateSensitivity({
              scenarioId: params.scenarioId
            })

            modelappWebUISensitivity.showSensitivityTable({
              instance: appData.modelInstance,
              targetItemId: itemId,
              scenarioId: params.scenarioId
            })
          }
        },
        {
          label: 'Calculate % of this',
          action: function () {
            debugLog.log('called % of this')
            const autoScenario = appData.modelInstance.autoCompare({
              scenarioId1: params.scenarioId,
              periodRef1: 0,
              scenarioId2: params.scenarioId,
              periodRef2: 0,
              baseItem: oneItem,
              formulaType: 'ratio',
              forcedFormat: {
                shortcut: '%',
                numberDecimals: 1
              }
            })
            runAppCalculations({ scenarioId: autoScenario._id })
          }
        },
        {
          isSeparator: true
        },
        {
          label: 'Turn to Chart',
          action: function () {
            debugLog.log('called chart')
            webUIItemChart.showChart({
              item: oneItem,
              instance: appData.modelInstance,
              chartType: 'bar',
              includeTrend: true
            })
          }
        }
      ]
    }

    if (appData?.settings?.mode !== 'local') {
      rowCell.contextMenu.push({
        isSeparator: true
      })

      rowCell.contextMenu.push({
        label: 'Insert row below (SOON)',
        action: function () {
          debugLog.log('called 3')
        }
      })

      rowCell.contextMenu.push({
        isSeparator: true
      })

      rowCell.contextMenu.push({
        label: 'Cell Content length:' + (cellValue || '').toString().length
      })

      rowCell.contextMenu.push({
        label: 'Delete Item',
        action: function () {
          debugLog.log('called Delete item')
          moduleItemController.deleteItem({
            item: oneItem,
            instance: appData.modelInstance
          })
        }
      })
    }

    // Identify values being synced
    if (oneItem.isSynced === false) {
      rowCell.classes.push('notSynced')
    }
    rowObj.cells.push(rowCell)

    franchiseTemplateColumnContent({
      rowObj: rowObj,
      oneItem: oneItem,
      instance: params.instance
    })
    dimensionsColumnContent({
      rowObj: rowObj,
      oneItem: oneItem,
      instance: params.instance
    })

    // Column to navigate previous columns
    rowObj.cells.push({
      columnPosition: 'left',
      rowPosition: 'content',
      value: ''
    })

    const scenarioData = params.instance.data.dataset.scenarios.find(function (oneScenario) {
      return oneScenario._id === params.scenarioId
    })

    const itemScenarioData = oneItem.byScenario[params.scenarioId]
    itemScenarioData.c_calculatedValues.forEach(function (oneCalculatedValue, stepIndex) {
      // Using the view settings: only show values after the offset
      if (stepIndex < params.instance.data.timeseries.periodSeriesViewOffset) return

      const classes = []
      const infoAttributes = []

      let cellValue = '-'
      if (oneCalculatedValue === null) cellValue = 'n'
      if (oneCalculatedValue === false) cellValue = '!!'
      if (oneCalculatedValue === undefined) cellValue = 'u'
      if (oneCalculatedValue === Infinity) cellValue = '∞'
      if (isNaN(oneCalculatedValue)) cellValue = 'nan'

      // Show the calculated value
      if (Number.isFinite(oneCalculatedValue)) {
        const formatParams = Object.assign({}, scenarioData?.forcedFormat)
        formatParams.value = oneCalculatedValue
        cellValue = modelAppFormatting.format(formatParams)
      }
      // To show the order of calculation
      // cellValue = itemScenarioData.c_evaluationOrder[stepIndex]

      // Evaluation Runs
      // cellValue = itemScenarioData.c_evaluationRun[stepIndex]

      // cellValue = (cellValue || '').toString().length

      if ([0, null, false, undefined, Infinity, NaN].includes(oneCalculatedValue)) {
        classes.push('isZero')
      }

      const rowCell = {
        value: cellValue,
        columnPosition: 'center',
        rowPosition: 'content',
        classes: classes,
        info: infoAttributes,
        cellId: generateCellId(),
        contextMenu: [
          {
            label: 'Evaluate cell',
            action: function () {
              debugLog.log('clicked evaluate')
              const cellValueObj = appData.modelInstance.helpers.evaluateItemStepValue({
                item: oneItem,
                stepIndex: stepIndex,
                scenarioId: params.scenarioId,
                instance: params.instance,
                forceCalculation: true,
                resetCalculation: true,
                showDebug: true
              })

              debugLog.log('cellValue', cellValueObj)

              // const stepFormulaObj = cellValueObj.stepFormula
              // debugLog.log('html:', stepFormulaObj?.c_mathParsed?.toHTML())
              // debugLog.log('html transformed:', stepFormulaObj?.c_mathParsedTransformed?.toHTML())

              // debugLog.log('with values:')

              // stepFormulaObj.c_refObj?.forEach(function (oneRef) {
              //   debugLog.log(oneRef.refId, oneRef.value)
              // })

              // Show in modal
              const modalBodyElement = document.querySelector('#modelModal .modal-body')
              debugLog.log('modalElement', modalBodyElement)
              modalBodyElement.innerHTML = '' // Reset
              modalBodyElement.appendChild(cellValueObj.debugHTMLElement)

              appData.modelInstance.context.modelModalElement.show()
            }
          },
          {
            isSeparator: true
          },
          {
            label: 'Cell Value: ' + oneCalculatedValue
          },
          {
            label: 'Cell Content length: ' + (cellValue || '').toString().length
          },
          {
            label: 'Evaluation order: ' + itemScenarioData.c_evaluationOrder[stepIndex]
          },
          {
            label: 'Evaluation run: ' + itemScenarioData.c_evaluationRun[stepIndex]
          }
        ]
      }

      infoAttributes.push({ stepIndex: stepIndex })
      infoAttributes.push({ itemId: itemId })
      infoAttributes.push({ scenarioId: params.scenarioId })
      infoAttributes.push({ cellId: generateCellId(false) })

      if (itemScenarioData.c_evaluationNote[stepIndex].isCircular) {
        classes.push('isCircular')
      }
      if (itemScenarioData.c_evaluationNote[stepIndex].isError) {
        // classes.push('isError')
        rowCell.value = '!' + rowCell.value
        rowCell.contextMenu.push({
          label: 'Error while calculating',
          action: function () {
            debugLog.log('click error:', itemScenarioData.c_evaluationNote[stepIndex].note)
          }
        })
      }

      if (itemScenarioData.c_stepFormulasChanges[stepIndex]) {
        if (itemScenarioData.c_stepFormulasChanges[stepIndex].c_isHardCoded) {
          classes.push('isHardCoded')
          infoAttributes.push({ hardCoded: true })
        } else {
          classes.push('isFormula')
        }

        // Identify values being synced
        if (itemScenarioData.c_stepFormulasChanges[stepIndex].isSynced === false) {
          classes.push('isNotSynced')
        }

        // Identify circular dependencies
        if (itemScenarioData.c_stepFormulasChanges[stepIndex].c_isCircular) {
          classes.push('isCircular')
          rowCell.contextMenu.push({
            label: 'Could not calculate: Circular',
            action: function () {
              debugLog.log('click error')
            }
          })
        }

        if (appData.settings.mode !== 'local') {
          rowCell.contextMenu.push({
            isSeparator: true
          })
          rowCell.contextMenu.push({
            label: 'Delete value',
            action: function () {
              debugLog.log('called delete')

              itemFormulaModule.deleteFormula({
                instance: appData.modelInstance,
                itemFormulaId: itemScenarioData.c_stepFormulasChanges[stepIndex]._id
              })
            }
          })
        }
      }

      rowObj.cells.push(rowCell)
    })
    rows.push(rowObj)
  })

  return rows
}

/*
  To return previous id
  generateCellId(false)

  to generate and return a new id:
  generateCellId()
  generateCellId(true)
*/
let cellCounter = 0
function generateCellId (isToUpdate) {
  // update the id except when explicitly asked not to
  if (!(isToUpdate === false)) {
    cellCounter++
  }
  return 'id' + cellCounter
}

//
//
function drawHeader (params) {
  const headerObj = []

  // Column for item names
  headerObj.push({
    columnPosition: 'left',
    rowPosition: 'header'
  })

  const subParams = {
    headerObj: headerObj,
    instance: params.instance
  }
  franchiseTemplateColumnHeader(subParams)
  dimensionsColumnHeaders(subParams)

  // Column to navigate previous columns
  headerObj.push({
    columnPosition: 'left',
    rowPosition: 'header',
    value: '<=',
    info: [{
      target: -1,
      action: 'period:move'
    }]
  })

  // Each column of the header
  const timeseries = params.instance.data.timeseries.periodSeriesView
  timeseries.forEach(function (oneTime) {
    const oneHeaderCell = {}
    oneHeaderCell.value = modelAppFormatting.formatDate({
      date: oneTime,
      periodicity: appData.modelInstance.data.timeseries.usedSettings.periodicity
    })
    oneHeaderCell.columnPosition = 'center'
    oneHeaderCell.rowPosition = 'header'
    // Deactivating the one-click change period: use timeframe settings instead
    // oneHeaderCell.info = [{
    //   action: 'period:pick'
    // }]

    oneHeaderCell.contextMenu = [{
      label: 'Cell Content length:' + (oneHeaderCell.value || '').toString().length
    }]

    headerObj.push(oneHeaderCell)
  })

  // Adding/Removing columns from the table: de-activating as this is available from the timeframe settings now
  // Column to add columns
  // headerObj.push({
  //   columnPosition: 'right',
  //   rowPosition: 'header',
  //   value: '-',
  //   info: [{
  //     target: -1,
  //     action: 'period:add'
  //   }]
  // })
  // // Column to add columns
  // headerObj.push({
  //   columnPosition: 'right',
  //   rowPosition: 'header',
  //   value: '+',
  //   info: [{
  //     target: 1,
  //     action: 'period:add'
  //   }]
  // })

  // Column to navigate following columns
  headerObj.push({
    columnPosition: 'right',
    rowPosition: 'header',
    value: '=>',
    info: [{
      target: 1,
      action: 'period:move'
    }]
  })

  return headerObj
}

/*
  params
    .scenarioId
*/
function registerBehaviour (params = {}) {
  debugLog.log('registerBehaviour')
  // Manage the listener

  const modelInstance = params.modelInstance

  const modelContainerElement = document.getElementById('modelContainer')
  if (!modelContainerElement) return

  // Drag and drop needs to be re-registered: it's added at the element level, not the parent
  webUIItemSorting.enableDragAndDropSorting()

  // Check the listener has not been added already
  const isListenerAdded = modelContainerElement.getAttribute('isModelCellListenerAdded')
  if (isListenerAdded) return

  // Add listeners
  debugLog.log('adding listeners')
  modelContainerElement.addEventListener('click', webUIListeners.clickListener, true)

  modelContainerElement.addEventListener('contextmenu', function (event) {
    webUIListeners.contextMenuListener(
      {
        event: event,
        modelInstance
      }
    )
  }, true)
  // Touch screens don't have contextMenu right clicks, so we enable it on touch with >1 fingers
  modelContainerElement.addEventListener('touchstart', function (event) {
    debugLog.log('touchstart', event)
    if (event.touches.length === 1) return

    // Multi touch
    webUIListeners.contextMenuListener(
      {
        event: event,
        modelInstance
      }
    )
  }, true)

  modelContainerElement.addEventListener('mouseover', webUIListeners.rowHoverListener, true)
  modelContainerElement.addEventListener('mouseout', webUIListeners.rowHoverListener, true)

  // Record that listeners are added
  modelContainerElement.setAttribute('isModelCellListenerAdded', true)
}

//
//
//
/*
  runParams
    .presetData: String
    .data:
      .items
      .itemsFormulas
      .scenarios?
    .scenarioId

  TODO: a version which only refreshes the item?
*/
export function runAppCalculations (runParams = {}) {
  debugLog.log('UI runApp', runParams)

  // Check which scenario to run
  let scenarioId = runParams.scenarioId ? runParams.scenarioId : false
  if (!scenarioId) {
    scenarioId = appData.modelInstance?.data?.dataset?.scenarios[0]?._id
  }

  runScenario({
    scenarioId: scenarioId,
    instance: appData.modelInstance
  })
}

/*
  @calculationParams
    .scenarioId
*/
export function runScenario (calculationParams) {
  // Keep track of scenario being viewed
  appData.settings = appData.settings || {}
  appData.settings.view = {
    scenarioId: calculationParams.scenarioId
  }

  debugLog.log('runScenario', calculationParams)

  console.time('runScenario')
  calculationParams.instance.calculateValues({ scenarioId: calculationParams.scenarioId })

  debugLog.log('time to draw table')
  const tableJS = drawTable(calculationParams)
  calculationParams.instance.context.tableJS = tableJS

  debugLog.log('time to add behaviour')
  registerBehaviour(
    {
      calculationParams,
      modelInstance: calculationParams.instance
    }
  )

  debugLog.log('time to add scenario')
  moduleScenarioPicker.proposeScenarios(calculationParams)

  console.timeEnd('runScenario')
}
