import * as axios from 'axios'

import * as envVar from '../../env.js'

console.log('modelSharingController')

export const modalDOMElementId = 'modelSharingModal'

const DOMelementIds = {
  titleModelName: 'modelName',
  modelContentId: 'modelSharingModalContent'
}

let modalElement
const settings = {}

const modelSharing = `
<div class="modal fade" id="${modalDOMElementId}" tabindex="-1" aria-labelledby="modelSharingModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Share the model '<span id="${DOMelementIds.titleModelName}">...</span>'</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="${DOMelementIds.modelContentId}"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
`

export function insertModal (modelData) {
  console.log('insertModal')
  // Store in the component
  settings.modelData = modelData

  // Check if already there and stops if it is
  modalElement = document.getElementById(modalDOMElementId)
  if (modalElement) return

  // Create it
  const modal = document.createElement('div')
  modal.innerHTML = modelSharing
  // console.log('modelSharing', modelSharing)

  document.body.append(modal)

  // Store it in the module
  modalElement = document.getElementById(modalDOMElementId)
  onShow()
  onHide()
}

function onHide () {
  // console.log('onHide add listener')
  modalElement.addEventListener('hidden.bs.modal', function (event) {
    // console.log('hidden.bs.modal show')
    // console.log(event)
    settings.shownModal = false
  })
}

function onShow () {
  // console.log('onShow add listener')
  modalElement.addEventListener('shown.bs.modal', function (event) {
    // console.log('shown.bs.modal show')
    // console.log(event)

    // For some reason, the event is fired twice, so we check the property
    if (settings.shownModal) return
    loadModalContent()
  })
}

function showModelName () {
  const DOMelement = document.getElementById(DOMelementIds.titleModelName)
  if (!DOMelement) return

  DOMelement.innerText = settings.modelData.name
}

function loadModalContent () {
  // console.log('loadModalContent')
  settings.shownModal = true

  const DOMelement = document.getElementById(DOMelementIds.modelContentId)
  if (!DOMelement) return

  showModelName()

  const htmlArray = []
  htmlArray.push('<div>Invite:</div>')
  htmlArray.push('<div><input id="inviteEmail" type="email" class="form-control" /></div>')
  htmlArray.push('<div><button type="button" id="sendInvite" class="btn btn-sm btn-primary">Invite</button></div>')

  htmlArray.push('<div>Sharing with:</div>')
  htmlArray.push('<div id="modelInvites"></div>')

  DOMelement.innerHTML = htmlArray.join('')

  loadModelInvites()
  manageSendInvite()
}

function manageSendInvite () {
  // console.log('manageSendInvite')
  const DOMelement = document.getElementById('sendInvite')
  // console.log('DOMelement', DOMelement)
  if (!DOMelement) return

  DOMelement.addEventListener('click', function (clickEvent) {
    // console.log('sendInvite clicked')
    const inviteeEmail = document.getElementById('inviteEmail').value
    // console.log('inviteeEmail', inviteeEmail)

    const queryUrl = [envVar.apiUrl, 'invites']

    const editBody = {
      modelId: settings.modelData._id,
      email: inviteeEmail,
      rights: ['canEdit', true]
    }
    // console.log('editBody', editBody)

    axios.post(queryUrl.join('/'), editBody)
      .then(function (response) {
        console.log('response', response)
        loadModelInvites()
      })
      .catch(function (error) {
        console.error('error', error)
      })
  })
}

function inviteRightsToggle (oneInvite) {
  const htmlArray = []

  const rights = {
    canView: 'View',
    canEdit: 'Edit'
  }

  function generateToggle (rightType) {
    const toggleHtml = []
    toggleHtml.push('<div>')
    toggleHtml.push(rights[rightType])
    toggleHtml.push('<label class="switch" d-rightType="RIGHTS_TYPE">')
    toggleHtml.push('<input type="checkbox" IS_TRUE>')
    toggleHtml.push('<span class="slider round"></span>')
    toggleHtml.push('</label>')
    toggleHtml.push('</div>')

    let toggleString = toggleHtml.join('')

    if (oneInvite.rights?.[rightType] === true) {
      toggleString = toggleString.replace('IS_TRUE', 'checked')
    } else {
      toggleString = toggleString.replace('IS_TRUE', '')
    }

    toggleString = toggleString.replace('RIGHTS_TYPE', rightType)

    return toggleString
  }

  htmlArray.push(generateToggle('canView'))
  htmlArray.push(generateToggle('canEdit'))
  return htmlArray.join('')
}

function fillOneInviteContent (params) {
  // console.log('fillOneInviteContent', params)
  if (!params.DOMelement) return

  const htmlArray = []
  const oneInvite = params.invite

  // active user
  if (oneInvite._user) {
    htmlArray.push('<div class="card-body">')
    htmlArray.push('<h5 class="card-title">' + oneInvite._user.name + '</h5>')
    htmlArray.push('<h6 class="card-subtitle mb-2 text-muted">' + oneInvite._user.email + '</h6>')
    htmlArray.push('</div>')

    htmlArray.push('<div class="card-body">')
    htmlArray.push(inviteRightsToggle(oneInvite))
    htmlArray.push('</div>')
  } else {
    htmlArray.push('<div class="card-body">')
    htmlArray.push('<h5 class="card-title">' + oneInvite.email + '</h5>')
    htmlArray.push('<h6 class="card-subtitle mb-2 text-muted">Not yet accepted</h6>')
    htmlArray.push('</div>')

    htmlArray.push('<div class="card-body">')
    htmlArray.push(inviteRightsToggle(oneInvite))
    htmlArray.push('</div>')

    htmlArray.push('<div class="card-body">')
    const inviteUrl = location.origin + '/app/invite/' + oneInvite._id
    htmlArray.push('<div>Share invite: <input type="text" class="form-control inviteUrl" value="' + inviteUrl + '"/></div>')
    htmlArray.push('</div>')
  }

  params.DOMelement.innerHTML = htmlArray.join('')
}

export function showOneInvite (oneInvite) {
  // console.log('showOneInvite', oneInvite)
  const DOMelement = document.getElementById('modelInvites')

  const element = document.createElement('div')
  element.classList.add('card')
  element.classList.add('isInvite')
  element.setAttribute('d-inviteId', oneInvite._id)

  fillOneInviteContent({
    DOMelement: element,
    invite: oneInvite
  })

  DOMelement.append(element)
}

function loadModelInvites () {
  const queryUrl = [envVar.apiUrl, 'invites', 'bymodel', settings.modelData._id]

  axios.get(queryUrl.join('/'))
    .then(function (response) {
      // console.log('response', response)
      const DOMelement = document.getElementById('modelInvites')
      DOMelement.innerHTML = ''

      if (!response.data || !response.data.data) console.warn('no invite')

      response.data.data.forEach(function (oneInvite) {
        showOneInvite(oneInvite)
      })
    })
    .catch(function (error) {
      console.error('error', error)
    })

  addListeners()
}

function addListeners () {
  document.getElementById('modelInvites').addEventListener('click', function (clickEvent) {
    listenToRightsToggles(clickEvent)
    listenToInviteUrlClick(clickEvent)
  })
}

/*
  @params
    .inviteId
*/
async function updateRights (params = {}) {
  // console.log('updateRights', params)
  const queryUrl = [envVar.apiUrl, 'invites', params.inviteId]

  const editBody = {
    rights: [params.updatedRight, params.updatedValue]
  }

  axios.put(queryUrl.join('/'), editBody)
    .then(function (response) {
      // console.log('response', response)
      if (!response.data || !response.data.data) console.warn('no invite')

      fillOneInviteContent({
        DOMelement: params.DOMelement,
        invite: response.data.data
      })
    })
    .catch(function (error) {
      console.error('error', error)
    })
}

function listenToRightsToggles (clickEvent) {
  // console.log('---')
  // console.log('listenToRightsToggles')

  // The input checkbox has a default event, we silence it
  if (clickEvent.target.tagName === 'INPUT') return

  const toggleDOM = clickEvent.target.closest('.switch')
  if (!toggleDOM) return
  // console.log('toggleDOM?', toggleDOM)

  const updatedRight = toggleDOM.getAttribute('d-rightType')
  const updatedValue = !toggleDOM.querySelector('input').checked // We take the inverse because the checked value has a delay

  // console.log(updatedRight, updatedValue)

  const inviteDOM = clickEvent.target.closest('.isInvite')
  // console.log('inviteDOM', inviteDOM)
  if (!inviteDOM) return

  const inviteId = inviteDOM.getAttribute('d-inviteId')
  updateRights({
    DOMelement: inviteDOM,
    inviteId: inviteId,
    updatedRight: updatedRight,
    updatedValue: updatedValue
  })
}

function listenToInviteUrlClick (clickEvent) {
  // console.log('listenToInviteUrlClick')
  function copy (inviteUrlDOM) {
    inviteUrlDOM.select()
    document.execCommand('copy')
    // console.log('copied', inviteUrlDOM.value)
  }

  const inviteUrlDOM = clickEvent.target.closest('.inviteUrl')
  if (!inviteUrlDOM) return

  // console.log('url clicked')
  copy(inviteUrlDOM)
}
