
/*
  On iPad, the option selector show 2 pre-selected value for some reason, so we try here

  <select>
    <option value="a1" select>a1</option>
    <option value="a2">a2</option>
  </select>
*/
export function load () {
  const DOMelement = document.getElementById('content')
  if (!DOMelement) return

  const selectorElement1 = generateSelector1()
  DOMelement.append(selectorElement1)

  const selectorElement2 = generateSelector2()
  DOMelement.append(selectorElement2)
}

function generateSelector1 (params) {
  const selectElement = document.createElement('select')
  selectElement.classList.add('form-control')

  selectElement.append(generateOption({
    value: 'Not Selected',
    isSelected: false
  }))

  selectElement.append(generateOption({
    value: 'Selected',
    isSelected: true
  }))

  return selectElement
}

function generateSelector2 (params) {
  const selectElement = document.createElement('select')
  selectElement.classList.add('form-control')

  selectElement.append(generateOption({
    value: 'notSelected1',
    isSelected: false
  }))

  selectElement.append(generateOption({
    value: 'notSelected2',
    isSelected: false
  }))

  return selectElement
}

function generateOption (params) {
  const optionElement = document.createElement('option')
  optionElement.setAttribute('value', params.value)
  optionElement.innerText = params.value

  if (params.isSelected) optionElement.setAttribute('selected', true)

  return optionElement
}
