
import * as logicFormulaPeriods from './logic-formula-periods.js'
import * as logicFormulaAggregations from './logic-formula-aggregations.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('eval:periodValues')
debugLog.disable()

/*
  params.scenarioId
  params.stepIndex
  params.instance
  params.ref = {
    itemId,
    period,
    function
  }
*/
export function evaluateItemStepPeriodValues (params = {}) {
  debugLog.log('... evaluateItemStepPeriodValues', params)

  // Turn the ref period into absolute instead of relative
  const periodAbs = logicFormulaPeriods.returnPeriodArrayParse({
    period: params.ref.period,
    stepIndex: params.stepIndex,
    instance: params.instance
  })
  debugLog.log('periodAbs', periodAbs)

  const instanceDataItems = params.instance.data?.dataset?.items
  if (!instanceDataItems) return false

  // Find the targetItem
  const targetItem = instanceDataItems.find(function (oneItem) {
    return params.instance.helpers.getItemId(oneItem) === params.ref.itemId
  })
  if (!targetItem) {
    console.warn('!!! no item:', params.ref.itemId)
    return false
  }

  // Find the values
  const values = []
  for (let i = periodAbs[0]; i <= periodAbs[1]; i++) {
    if (i < 0) {
      values.push(0) // enforce initial values
    } else {
      const valueObj = params.instance.helpers.evaluateItemStepValue({
        scenarioId: params.scenarioId,
        instance: params.instance,
        item: targetItem,
        stepIndex: i,
        parentParams: params.parentParams
      })
      const value = valueObj.value
      values.push(value)
    }
  }
  debugLog.log('values:', values)

  // Apply the aggregation
  const value = logicFormulaAggregations.applyAggregationFunction({
    function: params.ref.function,
    values: values
  })
  debugLog.log('aggregated value', value)

  return value
}
