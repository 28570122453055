
import dayjs from 'dayjs'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:series')
debugLog.disable()

/*
  @requestedSettings
    .periodicity: type of periods
    .starting: initial value
    .periods: number of period to generate
*/
export function generateSeries (requestedSettings = {}, modelInstance = {}) {
  debugLog.log('generateSeries', arguments)

  // Basics settings
  const usedSettings = {
    periodicity: requestedSettings.periodicity || 'month', // Defaults to monthly
    periodsView: requestedSettings.periods || 12,
    periods: requestedSettings.periods || 12
  }

  // Find the dates to consider
  const modelStartingPeriod = defaultStartingPeriod(modelInstance)
  const viewStartingPeriod = requestedSettings.starting || modelStartingPeriod || currentMonth()
  const calculationStartingPeriod = neededCalculationStartingPeriod({
    modelStartingPeriod,
    viewStartingPeriod,
    periodicity: usedSettings.periodicity
  })

  usedSettings.modelStartingPeriod = modelStartingPeriod // TEST
  usedSettings.starting = calculationStartingPeriod
  usedSettings.startingView = viewStartingPeriod

  if (!periodicityFunctions[usedSettings.periodicity]) {
    debugLog.error('No valid series generation function')
    return
  }
  const nextPeriodFunction = periodicityFunctions[usedSettings.periodicity]

  // Calculate the adjustment between calculation and view
  if (modelStartingPeriod < viewStartingPeriod) {
    // Generate series between calculation and view
    const periodsSeriesToViewTimeframe = generatePeriodSeries({
      startingPeriod: usedSettings.starting,
      endingPeriod: usedSettings.startingView,
      nextPeriodFunction: nextPeriodFunction
    })
    usedSettings.periods = usedSettings.periods + periodsSeriesToViewTimeframe.length
  }

  // Generate the timeframes
  const periodSeriesCalculation = generatePeriodSeries({
    startingPeriod: usedSettings.starting,
    numberPeriods: usedSettings.periods,
    nextPeriodFunction: nextPeriodFunction
  })
  const periodSeriesCalculationTo = generatePeriodSeriesTo({
    periodSeries: periodSeriesCalculation,
    periodicity: usedSettings.periodicity
  })
  const periodSeriesView = generatePeriodSeries({
    startingPeriod: usedSettings.startingView,
    numberPeriods: usedSettings.periodsView,
    nextPeriodFunction: nextPeriodFunction
  })
  const periodSeriesViewTo = generatePeriodSeriesTo({
    periodSeries: periodSeriesView,
    periodicity: usedSettings.periodicity
  })

  const timeseriesSettings = {
    periodSeries: periodSeriesCalculation,
    periodSeriesCalculationTo: periodSeriesCalculationTo,
    periodSeriesView: periodSeriesView,
    periodSeriesViewTo: periodSeriesViewTo,
    usedSettings: usedSettings,
    requestedSettings: requestedSettings
  }

  generateMappingViewToValue(timeseriesSettings)
  identifyPastPresent(timeseriesSettings)

  return timeseriesSettings
}

/*
  Calculate the last day of every period
*/
function generatePeriodSeriesTo (params) {
  const periodSeries = params.periodSeries
  const periodicity = params.periodicity

  if (periodicity === 'day') {
    return periodSeries
  }

  const periodSeriesTo = []
  periodSeries.forEach(function (onePeriod, index) {
    // The period end on the last day before the next period
    let nextPeriod = periodSeries[index + 1]
    if (!nextPeriod) {
      // If we don't know the next period, we generate it
      const periodicityUnits = periodicityMap[periodicity]
      nextPeriod = dayjs(onePeriod, 'YYYY-MM-DD').add(periodicityUnits.number, periodicityUnits.timeUnit).format('YYYY-MM-DD')
    }
    const periodTo = dayjs(nextPeriod, 'YYYY-MM-DD').add(-1, ' day').format('YYYY-MM-DD')

    periodSeriesTo.push(periodTo)
  })

  return periodSeriesTo
}

/*
  calculation: 2021-01-01
  view: 2021-03-01
  periodicity: quarter
  -> does not match!

  So we backtrack the needed calculation date as needed
*/
function neededCalculationStartingPeriod (params) {
  debugLog.log('neededCalculationStartingPeriod')
  const modelStartingPeriod = params.modelStartingPeriod
  const viewStartingPeriod = params.viewStartingPeriod
  const periodicity = params.periodicity

  // When model has no date, we use the view one
  if (!modelStartingPeriod) return viewStartingPeriod

  const modelDate = dayjs(modelStartingPeriod, 'YYYY-MM-DD')
  const viewDate = dayjs(viewStartingPeriod, 'YYYY-MM-DD')

  if (viewDate.isBefore(modelDate)) {
    return viewStartingPeriod
  }

  // When the calculation is before the view, we ensure it's for matching periods
  const periodicityUnits = periodicityMap[periodicity]
  let testDate = viewDate
  let maxLoop = 1000
  // debugLog.log(periodicity, 'testDate', maxLoop, testDate.format('YYYY-MM-DD'), modelDate.format('YYYY-MM-DD'))
  while (testDate.isAfter(modelDate) && maxLoop > 0) {
    // debugLog.log(periodicity, 'testDate', maxLoop, testDate.format('YYYY-MM-DD'))
    testDate = testDate.add(-1 * periodicityUnits.number, periodicityUnits.timeUnit)

    maxLoop--
  }

  const calculatedDate = testDate.format('YYYY-MM-DD')
  return calculatedDate
}

/*
  .periodSeries is always starting from 1st value to allow proper calculations.
  .periodSeriesView might start at a later index;
  This function gives an array of index to quickly access the proper calculated value
*/
function generateMappingViewToValue (timeseriesSettings) {
  timeseriesSettings.periodSeriesViewOffset = timeseriesSettings.periodSeries.indexOf(timeseriesSettings.periodSeriesView[0])

  timeseriesSettings.periodSeriesViewIndex = []

  timeseriesSettings.periodSeriesView.forEach(function (oneValue) {
    const mappedIndex = timeseriesSettings.periodSeries.indexOf(oneValue)
    timeseriesSettings.periodSeriesViewIndex.push(mappedIndex)
  })

  return timeseriesSettings
}

function identifyPastPresent (timeseriesSettings) {
  timeseriesSettings.periodSeriesViewIsFuture = []

  const today = new Date()

  timeseriesSettings.periodSeriesView.forEach(function (oneValue) {
    const isFuture = (new Date(oneValue) - today) > 0
    timeseriesSettings.periodSeriesViewIsFuture.push(isFuture)
  })

  return timeseriesSettings
}

//
//
function generatePeriodSeries (params) {
  const startingPeriod = params.startingPeriod
  const nextPeriodFunction = params.nextPeriodFunction

  // One or the other:
  const numberPeriods = params.numberPeriods
  const endingPeriod = params.endingPeriod

  function isToContinue (loopParams) {
    if (Number.isFinite(numberPeriods)) {
      return loopParams.periodCounter < numberPeriods
    } else if (endingPeriod) {
      return loopParams.generationCurrentPeriod < endingPeriod
    }
    return false
  }

  const loopParams = {
    generationCurrentPeriod: startingPeriod,
    periodCounter: 0
  }

  const periodSeries = []
  while (isToContinue(loopParams)) {
    periodSeries.push(loopParams.generationCurrentPeriod)
    loopParams.generationCurrentPeriod = nextPeriodFunction(loopParams.generationCurrentPeriod)
    loopParams.periodCounter++
  }
  return periodSeries
}

function defaultStartingPeriod (modelInstance) {
  debugLog.log('defaultStartingPeriod', arguments)
  const instanceDataset = modelInstance?.data?.dataset
  if (!instanceDataset) return currentMonth()

  // If available, default to first formula period
  let minPeriod = false
  instanceDataset.valueFormulas.forEach(function (oneFormulaObj) {
    minPeriod = (!minPeriod || minPeriod > oneFormulaObj.definition.first_period) ? oneFormulaObj.definition.first_period : minPeriod
  })

  return minPeriod
}

function currentMonth () {
  const month = [new Date().toJSON().substr(0, 7), '-01']
  return month.join('')
}

const periodicityMap = {
  day: {
    number: 1,
    timeUnit: 'day'
  },
  week: {
    number: 7,
    timeUnit: 'day'
  },
  month: {
    number: 1,
    timeUnit: 'month'
  },
  quarter: {
    number: 3,
    timeUnit: 'month'
  },
  year: {
    number: 12,
    timeUnit: 'month'
  }
}

export const periodicityFunctions = {
  day: nextDay,
  week: nextWeek,
  month: nextMonth,
  quarter: nextQuarter,
  // halfyear: nextHalfyear,
  year: nextYear
  // relative: nextRelative
}

export function availablePeriodicity () {
  return Object.keys(periodicityFunctions)
}

function moveChange (whichWay) {
  return [1, -1].includes(whichWay) ? whichWay : 1
}

function nextDay (currentPeriod, whichWay) {
  whichWay = moveChange(whichWay)
  const currentPeriodDate = new Date(currentPeriod)
  const currentValue = currentPeriodDate.getDate()
  const nextPeriod = (currentPeriodDate).setDate(currentValue + 1 * whichWay)
  const nextPeriodValue = new Date(nextPeriod).toJSON().substr(0, 10)
  return nextPeriodValue
}

function nextWeek (currentPeriod, whichWay) {
  whichWay = moveChange(whichWay)
  const currentPeriodDate = new Date(currentPeriod)
  const currentValue = new Date(currentPeriodDate).getDate()
  const nextPeriod = (currentPeriodDate).setDate(currentValue + 7 * whichWay)
  const nextPeriodValue = new Date(nextPeriod).toJSON().substr(0, 10)
  return nextPeriodValue
}

function nextMonth (currentPeriod, whichWay) {
  whichWay = moveChange(whichWay)
  const currentPeriodDate = new Date(currentPeriod)
  const currentValue = new Date(currentPeriodDate).getMonth()
  const nextPeriod = (currentPeriodDate).setMonth(currentValue + 1 * whichWay)
  const nextPeriodValue = new Date(nextPeriod).toJSON().substr(0, 10)
  return nextPeriodValue
}

function nextQuarter (currentPeriod, whichWay) {
  debugLog.log('nextQuarter', currentPeriod, whichWay)
  whichWay = moveChange(whichWay)
  const currentPeriodDate = new Date(currentPeriod)
  const currentValue = new Date(currentPeriodDate).getMonth()
  const nextPeriod = (currentPeriodDate).setMonth(currentValue + 3 * whichWay)
  const nextPeriodValue = new Date(nextPeriod).toJSON().substr(0, 10)
  return nextPeriodValue
}

function nextYear (currentPeriod, whichWay) {
  whichWay = moveChange(whichWay)
  const currentPeriodDate = new Date(currentPeriod)
  const currentValue = new Date(currentPeriodDate).getMonth()
  const nextPeriod = (currentPeriodDate).setMonth(currentValue + 12 * whichWay)
  const nextPeriodValue = new Date(nextPeriod).toJSON().substr(0, 10)
  return nextPeriodValue
}

// function nextRelative (currentPeriod, whichWay) {
//   whichWay = moveChange(whichWay)
//   return +(currentPeriod) + 1 * whichWay
// }

/*
  targetPeriod: oneValueFormula.definition.first_period
*/
export function startingPeriodIndex (params = {}) {
  const modelTimeSeries = params.modelTimeSeries
  const targetPeriod = params.targetPeriod

  const exactMatchingPeriod = modelTimeSeries.indexOf(targetPeriod)
  if (exactMatchingPeriod >= 0) {
    return exactMatchingPeriod
  } else {
    let periodMatching
    modelTimeSeries.forEach(function (oneTimeSeriesValue, arrayIndex) {
      const nextTimeSeriesValue = modelTimeSeries[arrayIndex + 1]
      // debugLog.log(oneTimeSeriesValue, ' <= ', targetPeriod, ' <= ', nextTimeSeriesValue)
      if (!periodMatching && oneTimeSeriesValue <= targetPeriod && targetPeriod <= nextTimeSeriesValue) {
        // debugLog.log('match')
        periodMatching = arrayIndex
      }
    })
    return periodMatching
  }
}

/*
  Calculation series is in periodSeries
  View series is in periodSeriesView
*/
export function getPeriodAbsolute (params) {
  debugLog.log('getPeriodAbsolute', params)
  const seriesArray = params.periodSeries || params.periodSeriesView
  const stepIndex = params.stepIndex

  return seriesArray[stepIndex]
}

export function moveDate (date, periodicity, movement) {
  return dayjs(date, 'YYYY-MM-DD').add(movement, periodicity).format('YYYY-MM-DD')

  // Otherwise, need to deal with the different length of months
  // use '0' to get the previous day
  // calculate the end of the month
  // then use the best value of max of month or previous values
  // let movedDate = new Date(baseDate.getFullYear(), baseDate.getMonth()-1,0)
  // console.log(movedDate)
}

export function endOfMonth (date) {
  return dayjs(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
}
