
import * as userModule from '../user.js'

import * as itemFormulaModule from './control-itemformulas.js'
import itemFormulaForm from './editor.html'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('formula:editor')
// debugLog.disable()

export function loadEditor (params = {}) {
  debugLog.log('load item formula editor')

  params.targetElement = params.targetElement || 'itemFormulaRow'
  const targetDOMelement = document.getElementById(params.targetElement)
  if (!targetDOMelement) return

  //
  const formContainerElement = document.createElement('div')
  formContainerElement.classList.add('gridArea')
  formContainerElement.innerHTML = itemFormulaForm

  targetDOMelement.innerHTML = '' // Clean
  targetDOMelement.append(formContainerElement)
  debugLog.log('item element added')

  const userData = userModule.userData()
  debugLog.log('userData', userData)

  if (!userData) {
    debugLog.log('hide fields when not logged')
    // Hide form
    document.getElementById('itemFormulaEditForm').setAttribute('hidden', true)

    // document.getElementById(itemFormulaModule.createItemFormulaButtonId).setAttribute('hidden', true)
    // document.getElementById('itemFormulaId').setAttribute('hidden', true)
    // document.getElementById('itemFormulaItemId').setAttribute('hidden', true)
    // document.getElementById('itemFormulaScenarioId').setAttribute('hidden', true)
    // document.getElementById('itemFormulaStartingPeriod').setAttribute('hidden', true)

    // Explain
    document.getElementById('itemFormulaEditContainer').append('Sign in/up or Copy the Model to edit formulas')
  }

  params.instance = appData.modelInstance
  itemFormulaModule.listenToCreate(params)
}
