
import * as userModule from '../frontend/user.js'
import htmlMenuLogged from './page-menu.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('module:pageMenu')
debugLog.disable()

const DOMelementId = 'headerMenu'

export function refresh () {
  debugLog.log('refresh')
  const userData = userModule.userData()

  const DOMelement = document.getElementById(DOMelementId)
  if (!DOMelement) return

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const menuElement = document.createElement('div')
  menuElement.innerHTML = htmlMenuLogged

  if (!userData) {
    menuElement.querySelectorAll('li').forEach(function (oneLi) {
      const ifLogged = oneLi.getAttribute('v-ifLoggedOnly')
      debugLog.log(oneLi, ifLogged)
      if (ifLogged) {
        oneLi.remove()
      }
    })
  }

  // Admin
  if (userData && userData.email === 'florian@futureflow.io') {
    const adminLink = document.createElement('li')
    adminLink.innerHTML = '<a href="/app/datasync">Admin</a>'

    const ulElement = menuElement.querySelector('ul')
    ulElement.append(adminLink)
  }

  DOMelement.innerHTML = ''
  DOMelement.append(menuElement)
}
