
import * as modelAppHelpers from './helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:autoCompare')
debugLog.disable()

/*
  Calculate the Delta% between 2 scenarios
  @params
    // base
    .scenarioId1
    .periodRef1
    .baseItem

    // to be compared
    .scenarioId2
    .periodRef2

    // Settings
    .forcedFormat: {shortcut: '%'} to pass in formatting module
    .formulaType:
      delta (ref2/ref1-1) DEFAULT,
      ratio (ref2/ref1)

    .scenarioTypes
    .instance

  We assume the items are identical in every scenario
*/
export function calculateScenarioDeltas (params) {
  debugLog.log('calculateScenarioDeltas', params)
  if (!params.scenarioId1) return
  if (!params.scenarioId2) return

  // Create a scenario with every item
  const scenarioId = ['delta', params.scenarioId1, params.scenarioId2, params.periodRef1, params.periodRef2, params.function1, params.scenarioTypes, modelAppHelpers.generateRandomString()].join('.')

  const baseItemId = params.baseItem ? params.instance.helpers.getItemId(params.baseItem) : false

  const newScenario = {
    _id: scenarioId,
    name: scenarioId,
    c_type: params.scenarioTypes,
    c_isAutoScenario: true,
    c_params: {
      // sourceParams: params.scenarioSourceParams,
      baseItemId,
      scenarioId1: params.scenarioId1,
      periodRef1: params.periodRef1,
      function1: params.function1,

      scenarioId2: params.scenarioId2,
      periodRef2: params.periodRef2
    },
    forcedFormat: params.forcedFormat,
    alertErrors: false // Auto scenarios might lead to formulas evaluating as null/null ... which should be null, but not highlight an error
  }
  params.instance.update.scenario.add(newScenario)

  // Add the formulas
  params.instance.data.dataset.items.forEach(function (oneItem) {
    const deltaFormulaParams = {
      baseItemId,
      itemId: params.instance.helpers.getItemId(oneItem),
      scenarioId1: params.scenarioId1,
      periodRef1: params.periodRef1 || 0,
      function1: params.function1,

      scenarioId2: params.scenarioId2,
      periodRef2: params.periodRef2 || 0,
      deltaScenario: newScenario,
      formulaType: params.formulaType,
      instance: params.instance
    }
    addDeltaFormula(deltaFormulaParams)
  })

  if (params.isCalculateToRun) {
    params.instance.calculateValues({ scenarioId: scenarioId })
  }

  return newScenario
}

function addDeltaFormula (params) {
  debugLog.log('addDeltaFormula', params)

  // Base
  let ref1 = {
    itemId: params.baseItemId ? params.baseItemId : params.itemId,
    period: '' + params.periodRef1, // formulas use strings
    scenarioId: params.scenarioId1
  }
  if (params.function1) ref1.function = params.function1
  ref1 = JSON.stringify(ref1)

  // To be compared
  let ref2 = {
    itemId: params.itemId,
    period: '' + params.periodRef2,
    scenarioId: params.scenarioId2
  }
  ref2 = JSON.stringify(ref2)

  // delta (ref2/ref1-1) DEFAULT,
  //  ratio (ref2/ref1)
  const formulaArray = []
  if (params.formulaType === 'ratio') {
    formulaArray.push(ref2)
    formulaArray.push(' / ')
    formulaArray.push(ref1)
  } else {
    formulaArray.push(ref2)
    formulaArray.push(' / ')
    formulaArray.push(ref1)
    formulaArray.push('-1')
  }
  const formula = formulaArray.join('')
  debugLog.log(formula)

  const deltaFormula = {
    c_isAutoFormula: true,
    c_deltaScenario: params.deltaScenario,
    tempId: modelAppHelpers.generateRandomString(),
    itemId: params.itemId,
    scenarioId: params.deltaScenario._id,
    periods: {
      first_period: params.instance.data.timeseries.periodSeries[0]
    },
    formula: formula
  }

  params.instance.formulas.add(deltaFormula)
}
