
import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('webui:chartWithSetXaxis')
debugLog.disable()

/*
  @params
    .instance
    .scenarioId
    .hideZeros // NOT IMPLEMENTED YET

    .xFilter: itemDimensionData // metric=YoYGrowth
    .yFilter: itemDimensionData // metric=revenueMultiplier
    .groupFilter: // Optional, defaults to __symbol
*/
export function prepareChart (params) {
  debugLog.log('prepareChart', params)
  const modelInstance = params.instance

  // find the items to cover
  const xFilterItemDimensionData = params.xFilter
  const yFilterItemDimensionData = params.yFilter

  const groupFilter = params.groupFilter || '__symbol' // Currently we don't allow user to pick their group

  const chartGroupedValues = {}

  modelInstance.data.dataset.items.forEach(function (oneItem, arrayIndex) {
    debugLog.log('oneItem', oneItem)

    // Ensure the item matches the filter
    if (!oneItem.dimensions) return

    // Check for X
    const xAxisValues = findValues({
      itemDimensionData: xFilterItemDimensionData,
      item: oneItem,
      instance: params.instance,
      scenarioId: params.scenarioId,
      hideZeros: params.hideZeros
    })

    // Check for X
    const yAxisValues = findValues({
      itemDimensionData: yFilterItemDimensionData,
      item: oneItem,
      instance: params.instance,
      scenarioId: params.scenarioId,
      hideZeros: params.hideZeros
    })

    const groupingValue = modelInstance.helpers.dimensions.getDimensionValue({
      item: oneItem,
      dimensionName: groupFilter
    })
    debugLog.log(xAxisValues, yAxisValues, groupingValue)

    chartGroupedValues[groupingValue] = chartGroupedValues[groupingValue] || {}
    if (xAxisValues) {
      chartGroupedValues[groupingValue].xAxisValues = xAxisValues
    }
    if (yAxisValues) {
      chartGroupedValues[groupingValue].yAxisValues = yAxisValues
    }
  })
  debugLog.log('chartGroupedValues', chartGroupedValues)

  return chartGroupedValues
}

function findValues (params) {
  const matchesDimension = findItemDimension({
    itemDimensionData: params.itemDimensionData,
    item: params.item
  })

  if (!matchesDimension) return

  const itemValues = extractValues({
    instance: params.instance,
    item: params.item,
    scenarioId: params.scenarioId,
    hideZeros: params.hideZeros
  })
  return itemValues
}

/*
  Returns the dimension if matching the filter
    @params.itemDimensionData {
      name: xx,
      value: xx
    }
*/
function findItemDimension (params) {
  const itemDimensionData = params.itemDimensionData
  const oneItem = params.item
  const targetDimension = oneItem.dimensions.find(function (oneDimension) {
    return oneDimension.name === itemDimensionData.name
  })
  if (!targetDimension) return
  if (!(targetDimension.value === itemDimensionData.value)) return

  return targetDimension
}

function extractValues (params) {
  const modelInstance = params.instance
  const oneItem = params.item
  const scenarioId = params.scenarioId
  const itemScenarioData = oneItem.byScenario[scenarioId]

  const viewOffsetIndex = modelInstance.data.timeseries.periodSeriesViewOffset
  let itemValues = itemScenarioData.c_calculatedValues.slice(viewOffsetIndex)

  itemValues = itemValues.map(function (oneValue) {
    // if (params.hideZeros) {
    return Number.isFinite(oneValue) ? oneValue : null
  })

  return itemValues
}
