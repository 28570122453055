import * as axios from 'axios'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('module:footer')
debugLog.disable()

const DOMelements = {
  footerContainer: 'footer',
  appVersion: 'appVersion',
  socketsStatus: 'socketsStatus'
}
const listeners = {
  socketsStatus: false,
  appVersion: false
}

export function launchFooter () {
  debugLog.log('footer')
  createFooterElements()
}

//
function createFooterElements () {
  debugLog.log('createFooterElements')
  const DOMelement = document.getElementById(DOMelements.footerContainer)
  if (!DOMelement) return

  DOMelement.classList.add('cssGrid', 'columns')

  const html = []
  html.push('<div class="gridArea"><div>Debug => </div></div>')
  html.push('<div id="' + DOMelements.appVersion + '" class="gridArea"></div>')
  html.push('<div id="' + DOMelements.socketsStatus + '" class="gridArea"></div>')

  DOMelement.innerHTML = html.join('')

  loadSocketStatus()
  loadAppVersion()
}

function socketStatusRefreshlistener () {
  const DOMelement = document.getElementById(DOMelements.socketsStatus)
  if (!DOMelement) return

  // Only attach the listener once
  if (listeners.socketsStatus) return

  DOMelement.addEventListener('click', function (clickEvent) {
    debugLog.log('clicked')
    const targetDOM = clickEvent.target.closest('.refresh')
    debugLog.log('targetDOM', targetDOM)
    if (!targetDOM) return

    loadSocketStatus()
  })
  listeners.socketsStatus = true
}

function showSocketStatus (socketData) {
  const DOMelement = document.getElementById(DOMelements.socketsStatus)
  if (!DOMelement) return

  const html = []
  html.push('<div><button type="button" class="btn btn-outline-info btn-sm refresh">Refresh Sockets</button></div>')
  html.push('<div>' + JSON.stringify(socketData) + '</div>')
  DOMelement.innerHTML = html.join('')

  socketStatusRefreshlistener()
}

export async function loadSocketStatus () {
  debugLog.log('loadSocketStatus')
  const queryUrl = ['/api', 'status', 'mysocket']
  return axios.get(queryUrl.join('/'))
    .then(function (response) {
      // debugLog.log(response)
      showSocketStatus(response.data)
      return response.data
    })
    .catch(function (error) {
      // console.error('loadSocketStatus', error)
      showSocketStatus(error)
    })
}

function appVersionRefreshlistener () {
  const DOMelement = document.getElementById(DOMelements.appVersion)
  if (!DOMelement) return

  // Only attach the listener once
  if (listeners.appVersion) return

  DOMelement.addEventListener('click', function (clickEvent) {
    const targetDOM = clickEvent.target.closest('.refresh')
    if (!targetDOM) return

    loadAppVersion()
  })
  listeners.appVersion = true
}

function showAppVersion (appVersion) {
  const DOMelement = document.getElementById(DOMelements.appVersion)
  if (!DOMelement) return

  const html = []
  html.push('<div><button type="button" class="btn btn-outline-info btn-sm refresh">Refresh Version</button></div>')
  html.push('<div>' + JSON.stringify(appVersion) + '</div>')
  DOMelement.innerHTML = html.join('')

  appVersionRefreshlistener()
}

async function loadAppVersion () {
  const queryUrl = ['/api', 'status']
  return axios.get(queryUrl.join('/'))
    .then(function (response) {
      // debugLog.log('Socket response', response.data)
      showAppVersion(response.data)
    })
    .catch(function (error) {
      // console.error('showMySocket', error)
      showAppVersion(error)
    })
}
