
// import { Draggable, Droppable, Swappable, Sortable } from '@shopify/draggable'
import { Sortable } from '@shopify/draggable'

// CSS Grid Module
import * as cssGridApp from '../cssgrid-app.v1/css-grid.js'

import * as modelModule from '../frontend/models/control-models.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('webui:itemSorting')
debugLog.disable()

//
export function enableDragAndDropSorting () {
  const modelContainerElement = document.getElementById('modelContainer')

  // Drag and Drop to sort
  const sortable = new Sortable(modelContainerElement.querySelectorAll('.dndDropzone'), {
    draggable: '.gridOneRow[d-isitem="true"]',
    handle: '.draghandle',
    mirror: {
      constrainDimensions: true // Keeps dragged element the same size than its origin
    }
  })

  // sortable.on('sortable:start', (e) => console.log('sortable:start', e))
  // sortable.on('sortable:sort', (e) => console.log('sortable:sort', e))
  // sortable.on('sortable:sorted', (e) => console.log('sortable:sorted', e, e.newIndex))
  // sortable.on('drag:stop', (e) => console.log('drag:stop', e))
  // sortable.on('sortable:stop', (e) => console.log('sortable:stop', e))

  // drag:stopped event added in 1.0.0-beta.12 version: being the npm @next (as of Aug 2021)
  // Sorting fully executed
  sortable.on('drag:stopped', (e) => {
    // console.log('drag:stopped', e)
    readGridSorting(sortable, e)
  })
}

function readGridSorting (sortable, event) {
  const modelContainerElement = document.getElementById('modelContainer')
  if (!modelContainerElement) return

  const rowBaseClass = cssGridApp.settings.rowClasses.baseStyling

  const rows = modelContainerElement.querySelectorAll('.' + rowBaseClass)
  // debugLog.log('rows', rows)

  const sortedItemIds = []
  rows.forEach(function (oneRowElement, index) {
    const isItemRow = oneRowElement.getAttribute('d-isItem')
    const itemId = oneRowElement.getAttribute('d-itemId')
    // const draggedSource = oneRowElement.classList.contains('draggable-source--placed')
    // const draggedMirror = oneRowElement.classList.contains('draggable-mirror')
    // draggable--original
    // debugLog.log(index, 'isItemRow:', isItemRow, draggedSource, draggedMirror)
    // debugLog.log(oneRowElement)
    // debugLog.log(oneRowElement.innerText)

    if (isItemRow && itemId) {
      sortedItemIds.push(itemId)
    }
  })

  // debugLog.log('sortable', sortable)
  // const elements = sortable.getDraggableElementsForContainer(sortable.containers[0])
  // debugLog.log(elements)
  // debugLog.log('newContainer', sortable.newContainer)

  // appData.settings.sortedItemIds = sortedItemIds
  // localStorage.setItem('sortedItemIds', sortedItemIds)

  saveSortedItemIds(sortedItemIds)
}

function saveSortedItemIds (sortedItemIds) {
  const params = {
    modelId: appData.modelInstance.data.model._id,
    sortedItemIds: sortedItemIds
  }
  modelModule.updateModel(params).then(function (received) {
    // debugLog.log('UPDATED', received)
    const sortedItemIds = received?.data?.settings?.sortedItemIds
    if (sortedItemIds) {
      appData.modelInstance.data.model.settings.sortedItemIds = sortedItemIds
    }
  })
}
