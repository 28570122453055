//

// name: 'module-template',
// value: 'model1'

// name: 'module-use',
// value: 'model1'

import * as modelAppHelpers from './helpers.js'
import * as prepFormulas from './prep-formula.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('logic:franchise')
// debugLog.disable()

// Create items defined by module-user (for the template value)
/*
  params
    .item
    .scenarioId
*/
export function useModel (params) {
  debugLog.log('useModel', params)
  const modelUseItem = params.item

  // Identify the model to use
  const modelName = modelUseItem.c_template_use
  debugLog.log('modelName', modelName)

  const instanceData = params.instance.data

  // Find the items of the model
  const modelItems = instanceData.dataset.items?.filter(function (oneItem) {
    return oneItem.c_template === modelName
  })
  debugLog.log('modelItems', modelItems)

  if (!modelItems) {
    debugLog.warn('!! the model used has no items')
  }

  // Shortcut
  const itemScenarioData = modelUseItem.byScenario[params.scenarioId]

  // refID of the model
  const modelReplicationId = [modelName, 0]

  // Go through all periods, and create model items when needed
  itemScenarioData.c_stepFormulas.forEach(function (oneStepFormula, stepFormulaIndex) {
    if (!oneStepFormula) return
    const useNumber = oneStepFormula.c_compiled.evaluate() // Value needs to be hard coded

    if (useNumber > 0) {
      // Create a new items
      useModelForPeriod(
        {
          modelReplicationId: modelReplicationId,
          scenarioId: params.scenarioId,
          stepFormula: oneStepFormula,
          stepFormulaIndex: stepFormulaIndex,
          useNumber: useNumber,
          modelItems: modelItems,
          modelName: modelName,
          instance: params.instance
        }
      )
    }
  })

  // Create the valueFormula, adjusting for the period
}

/*
  @params
    .scenarioId
    .stepFormula: object of the formula triggering the creation of items
    .stepFormulaIndex: index of the timeseries at which this formula is triggered
    .modelItems: array of items of the template model
    .useNumber: number
    .modelName: string /// Which model we are using
    .instance: the modelInstance
*/
function useModelForPeriod (params) {
  debugLog.log('useModelForPeriod', params)
  let modelItemsToCreate = params.useNumber

  const instance = params.instance
  const instanceData = instance.data
  const periodSeries = instanceData.timeseries.periodSeries

  while (modelItemsToCreate > 0) {
    // template itemId will be changed for the copies
    const mappingItemsIdInitial = []
    const mappingItemsIdMapped = []

    const modelItemsCreated = []

    params.modelReplicationId[1]++
    const modelSeriesId = ' - ' + params.modelReplicationId.join('#') // frontHelpers.generateRandomString(8)
    debugLog.log('create one outlet', modelSeriesId)

    // Create each items
    params.modelItems.forEach(function (oneItemToCreate) {
      const copyId = instance.helpers.getItemId(oneItemToCreate) + modelSeriesId

      mappingItemsIdInitial.push(instance.helpers.getItemId(oneItemToCreate))
      mappingItemsIdMapped.push(copyId)

      const itemCreated = {}
      itemCreated.isCreatedFromFranchise = true
      itemCreated._id = copyId
      itemCreated.name = oneItemToCreate.name + modelSeriesId
      itemCreated.dimensions = []
      oneItemToCreate.dimensions.forEach(function (oneDimension) {
        if (!['module-template'].includes(oneDimension.name)) { // We don't push the dimension of item being a template, as it's not :p
          itemCreated.dimensions.push(oneDimension)
        }
      })
      // Identify the item as being from a template
      itemCreated.dimensions.push({
        name: 'module-usage',
        value: params.modelName
      })

      instance.items.add(itemCreated)
      modelItemsCreated.push(itemCreated)
    })

    // Create the formulas
    params.modelItems.forEach(function (oneItem) {
      // debugLog.log('oneItem', oneItem)
      // Create the formulas
      const itemScenarioData = oneItem.byScenario?.[params.scenarioId]

      if (!itemScenarioData) return
      itemScenarioData.c_valueFormulas.forEach(function (oneValueFormulaToCreate) {
        debugLog.log('modelAppHelpers.generateRandomString()', modelAppHelpers.generateRandomString())
        const valueFormulaCreated = {
          tempId: modelAppHelpers.generateRandomString(), // Virtual ID
          isCreatedFromFranchise: true,
          itemId: false,
          scenarioId: params.scenarioId,
          periods: {
            first_period: false
          }
        }

        let isFormulaWithinModelTimeframe = true

        // Copy each definition property
        Object.keys(oneValueFormulaToCreate.definition).forEach(function (oneDefinitionProperty) {
          // Analyse special properties first
          if (oneDefinitionProperty === 'itemId') {
            let copiedItemId = oneValueFormulaToCreate.definition.itemId // Need to make a unique one... and to change the references in other formulas
            copiedItemId = mappingItemsIdMapped[mappingItemsIdInitial.indexOf(copiedItemId)]
            valueFormulaCreated.itemId = copiedItemId
          } else if (oneDefinitionProperty === 'first_period') {
            // TODO: better relative periods? is that needed?
            const copiedFirstPeriod = oneValueFormulaToCreate.definition.first_period // Need to adjust relative
            const timeseriesIndex = periodSeries.indexOf(copiedFirstPeriod)
            const targetArrayIndex = timeseriesIndex + params.stepFormulaIndex

            if (targetArrayIndex >= periodSeries.length) {
              // period is beyond the model period: No inclusion to be made
              isFormulaWithinModelTimeframe = false
            }

            const targetPeriod = periodSeries[timeseriesIndex + params.stepFormulaIndex]
            valueFormulaCreated.periods.first_period = targetPeriod
          // } else {
          //   valueFormulaCreated.definition[oneDefinitionProperty] = oneValueFormulaToCreate.definition[oneDefinitionProperty]
          }
        })

        if (!isFormulaWithinModelTimeframe) return

        valueFormulaCreated.formula = oneValueFormulaToCreate.formula
        // Replace the references in the formula
        mappingItemsIdInitial.forEach(function (oneInitialItemId, index) {
          const toReplace = '"' + oneInitialItemId + '"'
          const toReplaceRegex = new RegExp(toReplace, 'g')
          const replaceBy = '"' + mappingItemsIdMapped[index] + '"'

          valueFormulaCreated.formula = valueFormulaCreated.formula.replace(toReplaceRegex, replaceBy)

          // string.replaceAll() is in ES2021; not in Jest as of Jul 2021
          // valueFormulaCreated.formula = valueFormulaCreated.formula.replaceAll(toReplace, replaceBy)
        })

        instance.formulas.add(valueFormulaCreated)
      })
    })

    // Associate and compile the formulas for the new items
    modelItemsCreated.forEach(function (oneItem) {
      prepFormulas.associateFormulasToItsItem({
        item: oneItem,
        scenarioId: params.scenarioId,
        instance: params.instance
      })
    })

    modelItemsToCreate--
  }
}
