
import * as logicFormulaEvalMultipleItems from './logic-formula-eval-multiple-items.js'
import * as logicFormulaEvalMultiplePeriods from './logic-formula-eval-multiple-periods.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:logicFormulaEval')
debugLog.disable()

/*
  @params
    .oneRef
    .scenarioId
    .stepIndex
    .instance

*/
export function evalOneFormulaReferenceValue (params = {}) {
  // debugLog.log('--')
  debugLog.log('evalOneFormulaReferenceValue', params)
  const oneRef = params.oneRef

  const refValue = {
    evalRefId: '',
    value: ''
  }

  if (oneRef.refId && oneRef.scenarioId) {
    debugLog.log('evalRefValue: scenarioRef')

    // v1: direct read in the scenario: not allowing to target other periods than stepIndex
    // const instanceDataItems = params.instance.data?.dataset?.items
    // if (!instanceDataItems) return false

    // // Scenario comparison
    // const item = instanceDataItems.find(function (oneItem) {
    //   return params.instance.helpers.getItemId(oneItem) === oneRef.itemId
    // })

    // let v = item.byScenario[oneRef.scenarioId].c_calculatedValues[params.stepIndex]
    // v = Number.isFinite(v) ? v : 0

    // v2: leverage calculations for 1 item
    const evalParams = {
      scenarioId: oneRef.scenarioId,
      instance: params.instance,
      stepIndex: params.stepIndex,
      ref: {
        itemId: oneRef.itemId,
        period: oneRef.period,
        function: oneRef.function
      },
      parentParams: params.parentParams
    }
    const v = logicFormulaEvalMultiplePeriods.evaluateItemStepPeriodValues(evalParams)
    refValue.evalRefId = oneRef.refId
    refValue.value = v
  //
  } else if (oneRef.refId && oneRef.items) {
    debugLog.log('evalRefValue: multiple items')
    // oneRef.refId and oneRef.items -> formula across rows
    // eg. oneRef.items = "type:revenue"
    //  oneRef.period = X (only 1 period for now)
    const evalParams = {
      scenarioId: params.scenarioId,
      instance: params.instance,
      stepIndex: params.stepIndex,
      ref: oneRef,
      parentParams: params.parentParams
    }
    const v = logicFormulaEvalMultipleItems.evaluateItemStepItemsValues(evalParams)
    refValue.evalRefId = oneRef.refId
    refValue.value = v
  //
  } else {
    // oneRef.itemId -> formula on 1 item
    debugLog.log('evalRefValue: oneItem')

    const evalParams = {
      scenarioId: params.scenarioId,
      instance: params.instance,
      stepIndex: params.stepIndex,
      ref: {
        itemId: oneRef.itemId,
        period: oneRef.period,
        function: oneRef.function
      },
      parentParams: params.parentParams
    }
    const v = logicFormulaEvalMultiplePeriods.evaluateItemStepPeriodValues(evalParams)
    refValue.evalRefId = oneRef.refId
    refValue.value = v
  }
  debugLog.log('refValue', refValue)
  return refValue
}
