
// HOW TO LOAD LINKED DATA SHOULD THE MODULE BE ON THE SERVER?
// CURRENT SOLUTION IS NOT SCALABLE

import * as axios from 'axios'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('linkedData:financial')
debugLog.disable()

export async function loadLinkedData (params) {
  debugLog.log('loadLinkedData')

  const modelInstance = params.instance

  const promises = []
  const neededDataEndpoint = []

  modelInstance.data.dataset.items.forEach(function (oneItem) {
    if (!oneItem.link) return

    const targetEndpoint = generateEndpoint({
      item: oneItem
    })
    debugLog.log('targetEndpoint', targetEndpoint)
    if (!targetEndpoint) return

    // Only needed to call the API once per endpoint (eg. fundamentals data needed for multiple item)
    if (neededDataEndpoint.includes(targetEndpoint)) return
    neededDataEndpoint.push(targetEndpoint)

    promises.push(loadItemLinkedData({
      item: oneItem,
      modelInstance: modelInstance
    }))
  })

  debugLog.log('promises', promises)

  await Promise.all(promises)
}

function generateEndpoint (params) {
  const oneItem = params.item
  const queryUrl = oneItem.link?.linkEndpoint

  return queryUrl
}

function applyDataToOneItem (params) {
  const oneItem = params.item
  oneItem.link.rawData = params.rawData

  if (!oneItem.link.targetProperty) return

  if (oneItem.link.targetProperty.toLowerCase() === 'price') {
    parseStockPrices({
      item: oneItem,
      targetProperty: 'close'
    })

  //
  } else if (oneItem.link.targetProperty.toLowerCase() === 'adjprice') {
    parseStockPrices({
      item: oneItem,
      targetProperty: 'adjClose'
    })

  //
  } else if (['pnl', 'bs'].includes(oneItem.link.targetProperty)) {
    parseFundamentals({
      item: oneItem,
      fundamentalsType: oneItem.link.targetProperty,
      modelInstance: params.modelInstance
    })
  }
}

// Several items might be targetting the same dataset endpoint
function applyDataToItems (params) {
  debugLog.log('applyDataToItems', params)

  const modelInstance = params.modelInstance
  modelInstance.data.dataset.items.forEach(function (oneItem) {
    if (!oneItem.link) return

    const targetEndpoint = generateEndpoint({
      item: oneItem
    })
    if (!(targetEndpoint === params.queryUrl)) return

    // The response is for this item
    applyDataToOneItem({
      rawData: params.rawData,
      item: oneItem,
      modelInstance: params.modelInstance
    })
  })
}

async function loadItemLinkedData (params) {
  debugLog.log('loadItemLinkedData')
  const queryUrl = generateEndpoint({
    item: params.item
  })

  const getOptions = {}

  // Not needed; we return the full data anyway
  // if (oneItem.link?.metricName) {
  //   getOptions.params = {
  //     metric: oneItem.link?.metricName
  //   }
  // }

  return axios.get(queryUrl, getOptions)
    .then(function (response) {
      debugLog.log('response', response)
      if (!response.data) return
      if (!response.data.data) return

      applyDataToItems({
        queryUrl: queryUrl,
        rawData: response.data.data,
        modelInstance: params.modelInstance
      })
    })
    .catch(function (error) {
      debugLog.error('error:', error)
    })
}

function parseStockPrices (params) {
  const oneItem = params.item
  const rawData = oneItem.link.rawData

  const targetProperty = params.targetProperty

  const priceArrayIndex = rawData.prices.keys.indexOf(targetProperty)

  const stockPrices = {
    dates: [],
    values: []
  }
  rawData.prices.data.forEach(function (oneDataDate) {
    stockPrices.dates.push(oneDataDate.date)
    stockPrices.values.push(+oneDataDate.data[priceArrayIndex])
  })

  oneItem.link.data = stockPrices
}

function parseFundamentals (params) {
  const oneItem = params.item
  const fundamentalsType = params.fundamentalsType

  const rawData = oneItem.link.rawData
  if (!rawData) return

  // Map the DB property names
  const propertyName = {
    pnl: 'pnl',
    bs: 'balancesheet'
  }
  const targetFundamentalsProperty = propertyName[fundamentalsType]

  const metricName = oneItem.link.metricName
  const reportName = 'quarterlyReports'
  const reports = rawData[targetFundamentalsProperty][reportName]
  if (!reports) return

  const reportsSorted = sortFundamentals({
    reports: reports
  })
  if (!reportsSorted) return

  // Fundamentals data is for quarters, so we analyse the start and end of the quarter
  const fundamentalMetrics = {
    dates: [],
    values: [],
    datesFrom: []
  }

  reportsSorted.forEach(function (oneReport) {
    fundamentalMetrics.dates.push(oneReport.fiscalDateEnding)
    fundamentalMetrics.values.push(+oneReport[metricName])

    const quarterStart = params.modelInstance.helpers.moveDate(oneReport.fiscalDateEnding, 'month', -3)
    fundamentalMetrics.datesFrom.push(quarterStart) // Financial Quarterly results
  })

  oneItem.link.data = fundamentalMetrics
}

function sortFundamentals (params) {
  debugLog.log('sortFundamentals')
  const reports = params.reports

  const sortOrder = 1 // 1 for ascending
  const sortedArray = Array.from(reports).sort(function (p1, p2) {
    return sortOrder * (new Date(p1.fiscalDateEnding) - new Date(p2.fiscalDateEnding))
  })
  debugLog.log(sortedArray)
  return sortedArray
}
