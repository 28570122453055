
const logTracker = '$$$ JS Async'

async function asyncf1 () {
  return 1000
}

async function syncF () {
  function resolve () {
    console.log(logTracker, 'syncF - timeout done')
    return 888
  }
  return setTimeout(resolve, 3000)
}

async function syncf300 () {
  return new Promise(function (resolve, reject) {
    resolve(300)
  }).then(function (r) {
    console.log(logTracker, 'syncf300 inside, then1', r)
    return 301
  })
}

function returnedPromise () {
  return new Promise(function (resolve, reject) {
    console.log(logTracker, 'returnedPromise inside')
    resolve(10)
  })
}

function returnedPromiseWithThens () {
  return new Promise(function (resolve, reject) {
    resolve(100)

    // Useless return; the value returned in the resolved value
    return 101
  }).then(function (r) {
    console.log(logTracker, 'returnedPromiseWithThens then()', r)
    return 102
  }).then(function (r2) {
    console.log(logTracker, 'returnedPromiseWithThens then()', r2)
    return 103
  })
}

function resolvedTimeout () {
  return new Promise(function (resolve, reject) {
    function resolveTimeout () {
      resolve(logTracker, 'resolvedTimeout within done 1234')
    }
    setTimeout(resolveTimeout, 2000)
  })
}

export async function runTest () {
  console.log(logTracker, '----- ASYNC FUNCTIONS TEST -----')
  resolvedTimeout().then(function (returned) {
    console.log(logTracker, 'resolvedTimeout returned', returned)
  })

  returnedPromise().then(function (returned) {
    console.log(logTracker, 'returnedPromise returned', returned)
  })

  returnedPromiseWithThens()
    .then(function (returned) {
      console.log(logTracker, 'returnedPromiseWithThens returned', returned)

      // Return value for final .then()
      return 110
    })
    .catch(function (error) {
      console.error(logTracker, 'returnedPromiseWithThens error', error)
    })
    .then(function (r) {
      console.log(logTracker, 'returnedPromiseWithThens, in any case', r)
    })

  await syncF().then(function (returned) {
    console.log(logTracker, 'syncF returned', returned)
  })

  const syncf300A = await syncf300().then(function (returned) {
    console.log(logTracker, 'syncf300 returned', returned)
    return 304
  })
  console.log(logTracker, 'syncf300A', syncf300A)

  const casyncf1 = await asyncf1()
    .then(function (r) {
      console.log(logTracker, 'asyncf1 returned then', r)
      return 1001
    })
    .then(function (r) {
      console.log(logTracker, 'asyncf1 returned then', r)
      return 1002
    })
  console.log(logTracker, 'asyncf1 casyncf1', casyncf1)
}
