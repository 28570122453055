import * as axios from 'axios'

import * as envVar from '../env.js'
import * as userModule from '../frontend/user.js'

import * as componentShowModels from '../frontend/models/show-models.js'

import * as modelCreation from '../model-creation/index.js'

const moduleMe = {}

moduleMe.load = function (params = {}) {
  moduleMe.createModelModule()

  const userData = userModule.userData()

  if (params.showCreate && userData) {
    modelCreation.showModelCreateForm()
  }

  moduleMe.listModels(params)
}

//
moduleMe.listModels = function (params = {}) {
  const queryUrl = envVar.apiUrl + '/models'

  const queryParams = {}
  if (params && params.modelFilters) {
    if (typeof params.modelFilters.isMine === 'boolean') queryParams.isMine = params.modelFilters.isMine
    if (typeof params.modelFilters.isPublic === 'boolean') queryParams.isPublic = params.modelFilters.isPublic
  }

  axios.get(queryUrl, { params: queryParams })
    .then(function (response) {
      console.log('response', response)
      componentShowModels.showModels({ params: params, models: response.data.data })
    })
    .catch(function (error) {
      console.error('error', error)
    })
    .then(function () {
      console.log('in any case')
    })
}

//
moduleMe.createModelModule = function () {
  const DOMelement = document.getElementById('content')
  if (!DOMelement) return

  const html = []

  html.push('<div id="modelCreation"></div>')
  html.push('<div id="modelsList"></div>')

  DOMelement.innerHTML = html.join('')
}

export { moduleMe }
