
import * as d3 from 'd3'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('export:csv')
// debugLog.disable()

/*
  @params
    .modelInstance
*/
export function exportCsv (params) {
  debugLog.log('exportCsv', params)

  const modelInstance = params.modelInstance

  const currentTableData = modelInstance.context.tableJS
  if (!currentTableData) {
    debugLog.warn('no table data')
    return
  }

  const csvContent = generateCsv({
    currentTableData
  })
  const filename = [new Date().toJSON(), '.csv'].join('')
  triggerDownload({
    filename,
    csvContent
  })
}

function generateCsv (params) {
  const csvData = generateCsvData(params)

  // https://github.com/d3/d3-dsv/blob/v3.0.1/README.md#dsv_format
  const csv = d3.csvFormat(csvData)
  debugLog.log('csv', csv)

  return csv
}

/*
  D3 wants objects to map with a header
*/
function generateCsvData (params) {
  debugLog.log('generateCsvData', params)
  const currentTableData = params.currentTableData

  const csvCellsContent = []

  const headerNames = []

  currentTableData.forEach(function (oneRow, rowIndex) {
    const csvRow = []
    const csvRowObject = {}

    const rowCells = oneRow.cells || oneRow
    rowCells.forEach(function (oneCell, columnIndex) {
      if (rowIndex === 0) {
        headerNames.push(oneCell.value || columnIndex + 1)
      } else {
        csvRowObject[headerNames[columnIndex]] = oneCell.value || ''
      }
      csvRow.push(oneCell.value || '')
    })
    if (rowIndex === 0) return
    csvCellsContent.push(csvRowObject)
  })
  debugLog.log('csvCellsContent', csvCellsContent)

  return csvCellsContent
}

/*
  @params
    .filename
    .csvContent

  Inspired by in https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
*/
function triggerDownload (params) {
  debugLog.log('triggerDownload', params)

  const csvFileContent = 'data:text/csv;charset=utf-8,' + params.csvContent
  const encodedUri = encodeURI(csvFileContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', params.filename)
  document.body.appendChild(link) // Required for FF
  link.click() // Execute the link click to propose download
}
