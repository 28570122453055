
export function load () {
  const DOMelement = document.getElementById('content')
  if (!DOMelement) return

  generateRow({
    character: 0,
    containerElement: DOMelement,
    widthFit: true,
    id: 'measure'
  })

  generateRow({
    character: 0,
    containerElement: DOMelement
  })

  generateRow({
    character: 1,
    containerElement: DOMelement
  })

  generateRow({
    character: 'i',
    containerElement: DOMelement
  })

  generateRow({
    character: 'm',
    containerElement: DOMelement
  })

  generateDivRow({
    containerElement: DOMelement
  })

  evaluateFontSize()
}

function evaluateFontSize () {
  const measureElement = document.getElementById('measure')
  const size100 = measureElement.offsetWidth
  console.log('size100', size100)

  const measureElementTarget = document.querySelector('.fullRow:not(.widthFitContent)')
  console.log('measureElementTarget', measureElementTarget)
  const sizeTarget = measureElementTarget.offsetWidth

  const emRatio = Math.floor(100 * sizeTarget / size100)

  const contentElement = document.getElementById('content')
  contentElement.style.fontSize = (emRatio / 100) + 'em'
}

function generateRow (params) {
  const character = params.character
  const containerElement = params.containerElement

  const contentElement = document.createElement('div')
  contentElement.classList.add('fullRow')

  if (params.id) {
    contentElement.setAttribute('id', params.id)
  }

  if (params.widthFit) {
    contentElement.classList.add('widthFitContent')
  }

  const arrayOfValues = Array(100).fill(character)
  contentElement.innerText = arrayOfValues.join('')

  containerElement.append(contentElement)
}

function generateDivRow (params) {
  const containerElement = params.containerElement

  const contentElement = document.createElement('div')
  contentElement.classList.add('fullRow')

  const arrayOfValues = Array(25).fill('<div></div>')
  contentElement.innerHTML = arrayOfValues.join('')

  containerElement.append(contentElement)
}

/*
  - make a string of 100 characters
  - width:fit-content
  - measure the Element.offsetWidth -> 797
  - measure the targetElement.offsetWidth -> 1126
  - Read the current fontSize:
    ... window.getComputedStyle(temp1).fontSize
  - Calculate the needed font size

*/
