
import * as cssGridRow from './css-grid-row.js'
// import * as cssGrid from './css-grid.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:styling')
debugLog.disable()

/*
  @moduleParams
    .targetSelectorId
*/
export function updateGridCss (moduleParams) {
  debugLog.log('updateGridCss')
  debugLog.log(moduleParams)

  // Characters per row
  const totalCharacters = moduleParams.rowStats.charactersNeededPerCol.reduce(function (acc, value) { return acc + value })
  moduleParams.rowStats.totalCharacters = totalCharacters

  insertGridTemplateStyle({
    moduleParams: moduleParams,
    totalCharacters: totalCharacters
  })

  adjustFontSize({
    moduleParams: moduleParams,
    totalCharacters: totalCharacters
  })
}

//
//
function insertGridTemplateStyle (params = {}) {
  // Generate the cssGrid grid-template-columns style
  const gridTemplateColumns = generateGridTemplateColumnStyle({
    moduleParams: params.moduleParams,
    totalCharacters: params.totalCharacters
  })
  debugLog.log('gridTemplateColumns', gridTemplateColumns)
  params.moduleParams.rowStats.gridTemplateColumns = gridTemplateColumns

  const styleElement = document.createElement('style')
  document.head.appendChild(styleElement)

  const selectorClasses = []
  selectorClasses.push('#' + params.moduleParams.targetSelectorId) // Class to identify the grid
  selectorClasses.push('.' + cssGridRow.settings.rowClasses.gridTemplateColumns) // '.gridDefColumns'

  const styleObject = {
    selector: selectorClasses.join(' '),
    properties: [
      {
        name: 'grid-template-columns',
        value: gridTemplateColumns // eg '25% 18.75% repeat(3, minmax(0, 1fr)) 5.00%;'
      }
    ]
  }
  const styleString = generateCssRule(styleObject)
  debugLog.log('styleString', styleString)
  styleElement.sheet.insertRule(styleString)
}

//
//
function generateGridTemplateColumnStyle (params = {}) {
  const moduleParams = params.moduleParams
  const totalCharacters = params.totalCharacters

  let numberCenterColumns = 0
  const leftColumnsPct = []
  const rightColumnsPct = []

  moduleParams.rowStats.columnType.forEach(function (oneColumnType, arrayIndex) {
    let colPct, colDefPct
    switch (oneColumnType) {
      case 'left':
        colPct = 100 * (moduleParams.rowStats.charactersNeededPerCol[arrayIndex] / totalCharacters)
        colDefPct = colPct.toFixed(2) + '%'
        leftColumnsPct.push(colDefPct)
        break
      case 'right':
        colPct = 100 * (moduleParams.rowStats.charactersNeededPerCol[arrayIndex] / totalCharacters)
        colDefPct = colPct.toFixed(2) + '%'
        rightColumnsPct.push(colDefPct)
        break
      case 'center':
        numberCenterColumns++
        break
    }
  })
  return [
    leftColumnsPct.join(' '),
    'repeat(' + numberCenterColumns + ', minmax(0,1fr))',
    rightColumnsPct.join(' ')
  ].join(' ')
}

export function generateStyle (params) {
  debugLog.log('generateStyle', params)
  const targetColumnClass = params.targetColumnClass

  const propertyValue = []
  if (params.columnWidths) {
    params.columnWidths.forEach(function (oneColumnWidth) {
      if (typeof oneColumnWidth === 'string') {
        propertyValue.push(oneColumnWidth)
      } else {
        if (oneColumnWidth.columns === 0) return
        const width = oneColumnWidth.width === 'auto' ? 'minmax(0,1fr)' : oneColumnWidth.width
        propertyValue.push('repeat(' + oneColumnWidth.columns + ', ' + width + ')')
      }
    })
  }

  const selectorClasses = []
  selectorClasses.push(targetColumnClass)
  selectorClasses.push('.gridOneRow.gridDefColumns')

  const styleObject = {
    selector: selectorClasses.join(' '),
    properties: [
      {
        name: 'grid-template-columns',
        value: propertyValue.join(' ') // eg 'repeat(12, minmax(0, 1fr));'
      }
    ]
  }

  const styleString = generateCssRule(styleObject)
  debugLog.log('styleString', styleString)

  return styleString
}

export function generateCssRule (styleObject) {
  const properties = styleObject.properties.map(function (oneProperty) {
    return [oneProperty.name, oneProperty.value].join(':')
  })

  const styleString = styleObject.selector + '{' + properties.join(';') + '}'
  return styleString
}

//
//
function adjustFontSize (params = {}) {
  // vw is the 1% of the page width; but fonts are not sized this way: a 1% width font is not making a letter be 1%
  // const fontSize = (100 / params.totalCharacters)
  // const fontSizeValue = [fontSize.toFixed(2), 'vw'].join('')

  // 'ch' is the width of the character 0
  // const fontSize = (100 / params.totalCharacters) * 2
  // const fontSizeValue = [fontSize.toFixed(2), 'ch'].join('')

  const fontSizeValue = evaluateNeededFontSize(params.moduleParams)

  const fontSizeObject = {
    // selector: '.' + cssGrid.settings.gridClass,
    properties: [
      {
        name: 'font-size',
        value: fontSizeValue
      }
    ]
  }

  editCssRule(params.moduleParams, fontSizeObject)
}

function evaluateNeededFontSize (moduleParams) {
  const character = 0

  const targetElement = document.getElementById(moduleParams.targetSelectorId)

  // Create a test element with 100 characters
  const contentElement = document.createElement('div')
  // contentElement.classList.add('fullRow')
  contentElement.classList.add('widthFitContent')
  const arrayOfValues = Array(100).fill(character)
  contentElement.innerText = arrayOfValues.join('')
  contentElement.style.width = 'fit-content'
  contentElement.style.border = '1px solid red'
  targetElement.append(contentElement)
  const size100 = contentElement.clientWidth // offsetWidth
  const sizeTarget = targetElement.clientWidth // offsetWidth
  // console.log(targetElement.clientWidth, targetElement.offsetWidth)
  // console.log('size100', size100, 'sizeTarget', sizeTarget)

  // Remove:
  contentElement.remove()

  const paddingSize = (3 * 2 * moduleParams.rowStats.columnType.length) + // Each cell padding
    (moduleParams.rowStats.columnType.length * 6) + // // Considering breaks between cells: the column-gap value;
    8 + // 8px of Drag Handle
    6 // (6) of grid padding

  const availableGridWidth = sizeTarget - paddingSize
  let fontSizeRatio = (availableGridWidth / moduleParams.rowStats.totalCharacters) / (size100 / 100)
  fontSizeRatio = fontSizeRatio * 0.9 // 10% margin safety

  // console.log('moduleParams', moduleParams)

  const emRatio = Math.floor(100 * fontSizeRatio) / 100
  return emRatio + 'em'
}

function editCssRule (moduleParams, styleObject) {
  styleObject.properties.forEach(function (oneProperty) {
    moduleParams.tableElement.style[oneProperty.name] = oneProperty.value
  })
}
