
import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:contentAnalysis')
debugLog.disable()

//
// Calculate the Characters
export function evaluateCharacters (moduleParams) {
  // debugLog.log('evaluateCharacters', moduleParams)

  // Analyse each row
  moduleParams.rows.forEach(function (oneRow) {
    oneRow.rowStats.charactersTotal = 0
    oneRow.rowStats.charactersPerType = {}
    oneRow.rowStats.cellCharacters.forEach(function (oneCellCharacterCount, arrayIndex) {
      evaluateCellCharacters({
        oneRow: oneRow,
        oneCellCharacterCount: oneCellCharacterCount,
        arrayIndex: arrayIndex,
        moduleParams: moduleParams
      })
    })
  })

  // Aggregate analysis:
  // Find the max for any content column
  let contentColumnMaxCharacters = 0
  moduleParams.rowStats.columnType.forEach(function (oneColumnType, arrayIndex) {
    if (oneColumnType === 'center') {
      contentColumnMaxCharacters = Math.max(contentColumnMaxCharacters, moduleParams.rowStats.charactersMaxPerCol[arrayIndex])
    }
  })
  // debugLog.log('max center char', contentColumnMaxCharacters)

  // Give the Characters needed per column
  moduleParams.rowStats.columnType.forEach(function (oneColumnType, arrayIndex) {
    moduleParams.rowStats.charactersNeededPerCol[arrayIndex] = (oneColumnType === 'center') ? contentColumnMaxCharacters : moduleParams.rowStats.charactersMaxPerCol[arrayIndex]
  })
}

function evaluateCellCharacters (params = {}) {
  const oneRow = params.oneRow
  const oneCellCharacterCount = params.oneCellCharacterCount
  const arrayIndex = params.arrayIndex
  const moduleParams = params.moduleParams

  oneRow.rowStats.charactersTotal += oneCellCharacterCount

  const colType = oneRow.rowStats.columnType[arrayIndex]

  oneRow.rowStats.charactersPerType[colType] = oneRow.rowStats.charactersPerType[colType] || 0
  oneRow.rowStats.charactersPerType[colType] += oneCellCharacterCount

  // Aggregate the column types
  if (!moduleParams.rowStats.columnType[arrayIndex]) {
    moduleParams.rowStats.columnType[arrayIndex] = colType
  } else if (colType !== moduleParams.rowStats.columnType[arrayIndex]) {
    debugLog.warn('!! different column type passed:', arrayIndex, colType, 'vs.', moduleParams.rowStats.columnType[arrayIndex])
  }

  // Max per column
  moduleParams.rowStats.charactersMaxPerCol[arrayIndex] = moduleParams.rowStats.charactersMaxPerCol[arrayIndex] || 0
  moduleParams.rowStats.charactersMaxPerCol[arrayIndex] = Math.max(moduleParams.rowStats.charactersMaxPerCol[arrayIndex], oneCellCharacterCount)
}
