
// import * as editorComponent from './index.js'
import * as editorSubTyping from './sub-typing.js'
import * as editorSubPickerItem from './sub-picker-item.js'
import * as editorSubPickerPeriod from './sub-picker-period.js'

//
//
export function createSubSelector (params = {}) {
  console.log('createSubSelector: ', params)

  const precedingSub = params.precedingSub
  const followingContent = params.followingContent || ''

  const editorSub = generateSelectorSub({
    editorData: params.editorData,
    subPickerSection: params.subPickerSection
  })
  precedingSub.insertAdjacentElement('afterend', editorSub)
  console.log('editorSub', editorSub)
  addListener({
    editorData: params.editorData,
    editorSubElement: editorSub
  })

  if (!params.insertFollowingSub) return editorSub

  // Add another typing sub
  const nextTypingSub = editorSubTyping.generateTypingSub({
    editorData: params.editorData,
    content: followingContent
  })
  editorSub.insertAdjacentElement('afterend', nextTypingSub)
  nextTypingSub.focus()

  return editorSub
}

//
//
export function referenceSubToFormula (params = {}) {
  console.log('referenceSubToFormula', params)
  const editorData = params.editorData
  const subId = params.subId
  const subData = editorData.subs[subId]
  console.log('subData', subData)

  const formula = {}

  // Formula is either towards an item or a group of items
  if (subData.itemId) {
    formula.itemId = (subData.itemId).toString()
  } else if (subData.items) {
    formula.items = subData.items
  } else {
    formula.itemId = 'na'
  }
  // formula.itemId = subData.itemId ? (subData.itemId).toString() : 'na'

  formula.period = subData.valuePeriods ? (subData.valuePeriods).toString() : '0'

  const subString = JSON.stringify(formula)

  return subString
}

//
//
function generateSelectorSub (params = {}) {
  console.log('generateSelectorSub', params)

  const editorData = params.editorData

  // generate the Element
  const editorSub = document.createElement('span')
  editorSub.classList.add('editorSub', 'referenceSelector')
  editorSub.setAttribute('contentEditable', false)

  // generate a uniqueId for the Element
  editorData.subId = Number.isFinite(editorData.subId) ? editorData.subId + 1 : 0
  const subId = editorData.subId
  console.log('editorData.subId', editorData.subId, subId)
  editorSub.setAttribute('d-subId', subId)

  const subData = {
    subId: subId,
    DOMelement: editorSub,
    valuePeriods: params.subPickerSection?.period ? params.subPickerSection?.period : 0,
    itemId: params.subPickerSection?.itemId ? params.subPickerSection?.itemId : null,
    items: params.subPickerSection?.items ? params.subPickerSection?.items : null
  }
  editorData.subs.push(subData)

  const pickerParams = {
    editorData: editorData,
    subId: subId,
    subData: subData,
    editorSubElement: editorSub
  }

  editorSubPickerItem.includePickerItemElement(pickerParams)
  editorSubPickerPeriod.includePickerPeriodElement(pickerParams)

  // The removal element
  const pickerRemovalElement = document.createElement('span')
  pickerRemovalElement.classList.add('editor', 'pickerSection', 'pickerClose', 'close')
  pickerRemovalElement.innerText = 'x'
  editorSub.append(pickerRemovalElement)
  pickerRemovalElement.addEventListener('focus', function () {
    removePreviousPickersPopup()
  })

  console.log('editorSub', editorSub)
  return editorSub
}

//
//
function addListener (params = {}) {
  const editorSubElement = params.editorSubElement
  if (!editorSubElement) return

  editorSubElement.addEventListener('click', function (clickEvent) {
    console.log('click')
    manageRemoveSub(clickEvent, editorSubElement)
    manageClosePickerPopup(clickEvent, editorSubElement)
  })

  editorSubElement.addEventListener('focus', function (focusEvent) {
    console.log('focus', focusEvent)
    // const Element = focusEvent.target.closest('.pickerSection')
    removePreviousPickersPopup()
  })

  editorSubElement.querySelectorAll('.pickerSection').forEach(function (oneElement) {
    oneElement.addEventListener('focus', function (focusEvent) {
      console.log('.pickerSection focus')
      // removePreviousPickersPopup()
    })
  })
}

//
//
export function manageClosePickerPopup (clickEvent, selectorSub) {
  console.log('manageClosePickerPopup?')
  const closingElement = clickEvent.target.closest('.close')
  if (!closingElement) return

  const pickerElement = clickEvent.target.closest('.pickerPopup')
  // console.log('pickerElement?', pickerElement)
  if (!pickerElement) return

  pickerElement.remove()
}

//
//
export function manageRemoveSub (clickEvent, selectorSub) {
  const pickerClose = clickEvent.target.closest('.pickerClose')
  if (!pickerClose) return

  selectorSub.remove()
}

//
// Overall removal of pickers; to be used before creating new ones
export function removePreviousPickersPopup () {
  console.log('removePreviousPickersPopup')
  const pickerElements = document.querySelectorAll('.pickerPopup')
  if (!pickerElements) return

  pickerElements.forEach(function (onePicker) {
    onePicker.remove()
  })
}
