
// import * as consoleLogger from '../console-logger.js'
// const debugLog = new consoleLogger.DebugLog('listed:alphavantage')
// // debugLog.disable()

export const ALPHAVANTAGE_API_KEY = 'DOIYDEV851RP4R8U'

export const endpoints = {
  search: 'https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=[SEARCH]&apikey=[APIKEY]',
  pnl: 'https://www.alphavantage.co/query?function=INCOME_STATEMENT&symbol=[SYMBOL]&apikey=[APIKEY]',
  earningsCalendar: 'https://www.alphavantage.co/query?function=EARNINGS_CALENDAR&horizon=3month&apikey=[APIKEY]'
}
