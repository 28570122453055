import * as axios from 'axios'
import * as bootstrap from 'bootstrap'

import * as envVar from '../../env.js'
import * as helperModule from '../helpers.js'

// Modelapp, Web UI
import * as modelappWebUIModule from '../../modelapp-ui/web-ui.js'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('item:controller')
// debugLog.disable()

// Name DOM elements
const modalDOMElementId = 'itemEditModal'
const feedbackElementId = 'itemCreationModalFeedback'

export const createItemButtonId = 'createItemOpenModalButton'

//
function openModal () {
  const myModalEl = document.getElementById(modalDOMElementId)

  // Initialise the Element as a Bootstrap Modal
  const modalComponent = new bootstrap.Modal(myModalEl)
  if (!modalComponent) {
    debugLog.warn('modalComponent was not available')
    return
  }
  modalComponent.show()
}

//
function onModalOpen () {
  const myModalEl = document.getElementById(modalDOMElementId)
  myModalEl.addEventListener('shown.bs.modal', function (event) {
    focusItemName()
  })
}

//
function closeModal () {
  debugLog.log('closeModal')
  const myModalEl = document.getElementById(modalDOMElementId)
  const modalComponent = bootstrap.Modal.getInstance(myModalEl)
  modalComponent.hide()
}

//
function focusItemName () {
  const DOMelement = document.getElementById('itemName')
  if (!DOMelement) return

  DOMelement.focus()
}

//
function onCreate (params) {
  debugLog.log('onCreate')

  const response = params.response

  // Empty the fields
  document.getElementById('itemName').value = '' // Reset form

  if (response === 401) {
    const DOMelement = document.getElementById(feedbackElementId)
    DOMelement.innerHTML = '<div class="alert alert-danger" role="alert">You need to be logged in</div>'
    return
  }

  const createdItem = response.data.data
  refreshDataset({
    instance: params.instance,
    fullItem: createdItem,
    tempItem: params.creationParams
  })
  modelappWebUIModule.runAppCalculations()

  focusItemName()
}

//
export function openEditingForm (params) {
  debugLog.log('openEditingForm', params)
  const itemId = params.itemId
  if (!itemId) return

  const DOMelementItemId = document.querySelector('#itemEditForm #itemId')
  debugLog.log('DOMelementItemId', DOMelementItemId)
  if (!DOMelementItemId) return

  // Clicked on item left column
  DOMelementItemId.value = itemId

  // Find the item
  const instanceData = params.instance?.data
  const editingItem = instanceData?.dataset?.items.find(function (oneItem) {
    return oneItem._id === itemId
  })
  if (!editingItem) return

  document.querySelector('#itemEditForm #itemName').value = editingItem.name

  // feed the dimensions:
  showDimensionsForm(editingItem)

  // Show the item editing form
  document.querySelector('#itemId').toggleAttribute('hidden', false)

  openModal()
  focusItemName()
}

//
export function deleteItem (params) {
  const item = params.item
  if (!item) return

  const modelInstance = params.instance

  const itemId = modelInstance.helpers.getItemId(item)
  if (!itemId) return

  const queryUrl = [envVar.apiUrl, 'items', itemId]

  axios.delete(queryUrl.join('/'))
    .then(function (response) {
      debugLog.log('response', response)

      modelInstance.items.remove(item)
      modelappWebUIModule.runAppCalculations()
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

//
function createOrEdit (params) {
  const itemId = document.querySelector('#itemEditForm #itemId').value

  if (itemId) {
    edit(params)
  } else {
    create(params)
  }
}

function create (params) {
  // debugLog.log( 'create' )
  const itemName = document.getElementById('itemName').value
  if (itemName) {
    const queryUrl = [envVar.apiUrl, 'items']

    const creationParams = {
      tempId: helperModule.generateRandomString(8),
      name: itemName,
      modelId: helperModule.readUrl({ targetProperty: 'modelId' })
    }

    const itemDimensions = findItemDimensions().dimensionsArray
    if (itemDimensions) {
      creationParams.dimensions = itemDimensions
    }

    axios.post(queryUrl.join('/'), creationParams)
      .then(function (response) {
        // debugLog.log('response', response)
        onCreate({
          response: response,
          creationParams: creationParams,
          instance: params.instance
        })
      })
      .catch(function (error) {
        debugLog.error('error', error)
      })
      .then(function () {
        debugLog.log('in any case')
        // Push the item to the dataset
        // refresh the model view
      })

    // Push in dataset
    creationParams.isSynced = false

    const instanceData = params.instance?.data
    const items = instanceData?.dataset?.items
    items.push(creationParams)
    modelappWebUIModule.runAppCalculations()
  }
}

/*
  instance
  fullItem
  tempItem
*/
export function refreshDataset (params) {
  const fullItem = params.fullItem
  const tempItem = params.tempItem
  const instanceData = params.instance?.data
  const items = instanceData?.dataset?.items

  items.forEach(function (oneItem, arrayIndex) {
    const matchingTempId = (tempItem.tempId && tempItem.tempId === oneItem.tempId) // at creation
    const matchingEditId = (tempItem.itemId && tempItem.itemId === oneItem._id) // at edit time
    debugLog.log('matchingTempId', matchingTempId, 'matchingEditId', matchingEditId)
    if (matchingTempId || matchingEditId) {
      items[arrayIndex] = fullItem
    }
  })
}

//
function findItemDimensions () {
  const dimensionsArray = []
  let numberEmptyDimensions = 0
  document.querySelectorAll('#itemEditForm .itemDimension').forEach(function (oneItemDimensionElement) {
    const dimensionValue = oneItemDimensionElement.value
    if (dimensionValue === null || dimensionValue === '') {
      numberEmptyDimensions++
      return
    }
    const dimensionValueArray = dimensionValue.split(':')
    if (dimensionValueArray[0] && dimensionValueArray[1]) {
      dimensionsArray.push({
        name: dimensionValueArray[0],
        value: dimensionValueArray[1]
      })
    }
  })
  return {
    numberDimensions: dimensionsArray.length,
    numberEmptyDimensions: numberEmptyDimensions,
    dimensionsArray: dimensionsArray
  }
}

//
// Create the edit form for the dimensions
function showDimensionsForm (oneItem) {
  debugLog.log('showDimensionsForm', oneItem)
  // Empty the fields
  document.getElementById('itemDimensionsContainer').innerHTML = ''

  const inputElementHTML = '<input type="text" class="form-control form-control-sm itemDimension" placeholder="dimension:value" value="<VALUE>">'

  function createDimensionFields () {
    oneItem.dimensions.forEach(function (oneDimension) {
      const dimensionString = oneDimension.name + ':' + oneDimension.value
      const inputElementHTML = '<input type="text" class="form-control form-control-sm itemDimension" placeholder="dimension:value" value="' + dimensionString + '">'
      document.getElementById('itemDimensionsContainer').insertAdjacentHTML('beforeend', inputElementHTML.replace('<VALUE>', dimensionString))
    })
  }

  if (oneItem.dimensions) createDimensionFields()

  // One empty field
  document.getElementById('itemDimensionsContainer').insertAdjacentHTML('beforeend', inputElementHTML.replace('<VALUE>', ''))
}

//
function edit (params) {
  debugLog.log('edit')
  const itemId = document.querySelector('#itemEditForm #itemId').value
  const itemName = document.querySelector('#itemEditForm #itemName').value
  const itemDimensions = findItemDimensions().dimensionsArray

  if (itemId && itemName) {
    const queryUrl = [envVar.apiUrl, 'items', itemId]

    const editBody = {
      itemId: itemId, // not useful in backend; but for front end identification
      name: itemName,
      dimensions: itemDimensions
    }

    axios.put(queryUrl.join('/'), editBody)
      .then(function (response) {
        // debugLog.log('response', response)
        const editedItem = response.data.data

        refreshDataset({
          instance: params.instance,
          fullItem: editedItem,
          tempItem: editBody
        })

        modelappWebUIModule.runAppCalculations()

        closeModal()
      })
      .catch(function (error) {
        debugLog.error('error', error)
      })
      .then(function () {
        debugLog.log('in any case')
        // Push the item to the dataset
        // refresh the model view
      })

    // Push in dataset
    const instanceData = params.instance?.data
    const items = instanceData?.dataset?.items
    const editedItem = items.find(function (oneItem) {
      return oneItem._id === itemId
    })
    editedItem.name = editBody.name
    editedItem.dimensions = itemDimensions
    editedItem.isSynced = false
    refreshDataset({
      instance: params.instance,
      fullItem: editedItem,
      tempItem: editBody
    })
    modelappWebUIModule.runAppCalculations()
  }
}

function updateItemDimensionFields () {
  // Ensure there is field for another dimension
  if (findItemDimensions().numberEmptyDimensions === 0) {
    const inputElementHTML = '<input type="text" class="form-control form-control-sm itemDimension" placeholder="dimension:value">'
    document.getElementById('itemDimensionsContainer').insertAdjacentHTML('beforeend', inputElementHTML)
  }
}

//
// Listener for item editing
export function listenToEdit (params) {
  debugLog.log('item edit, listenToEdit ADD LISTENERS')

  // Check the item editor has been generated
  const itemFormSubmitElement = document.getElementById('item_edit_button')
  if (!itemFormSubmitElement) return

  // Behaviours
  onModalOpen()

  // **** Listeners
  addListeners()

  // Only add the listeners once
  const isListenerAdded = itemFormSubmitElement.getAttribute('isListenerAdded')
  debugLog.log('isListenerAdded?', isListenerAdded)
  if (isListenerAdded) return

  itemFormSubmitElement.addEventListener('click', function (event) {
    event.preventDefault()
    createOrEdit(params)
  })
  itemFormSubmitElement.setAttribute('isListenerAdded', true)
  document.querySelector('#itemEditForm #itemName').addEventListener('keyup', function (event) {
    event.preventDefault()
    updateItemDimensionFields()
    if ([13, 'Enter'].includes(event.key)) {
      createOrEdit(params)
    }
  })

  // Avoid form submit triggering the page reload
  document.querySelector('#itemEditForm').addEventListener('submit', function (event) {
    debugLog.log('submitted')
    event.preventDefault()
  })

  // Listen to dimensions:
  document.querySelector('#itemEditForm').addEventListener('focusout', function (event) {
    debugLog.log('event', event)
    event.preventDefault()
    const fieldDOM = event.target.closest('.itemDimension')
    if (fieldDOM) {
      debugLog.log(fieldDOM.value)
    }
    updateItemDimensionFields()
  })
}

//
function listenItemNew () {
  // Reset the form
  document.getElementById('itemDimensionsContainer').innerHTML = ''
  document.querySelector('#itemEditForm #itemName').value = ''
  document.querySelector('#itemEditForm #itemId').toggleAttribute('hidden', true)
  document.querySelector('#itemEditForm #itemId').value = ''
}

//
function addListeners () {
  // Opening New Item form
  const DOMelement = document.getElementById(createItemButtonId)
  if (!DOMelement) return

  // Ensure the listener is only added once
  if (DOMelement.getAttribute('isListenerAdded') === true) return

  DOMelement.addEventListener('click', function () {
    listenItemNew()
  })

  // Record the Element has the listener already
  DOMelement.setAttribute('isListenerAdded', true)
}
