import * as axios from 'axios'

import * as envVar from '../env.js'

import * as moduleScenarioPicker from './scenarios/scenario-picker.js'
import * as moduleItemEditor from './items/item-editor.js'
import * as moduleItemFormulaEditor from './itemformulas/itemformula-editor.js'
import * as menuModule from '../page-modules/page-menu.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('user')
debugLog.disable()

// Define the DOM element IDs
const userHeaderElement = 'signin'

// Use local storage in the module
const moduleStore = {}
moduleStore.userData = {}
debugLog.log('userModule, set userData')

export function loginUser (apiResponse) {
  debugLog.log('loginUser')
  // Record when we received an API response
  if (apiResponse.status === 200 && apiResponse.data && apiResponse.data.data) {
    debugLog.log('set user data')
    moduleStore.userData = apiResponse.data.data
  }
  showUserHeader()
}

export function logoutUser () {
  moduleStore.userData = false
  showUserHeader()
}

function isUserLoggedIn () {
  debugLog.log('isUserLoggedIn?', moduleStore.userData)
  if (moduleStore.userData) {
    return true
  } else {
    return false
  }
}

export function userData () {
  // debugLog.log('userModule, module data', moduleStore.userData)
  return moduleStore.userData
}

//
function generateGuestHeader () {
  const signedInUser = []
  signedInUser.push('<ul>')
  signedInUser.push('<li>Using App as a Guest</li>')
  signedInUser.push('</ul>')
  signedInUser.push('<a href="/me" class="btn btn-sm btn-danger">Sign Up</a>')

  return signedInUser
}

function generateUserHeader () {
  const signedInUser = []
  signedInUser.push('<ul>')
  // signedInUser.push('<li>', userData()._id, '</li>')
  signedInUser.push('<li>', userData().email, '</li>')
  // signedInUser.push('<li>', userData().name, '</li>')
  signedInUser.push('</ul>')
  signedInUser.push('<a href="/logout">Logout</a>')

  return signedInUser
}

function showLoggedInUser () {
  debugLog.log('showLoggedInUser')
  const DOMelement = document.getElementById(userHeaderElement)
  if (!DOMelement) return

  // debugLog.log('userData', userData())

  let userHeader = []

  if (userData().isGuest) {
    userHeader = generateGuestHeader()
  } else {
    userHeader = generateUserHeader()
  }

  const html = userHeader.join('')
  DOMelement.innerHTML = html
}

export function loginButton () {
  const button = []
  button.push('<a href="/auth/google" class="btn btn-primary">')
  button.push('<i class="fab fa-google"></i>')
  button.push(' Sign in/up with Google')
  button.push('</a>')

  return button.join('')
}

function showLoggedOutUser () {
  const DOMelement = document.getElementById(userHeaderElement)
  if (!DOMelement) return

  DOMelement.innerHTML = loginButton()
}

export function showUserHeader () {
  debugLog.log('showUserHeader')

  if (isUserLoggedIn()) {
    showLoggedInUser()
  } else {
    showLoggedOutUser()
  }
}

//
// Update what needs to be in the app
function refreshAppModules () {
  moduleScenarioPicker.load()
  moduleItemEditor.loadEditor()
  moduleItemFormulaEditor.loadEditor()

  menuModule.refresh()
}

export function loadUser () {
  debugLog.log('loadUser')
  const queryUrl = [envVar.apiUrl, 'user', 'me']
  const queryParams = {}

  return axios.get(queryUrl.join('/'), { params: queryParams })
    .then(function (response) {
      debugLog.log('user response', response)
      loginUser(response)
      refreshAppModules()
    })
    .catch(function (error) {
      debugLog.error('loadUser', error)
    })
    .then(function () {
      // debugLog.log('in any case')
      return 'loadUser done'
    })
}
