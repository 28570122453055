
import * as bootstrap from 'bootstrap'
// import { Dropdown } from 'bootstrap'

import * as scenarioController from './control-scenarios.js'
import htmlScenarioEditor from './editor.html'
import htmlScenarioLayout from './layout.html'

import * as modelappWebUIModule from '../../modelapp-ui/web-ui.js'
import * as userModule from '../user.js'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('scenario:picker')
// debugLog.disable()

export function load (params = {}) {
  debugLog.log('load picker')

  params.targetElement = params.targetElement || 'scenarioRow'
  const targetDOMelement = document.getElementById(params.targetElement)
  if (!targetDOMelement) return

  // Generate the layout
  targetDOMelement.innerHTML = htmlScenarioLayout

  const userData = userModule.userData()

  const modelInstance = appData.modelInstance // params.instance
  const userHasRights = !!(modelInstance.data?.model?.userRights?.canEdit)
  if (userData && userHasRights) {
    debugLog.log('include creation button')

    const editorModal = document.createElement('div')
    editorModal.innerHTML = htmlScenarioEditor

    targetDOMelement.append(editorModal)
  }

  if (appData?.settings?.mode === 'local') {
    const elementsToHide = targetDOMelement.querySelectorAll('.fullMode')
    debugLog.log('elementsToHide', elementsToHide)

    elementsToHide.forEach(function (oneElement) {
      oneElement.toggleAttribute('hidden', true)
    })
  }

  scenarioController.listenCallScenarioCreate()

  // Listen to auto compare scenario
  listenerAutoScenario()
}

//
let listeners = 0 // TODO: improve; for now record we have the listener on the scenario picker
export function proposeScenarios (params = {}) {
  debugLog.log('proposeScenarios')
  const DOMelement = document.getElementById('scenariosPicker')
  if (!DOMelement) {
    debugLog.warn('!! no DOM elements for scenarios')
  }

  const scenarios = params.instance?.data?.dataset?.scenarios
  if (!scenarios) return

  // Show the choice of scenarios
  const scenarioPickerHTML = []
  const scenarioPickerSensitivityValues = []
  const scenarioPickerSensitivityDeltas = []
  scenarios.forEach(function (oneScenario) {
    if (oneScenario.c_isAutoScenario) return

    if (oneScenario?.c_type === 'sensitivityDelta') {
      scenarioPickerSensitivityDeltas.push('<li><a class="dropdown-item" href="#" d-scenarioId="' + oneScenario._id + '">' + oneScenario.name + '</a></li>')
    } else if (oneScenario?.c_type === 'sensitivityValues') {
      scenarioPickerSensitivityValues.push('<li><a class="dropdown-item" href="#" d-scenarioId="' + oneScenario._id + '">' + oneScenario.name + '</a></li>')
    } else {
      scenarioPickerHTML.push('<li d-scenarioId="' + oneScenario._id + '">' + oneScenario.name + '</li>')
    }
  })

  // The main scenarios
  DOMelement.innerHTML = scenarioPickerHTML.join('')

  // if (scenarioPickerSensitivityDeltas.length > 0) {
  //   const DOMelementDeltas = document.getElementById('scenarioDeltas')
  //   DOMelementDeltas.innerHTML = scenarioPickerSensitivityDeltas.join('')
  //   DOMelementDeltas.toggleAttribute('hidden', false)
  // }

  // if (scenarioPickerSensitivityValues.length > 0) {
  //   const DOMelementAutoValues = document.getElementById('scenarioAutoValues')
  //   DOMelementAutoValues.innerHTML = scenarioPickerSensitivityValues.join('')
  //   DOMelementAutoValues.toggleAttribute('hidden', false)
  // }

  if (listeners > 0) return
  document.getElementById('scenariosPicker').addEventListener('click', function (e) {
    debugLog.log('click', e)
    const scenarioButtonElement = e.target.closest('li')
    debugLog.log('scenarioButtonElement', scenarioButtonElement)
    const scenarioId = scenarioButtonElement.getAttribute('d-scenarioId')
    debugLog.log('scenarioId', scenarioId)
    modelappWebUIModule.runAppCalculations({ scenarioId: scenarioId })
  })
  listeners++
}

//
//
function listenerAutoScenario () {
  debugLog.log('listenerAutoScenario')
  const DOMelementTrigger = document.getElementById('autoCompareScenarioPickerTrigger')
  debugLog.log('DOMelement', DOMelementTrigger)
  if (!DOMelementTrigger) return

  // The dropdown trigger listener
  DOMelementTrigger.addEventListener('click', function () {
    debugLog.log('click')

    generateEvaluateListMenu()

    // For some reason, the bootstrap dropdown is not working when initialising it at launch
    const dropdown = new bootstrap.Dropdown(DOMelementTrigger)
    dropdown.show()
  })

  const DOMelement = document.getElementById('autoCompareScenarioPicker')
  DOMelement.addEventListener('click', function (clickEvent) {
    // debugLog.log('clicked', clickEvent)
    const clickedChoiceElement = clickEvent.target.closest('li')

    const periodRef = clickedChoiceElement.getAttribute('d-periodRef')
    const base100 = clickedChoiceElement.getAttribute('d-type')

    let periodRef1
    let function1

    if (base100) {
      // Allows base 100 in first period, or first value of the period
      periodRef1 = '@0:0'
      function1 = 'FIRST'
    } else {
      periodRef1 = +(periodRef)
    }
    // debugLog.log('periodRef', periodRef)

    const autoScenario = appData.modelInstance.autoCompare({
      scenarioId1: appData.settings.view.scenarioId,
      periodRef1,
      function1,
      scenarioId2: appData.settings.view.scenarioId,
      periodRef2: 0,
      forcedFormat: {
        shortcut: '+%'
      }
    })
    modelappWebUIModule.runAppCalculations({ scenarioId: autoScenario._id })
  })
}

export const autoTrendCompareOptions = {
  day: [
    {
      periodRef: -1,
      label: 'Day over Day'
    },
    {
      periodRef: -7,
      label: 'Week over Week'
    },
    {
      periodRef: -28,
      label: 'over 28 days'
    },
    {
      periodRef: -365,
      label: 'over 365 days'
    }
  ],
  week: [
    {
      periodRef: -1,
      label: 'Week over Week'
    },
    {
      periodRef: -4,
      label: 'over 4 Weeks'
    },
    {
      periodRef: -13,
      label: 'over 13 Weeks (a quarter)'
    },
    {
      periodRef: -52,
      label: 'over 52 Weeks'
    }
  ],
  month: [
    {
      periodRef: -1,
      label: 'Month over Month'
    },
    {
      periodRef: -3,
      label: 'Quarter over Quarter'
    },
    {
      periodRef: -12,
      label: 'Year over Year'
    }
  ],
  quarter: [
    {
      periodRef: -1,
      label: 'Quarter over Quarter'
    },
    {
      periodRef: -4,
      label: 'Year over Year'
    }
  ],
  any: [
    {
      periodRef: -1,
      label: 'vs previous period'
    },
    {
      periodRef: -2,
      label: 'over 2 periods'
    },
    {
      periodRef: -4,
      label: 'over 4 periods'
    },
    {
      periodRef: -12,
      label: 'over 12 periods'
    }
  ]
}

export function makeOptionElements (params = {}) {
  const comparisons = autoTrendCompareOptions
  const viewPeriodicity = params.viewPeriodicity

  const optionsData = comparisons[viewPeriodicity] || comparisons.any
  const optionElements = []
  optionsData.forEach(function (oneOption) {
    const optionElement = document.createElement('li')
    optionElement.setAttribute('d-periodRef', oneOption.periodRef)

    const optionButtonElement = document.createElement('button')
    optionButtonElement.setAttribute('type', 'button')
    optionButtonElement.classList.add('dropdown-item')
    optionButtonElement.innerText = oneOption.label

    optionElement.appendChild(optionButtonElement)
    optionElements.push(optionElement)
  })

  return optionElements
}

function generateEvaluateListMenu () {
  const optionElements = makeOptionElements({
    viewPeriodicity: appData.modelInstance.data.timeseries.usedSettings.periodicity
  })

  const DOMelement = document.getElementById('autoCompareScenarioPicker')
  if (!DOMelement) return

  DOMelement.innerHTML = '' // Reset

  // The trend scenarios
  optionElements.forEach(function (optionElement) {
    DOMelement.appendChild(optionElement)
  })

  // Base 100
  const dividerElement = document.createElement('li')
  dividerElement.innerHTML = '<hr class="dropdown-divider">'
  DOMelement.appendChild(dividerElement)

  const base100Element = document.createElement('li')
  base100Element.setAttribute('d-type', 'base100')
  const optionButtonElement = document.createElement('button')
  optionButtonElement.setAttribute('type', 'button')
  optionButtonElement.classList.add('dropdown-item')
  optionButtonElement.innerText = 'Base 100'
  base100Element.appendChild(optionButtonElement)

  DOMelement.appendChild(base100Element)
}
