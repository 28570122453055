
import htmlContentMenuPopup from './popup.html'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('ui:contextMenu')
// debugLog.disable()

const elementId = 'gridContextMenu'

export function showContextMenu (params) {
  const eventTriggerParams = params.eventTriggerParams
  // debugLog.log('showContextMenu', JSON.stringify(eventTriggerParams))
  const contextMenuElement = getElement()

  // Position the popup
  // popupElement.style.backgroundColor = 'red'
  contextMenuElement.style.left = pxPosition(eventTriggerParams.offset.left)
  contextMenuElement.style.top = pxPosition(eventTriggerParams.offset.top + eventTriggerParams.offset.height)
  // debugLog.log('popupElement.style', contextMenuElement.style.left, contextMenuElement.style.top)

  // Generate popup content
  const targetCellJS = params.targetCellJS
  generateContextMenuContent({
    targetCellJS,
    contextMenuElement
  })

  contextMenuElement.toggleAttribute('hidden', false)
}

function generateContextMenuContent (params) {
  // debugLog.log('generateContextMenuContent', params)
  const targetCellJS = params.targetCellJS
  const contextMenuElement = params.contextMenuElement
  contextMenuElement.innerHTML = '' // Reset

  const contextMenuOptions = targetCellJS?.contextMenu

  if (!contextMenuOptions) {
    const menuElement = document.createElement('div')
    menuElement.classList.add('disabled')
    menuElement.innerText = 'No proposed action'
    contextMenuElement.append(menuElement)
    return
  }

  // Generate the menu Elements
  contextMenuOptions.forEach(function (oneMenuOption) {
    if (oneMenuOption.isSeparator) {
      const menuElement = document.createElement('div')
      menuElement.classList.add('separator')
      contextMenuElement.append(menuElement)
    //
    } else if (!oneMenuOption.action) {
      const menuElement = document.createElement('div')
      menuElement.classList.add('disabled')
      menuElement.innerText = oneMenuOption.label || '...'
      contextMenuElement.append(menuElement)
    //
    } else {
      const menuElement = document.createElement('div')
      menuElement.classList.add('enabled')
      menuElement.innerText = oneMenuOption.label || '...'
      menuElement.addEventListener('click', function () {
        if (!oneMenuOption.action) {
          debugLog.warn('no action')
          return
        }
        oneMenuOption.action()
      })
      contextMenuElement.append(menuElement)
    }
  })
}

//
function pxPosition (value) {
  return [value, 'px'].join('')
}

//
export function hideContextMenu () {
  const contextMenuElement = document.getElementById(elementId)
  if (!contextMenuElement) return

  contextMenuElement.toggleAttribute('hidden', true)
}

//
function getElement () {
  // Return the element if already existing
  let contextMenuElement = document.getElementById(elementId)
  if (contextMenuElement) return contextMenuElement

  // Otherwise, create it
  const elementContainer = document.createElement('div')
  elementContainer.innerHTML = htmlContentMenuPopup

  const contentContainer = document.getElementById('content')
  contentContainer.append(elementContainer)

  contextMenuElement = document.getElementById(elementId)

  contextMenuListeners(contextMenuElement)

  return contextMenuElement
}

function contextMenuListeners (contextMenuElement) {
  // Hide menu when mouse goes out
  contextMenuElement.addEventListener('mouseleave', function (event) {
    contextMenuElement.toggleAttribute('hidden', true)
  })
}
