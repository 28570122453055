
import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:dimensions')
debugLog.disable()

const settings = {

  // For franchise models: eg {
  // name: 'module-template', | 'module-use'
  // value: 'model1'
  // }
  dimensionRefs: {
    template: 'module-template',
    use: 'module-use'
  },
  //
  // __symbol: XXX
  // __data:price or __data:pnl,xxx or __data:bs,xxx
  financialDataEndpoints: {
    price: {
      url: ['API_URL', 'marketdata', 'prices', 'SYMBOL']
    },
    adjPrice: {
      url: ['API_URL', 'marketdata', 'prices', 'SYMBOL']
    },
    pnl: {
      url: ['API_URL', 'marketdata', 'fundamentals', 'SYMBOL'],
      includeSecondParam: true
    },
    bs: {
      url: ['API_URL', 'marketdata', 'fundamentals', 'SYMBOL'],
      includeSecondParam: true
    }
  }
}

export function identifyDimensions (params) {
  debugLog.log('identifyModules')

  const dimensionData = {
    templates: [],
    dimensions: {}
  }

  const dataItems = params.instance.data.dataset.items

  if (!dataItems) return
  dataItems.forEach(function (oneItem) {
    analyseItemDimensions({
      dimensionData: dimensionData,
      oneItem: oneItem,
      instance: params.instance
    })
  })

  return dimensionData
}

function analyseItemDimensions (params) {
  // debugLog.log('analyseItemDimensions', params)
  const oneItem = params.oneItem
  if (!oneItem.dimensions) return

  const dimensionData = params.dimensionData

  oneItem.dimensions.forEach(function (oneDimension) {
    // Analyse the Franchise templates
    if (oneDimension.name === settings.dimensionRefs.template) {
      // Detects templates
      const templateName = oneDimension.value
      if (!dimensionData.templates.includes(templateName)) dimensionData.templates.push(templateName)

      oneItem.c_template = templateName
    //
    } else if (oneDimension.name === settings.dimensionRefs.use) {
      // Detects Franchise usage
      const templateName = oneDimension.value
      oneItem.c_template = ' -> ' + templateName
      oneItem.c_template_use = templateName
    //
    //
    } else {
      // Any other dimension
      const dimensionParams = {
        dimensionData: dimensionData,
        oneDimension: oneDimension
      }
      createDimensionStorage(dimensionParams)
      storeDimensionValue(dimensionParams)

      const linkObj = analyseFinancialMarketDimension({
        dimension: oneDimension
      })
      if (linkObj) {
        debugLog.log('linkObj', linkObj, params.instance)
        oneItem.link = oneItem.link || {}
        oneItem.link[linkObj.name] = linkObj
      }
    }
  })

  // Gather the financial markets needs of the item
  if (oneItem.link?.__symbol && oneItem.link?.__data) {
    oneItem.link.symbol = oneItem.link.__symbol.symbol

    oneItem.link.linkEndpoint = generateEndpointUrl({
      item: oneItem,
      instance: params.instance
    })
    oneItem.link.targetProperty = oneItem.link.__data.targetProperty

    if (oneItem.link.__data.metricName) {
      oneItem.link.metricName = oneItem.link.__data.metricName
    }
  }
}

function generateEndpointUrl (params) {
  const oneItem = params.item
  const endpointUrl = oneItem.link.__data.endpoint?.url
  if (!endpointUrl) return
  let linkEndpoint = endpointUrl.join('/').replace('SYMBOL', oneItem.link.symbol)
  linkEndpoint = linkEndpoint.replace('API_URL', params.instance.context.apiUrl)

  return linkEndpoint
}

function analyseFinancialMarketDimension (params) {
  const oneDimension = params.dimension

  const linkObj = {
    name: oneDimension.name
  }
  if (oneDimension.name === '__symbol') {
    linkObj.symbol = oneDimension.value.trim()
  }

  if (oneDimension.name === '__data') {
    linkObj.targetValues = oneDimension.value.split(',')
    linkObj.targetProperty = linkObj.targetValues[0]

    const targetEndpoint = linkObj.targetValues[0]
    const settingsEndpoint = settings.financialDataEndpoints[targetEndpoint]
    if (settingsEndpoint) {
      linkObj.endpoint = settings.financialDataEndpoints[targetEndpoint]

      if (linkObj.endpoint.includeSecondParam) {
        linkObj.metricName = linkObj.targetValues[1].trim()
      }
    }
  }
  return linkObj
}

//
//
function createDimensionStorage (params) {
  const dimensionData = params.dimensionData
  const oneDimension = params.oneDimension

  if (dimensionData.dimensions[oneDimension.name]) return

  dimensionData.dimensions[oneDimension.name] = {
    name: oneDimension.name,
    values: []
  }
}

function storeDimensionValue (params) {
  const dimensionData = params.dimensionData
  const oneDimension = params.oneDimension

  const dimensionStorage = dimensionData.dimensions[oneDimension.name]
  if (dimensionStorage.values.includes(oneDimension.value)) return

  dimensionStorage.values.push(oneDimension.value)
}

export function getDimensionValue (params) {
  const oneItem = params.item
  if (!oneItem) return
  if (!oneItem.dimensions) return

  const dimensionTarget = params.dimensionName
  if (!dimensionTarget) return

  const dimensionObj = oneItem.dimensions.find(function (oneDimension) {
    return oneDimension.name === dimensionTarget
  })
  if (!dimensionObj) return

  return dimensionObj.value
}
