
import * as formulajs from 'formulajs'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('logic:formulaAggregation')
debugLog.disable()

/*
  params
    .values: []
    .function: null or string
*/
export function applyAggregationFunction (params) {
  debugLog.log('applyAggregationFunction', params)
  if (isIncludingError(params)) return false

  let value = null
  if (!params.function) {
    value = executeDefaultSum(params)
    return value
  }

  if (aggregationFunctions[params.function]) {
    debugLog.log('use aggregationFunctions:', params.function)
    // App defined functions
    value = aggregationFunctions[params.function](params.values)
  } else if (!formulajs[params.function]) {
    // function called but not available
    debugLog.warn('!! Function not available:', params.function)
  } else {
    value = formulajs[params.function](params.values)
  }
  return value // Return number
}

/*
  If any of the value referenced is an error, we return an error
*/
function isIncludingError (params) {
  if (!params.values) return

  let numberError = 0
  params.values.forEach(function (oneValue) {
    if (oneValue === false) numberError++
  })

  if (numberError > 0) return true
  return false
}

function executeDefaultSum (params) {
  debugLog.log('executeDefaultSum', params, params.values.length)
  let calcValue = null
  // Default to sum
  if (params.values.length > 0) {
    debugLog.log('go for reduce function')
    let isContainingRealValue = false
    const reducerStartingValue = 0
    const reducerFunction = function (acc, value) {
      // debugLog.log('acc value', acc, value, 'Number.isFinite(value)', Number.isFinite(value))
      if (Number.isFinite(value)) isContainingRealValue = true
      return acc + value
    }
    // We pass the reducerStartingValue to ensure the callback if executed, even for array of just 1 value
    calcValue = params.values.reduce(reducerFunction, reducerStartingValue)
    debugLog.log('isContainingRealValue', isContainingRealValue, calcValue)
    // Identify should a series not include actual values
    if (!isContainingRealValue) calcValue = null
  }
  return calcValue
}

const aggregationFunctions = {
  ROI: function (values) {
    debugLog.log('aggregationFunctions ROI', values)
    const investment = -values[0]
    const returnAmount = values.reduce(function (acc, val) { return acc + val })
    return returnAmount / investment
  },
  IRR: function (values) {
    debugLog.log('aggregationFunctions IRR', values)

    // We need at least 2 values
    if (values.length <= 1) return 0
    // demo: // formulajs.IRR( [-100,100,100], 0.01)
    // Define calculation precision at 0.01
    let irr = formulajs.IRR(values, 0.01)

    // Round number
    if (Number.isFinite(irr)) {
      irr = parseFloat(irr.toFixed(4))
    }
    return irr
  },
  FIRST: function (values) {
    debugLog.log('aggregationFunctions FIRST', values)
    let firstValue = null
    let foundValue = false
    values.forEach(function (oneValue) {
      if (!foundValue && Number.isFinite(oneValue)) {
        foundValue = true
        firstValue = oneValue
      }
    })
    return firstValue
  }
}
