
import * as cssGridHelpers from './css-grid-helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:cell')
debugLog.disable()

/*
  @params
    .cellParams
      .data
      .classes
      .value: the text to show
      .columnPosition

      // Will be enriched:
      .cellClasses
      .characters

    .rowParams
      .rowStats
        .cellCharacters[] // Will be enriched of additional data
      .cells[] // Will be enriched of the cell
*/
export function generateCellElement (params = {}) {
  debugLog.log('generateCellElement', params)

  // Shortcut
  const cellParams = params.cellParams

  // cell Container classes
  const cellClasses = []

  // Default cell class
  cellClasses.push('TLcell')

  // Default positioning classes
  cellClasses.push(...addCellPositionClasses(cellParams))

  // Classes passed from the data
  if (cellParams.data?.classes) {
    cellClasses.push(...cellParams.data?.classes)
  }

  // Add the classes to the Element
  const cellElement = document.createElement('div')
  cellElement.classList.add(...cellClasses)

  // The cell Content Element
  const cellContentElement = document.createElement('div')
  cellContentElement.classList.add('cellInside')
  cellContentElement.innerText = cellParams.data?.value || ''

  cssGridHelpers.generateAttributes(cellParams)
  cellParams.elementAttributes.forEach(function (oneAttribute) {
    cellElement.setAttribute(oneAttribute[0], oneAttribute[1])
  })

  // Add a drag handle if needed
  if (cellParams.data?.isIncludeDragHandle) {
    const dragHandle = document.createElement('div')
    dragHandle.classList.add('draghandle')
    dragHandle.innerText = '•' // '[+]'
    cellElement.appendChild(dragHandle)
  }

  // Add the element
  cellElement.appendChild(cellContentElement)

  cellParams.cellClasses = cellClasses

  // Calculate states
  cellParams.characters = evaluateCellContent(cellParams)

  return cellElement
}

//
//
function evaluateCellContent (cellParams = {}) {
  const contentLength = (cellParams.data.value || '').toString().length
  return contentLength
}

//
//
function addCellPositionClasses (cellParams = {}) {
  const classes = []

  // Analyse the Columns
  switch (cellParams.data.columnPosition) {
    case 'left':
      classes.push('cellColTypeLeft')
      break

    case 'center':
      classes.push('cellColTypeCenter')
      break

    case 'right':
      classes.push('cellColTypeRight')
      break
  }

  // Analyse the Rows
  switch (cellParams.data.rowPosition) {
    case 'content':
      classes.push('cellRowTypeContent')
      break

    case 'header':
      classes.push('cellRowTypeHeader')
      break
  }

  return classes
}
