
import * as logicFormulaPeriods from './logic-formula-periods.js'
import * as logicFormulaAggregations from './logic-formula-aggregations.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('eval:multipleItems')
debugLog.disable()

/*
  params.stepIndex
  params.scenarioId
  params.instance
  params.ref = {
    items,
    period,
    function
  }
*/
export function evaluateItemStepItemsValues (params = {}) {
  debugLog.log('evaluateItemStepItemsValues', params)

  // Find the period to consider
  const periodAbs = logicFormulaPeriods.returnPeriodArrayParse({
    period: params.ref.period,
    stepIndex: params.stepIndex,
    instance: params.instance
  })

  if (!Number.isFinite(periodAbs[0])) debugLog.warn('!! invalid period ref:', periodAbs, 'for evaluateItemStepItemsValues', params)

  // Find the items to consider
  const filteredItems = params.ref.c_items

  // Get the value for each of such item
  let value = 0
  if (!filteredItems) {
    debugLog.warn('!! No items for evaluateItemStepItemsValues', params)
    return value
  }

  if (periodAbs[0] < 0) return 0 // Avoid past values issue

  const values = []
  filteredItems.forEach(function (oneItem) {
    // Only manage 1 period for such multi item calculations: so evaluateItemStepValue
    debugLog.log('call evaluateItemStepValue', params)
    const valueObj = params.instance.helpers.evaluateItemStepValue({
      item: oneItem,
      stepIndex: periodAbs[0],
      scenarioId: params.scenarioId,
      instance: params.instance,
      parentParams: params.parentParams
    })
    const v = valueObj.value
    if (Number.isFinite(v)) values.push(v)
  })

  value = logicFormulaAggregations.applyAggregationFunction({
    function: params.ref.function,
    values: values
  })

  // debugLog.log( 'filteredItems', filteredItems )
  return value
}
