
import * as cssGridCell from './css-grid-cell.js'
import * as cssGridHelpers from './css-grid-helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:scrollableElements')
debugLog.disable()

export const settings = {
  rowClasses: {
    baseStyling: 'gridOneRow',
    gridTemplateColumns: 'gridDefColumns'
  }
}

/*
  @params

    .rowParams
      .data: []
      .rowIndex:

      // Will the enriched
      .cells[]
      .rowStats
        .cellCharacters[] // Will be enriched of additional data
        .columnType: []
      .rowClasses: []

*/
export function generateScrollableGridRow (rowParams = {}) {
  debugLog.log('generateScrollableGridRow', rowParams)

  // row Container classes
  const rowClasses = []

  // Default cell class
  rowClasses.push(settings.rowClasses.baseStyling)
  rowClasses.push(settings.rowClasses.gridTemplateColumns)

  // Add the classes to the Element
  const rowElement = document.createElement('div')
  rowElement.classList.add(...rowClasses)

  // Add attributes
  const paramsOfRow = rowParams.rowParams // Because we use sub-element for scrolling; the full rowParams are in a property
  cssGridHelpers.generateAttributes(paramsOfRow)
  paramsOfRow.elementAttributes?.forEach(function (oneAttribute) {
    rowElement.setAttribute(oneAttribute[0], oneAttribute[1])
  })

  // Process each cell
  const cells = rowParams.cells
  cells.forEach(function (oneCell, arrayIndex) {
    const cellParams = {
      data: oneCell,
      cellIndex: arrayIndex
    }

    const cellElement = cssGridCell.generateCellElement({
      cellParams: cellParams
    })

    rowElement.appendChild(cellElement)
  })

  return rowElement
}

export function analyseCellByScrollableElement (rowParams = {}) {
  const cellsByScrollableElement = rowParams.moduleParams.cellsByScrollableElement

  const rowCells = {}
  let yClass

  // Process each cell
  const cells = rowParams.data?.cells || rowParams.data
  cells.forEach(function (oneCell) {
    const xClass = oneCell.columnPosition
    yClass = oneCell.rowPosition

    rowCells[xClass] = rowCells[xClass] || { rowParams: rowParams, cells: [] }
    rowCells[xClass].cells.push(oneCell)
  })

  Object.keys(rowCells).forEach(function (oneXclass) {
    cellsByScrollableElement[oneXclass] = cellsByScrollableElement[oneXclass] || {}
    cellsByScrollableElement[oneXclass][yClass] = cellsByScrollableElement[oneXclass][yClass] || []

    cellsByScrollableElement[oneXclass][yClass].push(rowCells[oneXclass])
  })
}

export function getGridScrollingElement (params) {
  debugLog.log('getGridScrollingElement', params)
  const xClass = params.xClass // left, center, right
  const yClass = params.yClass // header, content, footer
  const scrollableElements = params.scrollableElements

  scrollableElements[xClass] = scrollableElements[xClass] || {}
  if (!scrollableElements[xClass][yClass]) scrollableElements[xClass][yClass] = generateGridScrollingElement(params)

  return scrollableElements[xClass][yClass]
}

/*

*/
function generateGridScrollingElement (params) {
  debugLog.log('generateGridScrollingElement', params)
  const xClass = params.xClass // left, center, right
  const yClass = params.yClass // header, content, footer

  const gridScrollingElement = document.createElement('div')
  gridScrollingElement.classList.add(xClass)
  gridScrollingElement.classList.add(yClass)
  gridScrollingElement.classList.add('gridScrollableContainer')

  debugLog.log('gridScrollingElement', gridScrollingElement)

  return gridScrollingElement
}

export function listenGridScrolling (params) {
  debugLog.log('listenGridScrolling', params)
  const DOMGridContainer = params.DOMGridContainer
  debugLog.log('DOMGridContainer', DOMGridContainer)

  const scrollableElements = params.moduleParams.scrollableElements

  DOMGridContainer.querySelectorAll('div.gridScrollableContainer').forEach(function (oneEl) {
    oneEl.addEventListener('scroll', function (scrollEvent) {
      // debugLog.log('scrollEvent.target.classList', scrollEvent.target.classList)
      // debugLog.log('scroll', scrollEvent, scrollEvent.target.scrollTop)
      // scrollEvent.target.scrollTop
      // scrollEvent.target.scrollLeft

      if (scrollEvent.target.classList.contains('left') && scrollEvent.target.classList.contains('header')) {
        // debugLog.log('header left')
        scrollableElements.left.header.scroll({
          top: scrollEvent.target.scrollTop
          // behaviour: 'smooth'
        })
        scrollableElements.left.content.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('center') && scrollEvent.target.classList.contains('header')) {
        // debugLog.log('top right')
        scrollableElements.left.header.scroll({
          top: scrollEvent.target.scrollTop
          // behaviour: 'smooth'
        })
        scrollableElements.center.content.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('left') && scrollEvent.target.classList.contains('content')) {
        // debugLog.log('bottom left')
        scrollableElements.center.content.scroll({
          top: scrollEvent.target.scrollTop
          // behaviour: 'smooth'
        })
        scrollableElements.left.header.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('center') && scrollEvent.target.classList.contains('content')) {
        // debugLog.log('bottom right')
        scrollableElements.left.content.scroll({
          top: scrollEvent.target.scrollTop
          // behaviour: 'smooth'
        })
        scrollableElements.center.header.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }
    })
  })
}
