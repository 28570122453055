
import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('logic:formulaPeriods')
debugLog.disable()

/*
  Returns the absolute periods based on the period on which the formula is called
  [start, end]
  Or false if invalid

  @params: {
    period: params.ref.period,
    stepIndex: params.stepIndex
  }

  // '0' : string for absolute periods
  // -0 : Number of relative periods
  // eg '0',-1 : from start to previous
  // +1: future periods: why would we need that?
  // '0',+Infinity: all periods
*/
export function returnPeriodArrayParse (params = {}) {
  if (!params.period) return false
  return parsePeriods(params)
}

//
//
function parsePeriods (params) {
  debugLog.log('parsePeriods')
  const periodAbs = []

  // Period 1
  const periodRef = extractPeriodAbs(params, 0)
  periodAbs.push(periodRef)

  // Period 2
  const periodRef2 = extractPeriodAbs(params, 1)
  if (Number.isFinite(periodRef2)) {
    periodAbs.push(periodRef2)
  } else {
    periodAbs.push(periodRef)
  }

  // debugLog.log( periodAbs )
  return periodAbs
}

//
//
function extractPeriodAbs (params, arrayKey) {
  const targetPeriodArray = params.period
  const targetPeriodRef = targetPeriodArray[arrayKey]
  debugLog.log('extractPeriodAbs', targetPeriodArray, arrayKey)
  if (targetPeriodRef === undefined) return false

  let v
  if (Number.isFinite(targetPeriodRef)) {
    v = targetPeriodRef + params.stepIndex
  } else {
    // Expecting absolute reference to be of the form '@X' hence removing the first character

    // Adjust for the view timesries
    const periodSeriesViewOffset = params.instance?.data?.timeseries?.periodSeriesViewOffset || 0
    v = +(targetPeriodRef).substr(1) + periodSeriesViewOffset
  }

  return v
  // return Math.max(0, v) // We can not have negative value -> TODO: always calculate from period 0
}

/*
  Potential periods reference:
  @0:0 // Range Absolute to Relative
  @1 // Absolute

  Returns an array [] with 1 or 2 values
*/
export function parsePeriodsReference (periodsRef) {
  let isCorrectValue = true

  const mapped = periodsRef.split(':').map(function (o) {
    const refValue = Number.isFinite(+o) ? +o : o

    if (typeof refValue === 'string') {
      const passesRegex = refValue.match(/@[0-9]+/)
      if (!passesRegex) isCorrectValue = false
    }
    return refValue
  })

  if (!isCorrectValue) return false

  return mapped
}
