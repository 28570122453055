
import * as accounting from 'accounting'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelogic:formatting')
debugLog.disable()

/*
... Formatting
  Input
  ... Unit: eg. thousands, millions...
  ... Currency

  View
  ... format: $, #, %
  ... Decimals
  ... Unit: 0, thousands, millions -> apply the multiplier
  ... Unit symbol: number, k, m, bn
  ... currencySymbol: US$, S$, HK$, Euro...

  UX picker:
  v1:
  Format as: Currency ($), Number, Percentage (%)
  Show Decimals: 0, 1, 2

  {
    formatAs: '$', '#', '%', '+%'
    numberDecimals: 0
  }

  v2: specify the units and symbol
*/

/*
  @params
    .value
    .formatAs: string or {
      shortcut
      anyProperty
    }
*/
export function format (params) {
  debugLog.log('format', params)

  // const formatShortcut = params.formatAs?.shortcut || params.formatAs
  const formatShortcut = params.shortcut

  if (formatShortcut && mapping[formatShortcut]) {
    // debugLog.log('shortcut formatting')
    return mapping[formatShortcut](params)
  } else {
    // Find a default
    const absValue = Math.abs(params.value)
    if (absValue === 0) {
      params.numberDecimals = Number.isFinite(params.numberDecimals) ? params.numberDecimals : 0
      return formatNumber(params)
    } else if (absValue !== 0 && absValue < 1) {
      return formatPct(params)
    } else if (absValue < 10) {
      params.numberDecimals = Number.isFinite(params.numberDecimals) ? params.numberDecimals : 1
      return formatNumber(params)
    } else {
      return formatNumber(params)
    }
  }
}

const mapping = {
  '+%': formatPctTrend,
  '%': formatPct,
  '#': formatNumber,
  $: formatCurrency
}

//
/*
  Receives a pct number as Float: 0.1 -> 10%
*/
export function formatPctTrend (params) {
  const pctValue = params.value
  return accounting.formatMoney((100 * pctValue), {
    symbol: '%',
    format: {
      pos: '+%v%s',
      neg: '-%v%s',
      zero: '-'
    },
    precision: params.numberDecimals || 0
  })
}

//
/*
  Receives a pct number as Float: 0.1 -> 10%
*/
export function formatPct (params) {
  const pctValue = params.value
  return accounting.formatMoney((100 * pctValue), {
    symbol: '%',
    format: {
      pos: '%v%s',
      neg: '-%v%s',
      zero: '-'
    },
    precision: params.numberDecimals || 0
  })
}

/*
  @params
    .currencySymbol
    .numberDecimals
    .unitSymbol
*/
export function formatCurrency (params) {
  return accounting.formatMoney(params.value, {
    symbol: '$', // Should be an option: params.currencySymbol
    format: {
      pos: '%s %v', // Should include a symbol eg 'k'
      neg: '%s -%v',
      zero: '0'
    },
    decimal: '.',
    thousand: ',',
    precision: params.numberDecimals || 0
  })
}

//
//
export function formatNumber (params) {
  return accounting.formatMoney(params.value, {
    symbol: '', // Should be an option
    format: {
      pos: '%v', // Should include a symbol eg 'k'
      neg: '-%v',
      zero: '%v'
    },
    decimal: '.',
    thousand: ',',
    precision: params.numberDecimals || 0
  })
}

/*
  @params
    .date: YYYY-MM-DD value
    .periodicity: to know how to format
*/
export function formatDate (params) {
  debugLog.log('formatDate', params)
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat

  const settings = {
    year: {
      year: 'numeric'
    },
    quarter: {
      year: 'numeric',
      month: 'short'
    },
    month: {
      year: 'numeric',
      month: 'short'
    },
    week: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    },
    day: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }

  if (settings[params.periodicity]) {
    const dateFormat = settings[params.periodicity]
    const dateTimeFormatting = new Intl.DateTimeFormat('en', dateFormat)

    const date = new Date(params.date)
    return dateTimeFormatting.format(date)
  } else {
    return params.date
  }
}
