
import * as axios from 'axios' // Make a dedicated instance to avoid the app interceptor (which add headers rejected by AlphaVantage)

import * as envVar from '../env.js'

import * as alphaSettings from './listed-settings.js'

import * as modelogic from '../modelogic/index.js'
import * as helperModule from '../frontend/helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('listed:alphavantage:index')
// debugLog.disable()

const alphaVantageAxiosInstance = axios.create()
const ALPHAVANTAGE_API_KEY = alphaSettings.ALPHAVANTAGE_API_KEY
const endpoints = alphaSettings.endpoints

let modelInstance = null

export async function loadPrices (params) {
  debugLog.log('loadPrices', params)

  const symbol = params.symbol
  const queryUrl = [envVar.apiUrl, 'marketdata', 'prices', symbol]

  return axios.get(queryUrl.join('/'), {})
    .then(function (response) {
      debugLog.log('response', response)
    })
    .catch(function (error) {
      debugLog.error('error:', error)
    })
}

export async function runPnLQuery (params) {
  debugLog.log('runPnLQuery', params)
  const symbol = params.symbol

  let queryUrl = endpoints.pnl
  queryUrl = queryUrl.replace('[APIKEY]', ALPHAVANTAGE_API_KEY)
  queryUrl = queryUrl.replace('[SYMBOL]', symbol)

  return alphaVantageAxiosInstance.get(queryUrl)
    .then(async function (response) {
      debugLog.log(response)
      await parsePnL({
        reports: response.data,
        reportNames: 'quarterlyReports'
      })
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

async function parsePnL (params) {
  debugLog.log('parsePnL', params)
  const reportNames = params.reportNames
  const reports = params.reports

  const reportsToUse = reports[reportNames]
  debugLog.log('reportsToUse', reportsToUse)
  if (!reportsToUse) return

  await createInstanceModel(params)

  const periods = []
  params.periods = periods

  reportsToUse.forEach(function (oneReport) {
    debugLog.log('oneReport', oneReport)
    const reportDate = oneReport.fiscalDateEnding
    // const currency = oneReport.reportedCurrency

    const modelValueDate = turnPeriodsToDate(reportDate)

    periods.push(modelValueDate)

    Object.entries(oneReport).forEach(function (oneReportProperty) {
      const propertyName = oneReportProperty[0]
      const propertyValue = oneReportProperty[1]

      if (Number.isFinite(+propertyValue)) {
        const item = returnItem({
          itemName: propertyName
        })
        // debugLog.log('item', item)

        recordValue({
          item: item,
          value: propertyValue,
          period: modelValueDate
        })
      }
    })
  })

  updatePeriodSeries(params)

  debugLog.log(modelInstance)
}

function turnPeriodsToDate (fiscalDateEnding) {
  const baseMonth = fiscalDateEnding.substr(0, 7)
  return baseMonth + '-01'
}

//
function recordValue (params) {
  // debugLog.log('recordValue', params)
  const startingPeriod = params.period

  const scenarioId = modelInstance.data?.dataset?.scenarios[0]._id
  // debugLog.log('scenarioId', scenarioId)

  const newItemValue = {
    tempId: helperModule.generateRandomString(),
    formula: params.value,
    itemId: modelInstance.helpers.getItemId(params.item),
    scenarioId: scenarioId,
    periods: {
      first_period: startingPeriod,
      repeat_periods: 1
    }
  }
  // debugLog.log('newItemValue', newItemValue)
  modelInstance.formulas.add(newItemValue)
}

function returnItem (params) {
  // debugLog.log('returnItem', params)
  const item = findItem(params)
  // debugLog.log('item after findItem', item)
  if (item) return item

  // const itemTempId = helperModule.generateRandomString()
  const itemId = '' + params.itemName
  const newItem = {
    tempId: itemId,
    name: turnIdToName(params.itemName) // Ensure it's a string
  }
  // debugLog.log('newItem', newItem)
  modelInstance.items.add(newItem)
  return newItem
}

function turnIdToName (itemFinancialName) {
  const baseName = '' + itemFinancialName
  // Found in https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
  const result = baseName.replace(/([A-Z])/g, ' $1')
  const nameAsWords = result.charAt(0).toUpperCase() + result.slice(1)
  return nameAsWords
}

function sortItems () {
  const baseOrder = [
    'totalRevenue',
    'nonInterestIncome',

    'costOfRevenue',
    'costofGoodsAndServicesSold',
    'grossProfit',

    'researchAndDevelopment',
    'sellingGeneralAndAdministrative',
    'operatingExpenses',

    'investmentIncomeNet',
    'netInterestIncome',

    'interestIncome',
    'interestExpense',
    'otherNonOperatingIncome',
    'ebitda',
    'depreciationAndAmortization',
    'depreciation',
    'ebit',
    'operatingIncome',
    'interestAndDebtExpense',
    'incomeBeforeTax',
    'incomeTaxExpense',

    'comprehensiveIncomeNetOfTax',
    'netIncome',
    'netIncomeFromContinuingOperations'
  ]
  return baseOrder
}

function findItem (params) {
  // debugLog.log('findItem', params, modelInstance)
  const modelInstanceItems = modelInstance.data?.dataset?.items
  // debugLog.log('modelInstanceItems', modelInstanceItems)
  if (!modelInstanceItems) return
  return modelInstanceItems.find(function (oneItem) {
    // debugLog.log(oneItem, 'vs. ', params.itemName)
    return modelInstance.helpers.getItemId(oneItem) === params.itemName
  })
}

async function createInstanceModel (params) {
  debugLog.log('createInstanceModel', params)
  modelInstance = new modelogic.Modelapp()
  appData.modelInstance = modelInstance
  await modelInstance.initModel({
    modelData: {
      name: 'P&L for ' + params.reports.symbol,
      settings: {
        sortedItemIds: sortItems()
      }
    }
  })

  const scenarioName = params.reportNames
  modelInstance.update.scenario.add({
    _id: scenarioName,
    name: scenarioName
  })

  appData.settings = appData.settings || {}
  appData.settings.view = appData.settings.view || {}
  appData.settings.view.scenarioId = scenarioName

  debugLog.log('modelInstance', modelInstance)
}

//
function updatePeriodSeries (params) {
  debugLog.log('updatePeriodSeries', params)
  const sortedPeriods = params.periods.sort()
  modelInstance.updateTimeSeriesSettings({
    starting: sortedPeriods[0],
    periods: sortedPeriods.length,
    periodicity: params.reportNames === 'quarterlyReports' ? 'quarter' : 'year'
  })
}
