
import * as modelAppHelpers from './helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modelapp:data')
debugLog.disable()

export function prepareFormulaObj (formulaObj) {
  // debugLog.log('prepareFormulaObj', formulaObj)
  formulaObj.definition = {
    itemId: formulaObj.itemId || formulaObj._item,
    scenarioId: formulaObj.scenarioId || formulaObj._scenario,
    first_period: formulaObj.periods.first_period,
    repeat_periods: formulaObj.periods.repeat_periods || false
  }
  return formulaObj
}

export function storeReceivedData (receivedData = {}) {
  debugLog.log('prepareFormulaObj', receivedData)
  debugLog.log('arg', arguments)
  debugLog.log('this', this)

  const data = {}

  // Stores the full object
  data.model = receivedData

  // Store key data
  data.dataset = {
    items: receivedData?._items || receivedData?.items || [],
    valueFormulas: receivedData?._itemFormulas || receivedData?.itemFormulas || receivedData?.itemsFormulas || [],
    scenarios: receivedData?._scenarios || receivedData?.scenarios || []
  }

  if (data.dataset.valueFormulas.length === 0) {
    debugLog.warn('No formulas included')
  }

  // Manipulate the formulas for proper parsing
  data.dataset.valueFormulas.forEach(function (formulaObj) {
    prepareFormulaObj(formulaObj)
  })

  return data
}

//
// ***** ITEMS

// Add an item
export function itemAdd (params = {}) {
  const dataset = params.dataset
  const item = params.item

  if (!item.tempId && !modelAppHelpers.getItemId(item)) {
    debugLog.warn('missing id')
    return
  }
  if (!item.name) {
    debugLog.warn('missing name')
    return
  }
  dataset.items.push(params.item)
}

export function itemRemove (params = {}) {
  const dataset = params.dataset
  const item = params.item

  const itemId = modelAppHelpers.getItemId(item) || item.tempId
  if (!itemId) {
    debugLog.warn('missing id')
    return
  }

  const itemIndex = dataset.items.findIndex(function (oneItem) {
    return itemId === modelAppHelpers.getItemId(oneItem)
  })

  // Remove from the array of items; in place
  if (itemIndex >= 0) {
    dataset.items.splice(itemIndex, 1)
  }
}

// tempId: helperModule.generateRandomString(8),
//       name: itemName,
//       modelId: helperModule.readUrl({ targetProperty: 'modelId' })

// UPDATE
// Search by tempId or modelAppHelpers.getItemId(item)

//
//
// ****** FORMULAS

// ADD
export function formulaAdd (params = {}) {
  debugLog.log('formulaAdd', params)
  const dataset = params.dataset
  const itemFormula = params.itemFormula

  if (!itemFormula.tempId && !itemFormula._id) {
    debugLog.warn('missing tempId')
    return
  }

  const formulaString = ('' + itemFormula.formula)
  if (!formulaString) {
    debugLog.warn('missing formula')
    return
  }
  if (!itemFormula.itemId && !itemFormula._item) {
    debugLog.warn('missing itemId')
    return
  }
  if (!itemFormula.scenarioId && !itemFormula._scenario) {
    debugLog.warn('missing scenarioId')
    return
  }

  prepareFormulaObj(itemFormula)
  dataset.valueFormulas.push(itemFormula)
}

//
//
export function itemFormulaRemove (params = {}) {
  const dataset = params.dataset
  const itemFormula = params.itemFormula

  const itemFormulaId = itemFormula._id || itemFormula.tempId
  if (!itemFormulaId) {
    debugLog.warn('missing id')
    return
  }

  const itemFormulaIndex = dataset.valueFormulas.findIndex(function (oneFormula) {
    return itemFormulaId === oneFormula._id || oneFormula.tempId
  })

  // Remove from the array of items; in place
  if (itemFormulaIndex >= 0) {
    dataset.valueFormulas.splice(itemFormulaIndex, 1)
  }
}

//
//
// ***** SCENARIO
//
//

/*
  @params
    .instance

    .baseItem // Testing to add
    .formulaType // Testing to add
*/
export function findAutoScenario (params = {}) {
  debugLog.log('findAutoScenario', params)
  const modelInstance = params.instance

  const trendScenario = modelInstance.data.dataset.scenarios.find(function (oneScenario) {
    if (!oneScenario.c_isAutoScenario) return false

    if (params.scenarioId1) {
      if (!(oneScenario.c_params.scenarioId1 === params.scenarioId1)) return false
    }

    if (params.scenarioId2) {
      if (!(oneScenario.c_params.scenarioId2 === params.scenarioId1)) return false
    }

    if (params.periodRef1) {
      if (!(oneScenario.c_params.periodRef1 === params.periodRef1)) return false
    }

    if (params.periodRef2) {
      if (!(oneScenario.c_params.periodRef2 === params.periodRef2)) return false
    }

    if (params.function1) {
      if (!(oneScenario.c_params.function1 === params.function1)) return false
    }

    return true
  })
  return trendScenario
}

export function scenarioRemove (params = {}) {
  const dataset = params.dataset
  const scenario = params.scenario

  const scenarioId = scenario._id || scenario.tempId
  if (!scenarioId) {
    debugLog.warn('missing id')
    return
  }

  const scenarioIndex = dataset.scenarios.findIndex(function (oneScenario) {
    return scenarioId === oneScenario._id || oneScenario.tempId
  })

  // Remove from the array of items; in place
  if (scenarioIndex >= 0) {
    dataset.scenarios.splice(scenarioIndex, 1)
  }
}

// UPDATE
// TODO
