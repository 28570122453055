// How to import from npm module???
// import 'page'
// import { page } from 'page'
// import 'https://unpkg.com/page/page.js'

// Axios Interceptors - Load before any request is sent
import './helpers-ajax.js'

import * as appStarter from './app-starter.js'
import * as socketIO from './socket.js'
import * as moduleModel from './model/model.js'

import * as moduleMe from '../page-modules/me.js'
import * as profileMe from '../user-profile/profile-me.js'
import * as pageFooter from '../page-modules/footer.js'

import * as webUIItemChart from '../modelapp-ui/web-ui-chart.js'

import * as dataSyncPage from '../data-sync/data-sync.js'

import * as formulaEditing from '../formula-editor/index.js'

import * as listedCompaniesSearch from '../listed/listed-search.js'
import * as listedCompaniesModel from '../listed/listed-model.js'

import * as inviteController from './models/model-invited.js'

import * as modelogic from '../modelogic/index.js'
import * as modelDataModule from './model-data.js' // Serves as modelData Storage

import * as jsDemo from '../demo_test/async_functions.js'
import * as expFontSize from '../experiments/fontsize.js'
import * as expFormSelector from '../experiments/form-select.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('app:router')
// debugLog.disable()

// ONGOING: page base loads:
// Load user: Need to happen one first page load only; then it updated if any request returns 401 (Axios interceptor)
// Load model: Need to be for specific page only
// So there can be a then()
//

//
// All routes will be at /app/xxx
export const settings = {
  baseRoute: '/app'
}
page.base(settings.baseRoute)

page('/public', function () {
  debugLog.log('public')
  // App Loader
  appStarter.pageLoader()

  // In parallel
  moduleMe.moduleMe.load({
    modelFilters: {
      isPublic: true,
      isMine: false
    },
    showCreate: false,
    page: 'public'
  })
})

page('/me', function () {
  debugLog.log('me')
  // App Loader
  appStarter.pageLoader()

  // In parallel
  moduleMe.moduleMe.load({
    modelFilters: {
      isPublic: false,
      isMine: true
    },
    showCreate: true,
    page: 'me'
  })

  // socketIO.joinRooms()
})

//
//
page('/profile/me', async function () {
  debugLog.log('/profile/me')
  // App Loader
  const pageLoaded = await appStarter.pageLoader()
  debugLog.log('*** pageLoaded', pageLoaded)

  // Wait for response, then load the actual page
  profileMe.load()

  // socketIO.joinRooms()
})

//
//
settings.oneModel = '/model/:modelId'
page(settings.oneModel, async function (context, next) {
  debugLog.log('model', context.params)
  // App Loader
  const pageLoaded = await appStarter.pageLoader()
  debugLog.log('*** pageLoaded', pageLoaded)

  // Wait for response, then load the actual page:

  // *** LOAD THE MODEL
  let modelInstance
  const modelId = context.params.modelId

  if (['fileLoaded', 'listedCompany'].includes(modelId)) {
    appData.settings = appData.settings || {}
    appData.settings.mode = 'local'
  //
  } else if (modelId.substr(0, 7) === 'listed:') {
    appData.settings = appData.settings || {}
    appData.settings.mode = 'local'

    const tickerSymbol = modelId.substr(7)

    await listedCompaniesModel.loadPrices({
      symbol: tickerSymbol
    })
    debugLog.log('prices loaded')

    await listedCompaniesModel.runPnLQuery({
      symbol: tickerSymbol
    })
    debugLog.log('PnL ready')
  //
  } else {
    const modelLoaded = await modelDataModule.loadModel({
      modelId: modelId
    })
    debugLog.log('modelLoaded', modelLoaded)

    modelInstance = new modelogic.Modelapp()
    appData.modelInstance = modelInstance
    await modelInstance.initModel({ modelData: modelLoaded })
  }

  // For some reason Bootstrap backdrop sometimes remain (eg after model copy)... so we brute force remove them
  document.querySelectorAll('.modal-backdrop').forEach(function (oneE) { oneE.remove() })

  // *** THEN EXECUTE THE PAGE
  moduleModel.load()

  // Additional: charts?
  debugLog.log('context', context)
  debugLog.log('url details?:', context.hash)
  // http://localhost:3000/app/model/613add799f60eb25789ef2bf#{%22details%22:%22chart%22,%22options%22:{%22x%22:%22a%22,%22y%22:%22b%22}}
  if (context.hash) {
    const urlHashObj = JSON.parse(context.hash)
    debugLog.log(urlHashObj)
    webUIItemChart.showChartWithxAxis({
      instance: modelInstance,
      filters: {
        xFilter: urlHashObj.options.x,
        yFilter: urlHashObj.options.y
      }
    })
  }

  // Extras
  socketIO.joinRooms({
    modelId: modelId
  })
  pageFooter.loadSocketStatus()
})

//
//
page('/invite/:inviteId', async function (context, next) {
  debugLog.log('invite', context.params)
  // App Loader
  const pageLoaded = await appStarter.pageLoader()
  debugLog.log('*** pageLoaded', pageLoaded)
  // Wait for response, then load the actual page:

  // Stores the inviteId, allowing redirect after login
  localStorage.setItem('inviteId', context.params.inviteId)
  //
  inviteController.load({
    inviteId: context.params.inviteId
  })
})

//
//
page('/datasync', async function (context, next) {
  debugLog.log('datasync')
  // App Loader
  const pageLoaded = await appStarter.pageLoader()
  debugLog.log('*** pageLoaded', pageLoaded)
  // Wait for response, then load the actual page:

  dataSyncPage.load()
})

page('/js_test', function () {
  debugLog.log('js_test')

  // App Loader
  appStarter.pageLoader()

  jsDemo.runTest()
})

page('/loggedin', function () {
  debugLog.log('loggedin')

  const inviteId = localStorage.getItem('inviteId')

  if (inviteId) {
    page.redirect('/invite/' + inviteId)
  } else {
    page.redirect('/')
  }
})

page('/listed', async function (context, next) {
  debugLog.log('listed companies', context.params)
  // App Loader
  const pageLoaded = await appStarter.pageLoader()
  debugLog.log('*** pageLoaded', pageLoaded)

  // Wait for response, then load the actual page:

  // *** THEN EXECUTE THE PAGE
  listedCompaniesSearch.showPage()
})

page('/tableScrolling', function () {
  debugLog.log('tableScrolling')
  const DOMelement = document.getElementById('content')
  debugLog.log('DOMelement', DOMelement)
  if (!DOMelement) return

  const DOMGridContainer = document.createElement('div')
  DOMGridContainer.classList.add('scrollingContainer')

  const DOMGridTopLeft = document.createElement('div')
  DOMGridTopLeft.classList.add('top')
  DOMGridTopLeft.classList.add('left')
  DOMGridTopLeft.setAttribute('id', 'topLeft')
  const DOMinside1 = document.createElement('div')
  DOMinside1.classList.add('insideCell')
  DOMGridTopLeft.append(DOMinside1)

  const DOMGridTopRight = document.createElement('div')
  DOMGridTopRight.classList.add('top')
  DOMGridTopRight.classList.add('right')
  DOMGridTopRight.setAttribute('id', 'topRight')
  const DOMinside2 = document.createElement('div')
  DOMinside2.classList.add('insideCell')
  DOMGridTopRight.append(DOMinside2)

  const DOMGridBottomLeft = document.createElement('div')
  DOMGridBottomLeft.classList.add('bottom')
  DOMGridBottomLeft.classList.add('left')
  DOMGridBottomLeft.setAttribute('id', 'bottomLeft')
  const DOMinside3 = document.createElement('div')
  DOMinside3.classList.add('insideCell')
  DOMGridBottomLeft.append(DOMinside3)

  const DOMGridBottomRight = document.createElement('div')
  DOMGridBottomRight.classList.add('bottom')
  DOMGridBottomRight.classList.add('right')
  DOMGridBottomRight.setAttribute('id', 'bottomRight')
  const DOMinside4 = document.createElement('div')
  DOMinside4.classList.add('insideCell')
  DOMGridBottomRight.append(DOMinside4)

  DOMGridContainer.append(DOMGridTopLeft)
  DOMGridContainer.append(DOMGridTopRight)
  DOMGridContainer.append(DOMGridBottomLeft)
  DOMGridContainer.append(DOMGridBottomRight)

  DOMelement.append(DOMGridContainer)

  DOMGridContainer.querySelectorAll('div').forEach(function (oneEl) {
    oneEl.addEventListener('scroll', function (scrollEvent) {
      // debugLog.log('scrollEvent.target.classList', scrollEvent.target.classList)
      // debugLog.log('scroll', scrollEvent, scrollEvent.target.scrollTop)
      // scrollEvent.target.scrollTop
      // scrollEvent.target.scrollLeft

      if (scrollEvent.target.classList.contains('top') && scrollEvent.target.classList.contains('left')) {
        // debugLog.log('top left')
        DOMGridTopRight.scroll({
          top: scrollEvent.target.scrollTop,
          behaviour: 'smooth'
        })
        DOMGridBottomLeft.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('top') && scrollEvent.target.classList.contains('right')) {
        // debugLog.log('top right')
        DOMGridTopLeft.scroll({
          top: scrollEvent.target.scrollTop,
          behaviour: 'smooth'
        })
        DOMGridBottomRight.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('bottom') && scrollEvent.target.classList.contains('left')) {
        // debugLog.log('bottom left')
        DOMGridBottomRight.scroll({
          top: scrollEvent.target.scrollTop,
          behaviour: 'smooth'
        })
        DOMGridTopLeft.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }

      if (scrollEvent.target.classList.contains('bottom') && scrollEvent.target.classList.contains('right')) {
        // debugLog.log('bottom right')
        DOMGridBottomLeft.scroll({
          top: scrollEvent.target.scrollTop,
          behaviour: 'smooth'
        })
        DOMGridTopRight.scroll({
          left: scrollEvent.target.scrollLeft
        })
      }
    })
  })
})

page('/experimentFontSize', function () {
  expFontSize.load()
})

page('/experimentSelector', function () {
  expFormSelector.load()
})

page('/editing', function () {
  const DOMelement = document.getElementById('content')
  if (!DOMelement) return

  const editor1 = document.createElement('div')
  const editor1DOMid = 'editor1'
  editor1.setAttribute('id', editor1DOMid)
  DOMelement.append(editor1)

  formulaEditing.load({
    containerId: editor1DOMid,
    includeDebug: true,
    isElementToBeAppended: true
  })

  const editor2 = document.createElement('div')
  const editor2DOMid = 'editor2'
  editor2.setAttribute('id', editor2DOMid)
  DOMelement.append(editor2)

  formulaEditing.load({
    containerId: editor2DOMid,
    includeDebug: true,
    isElementToBeAppended: true,
    initialFormula: '100 * {"itemId":"100","period":"-2"}'
  })

  const editor3 = document.createElement('div')
  const editor3DOMid = 'editor3'
  editor3.setAttribute('id', editor3DOMid)
  DOMelement.append(editor3)

  formulaEditing.load({
    containerId: editor3DOMid,
    includeDebug: true,
    isElementToBeAppended: true,
    initialFormula: '{"itemId":"100","period":"-2"} * {"itemId":"100","period":"0"}'
  })

  const editor4 = document.createElement('div')
  const editor4DOMid = 'editor3'
  editor3.setAttribute('id', editor4DOMid)
  DOMelement.append(editor4)

  formulaEditing.load({
    containerId: editor4DOMid,
    includeDebug: true,
    isElementToBeAppended: true,
    initialFormula: '{"itemId":"100","period":"-2"} * 12 + {"itemId":"100","period":"@0:-1"}'
  })
})

page('*', function () {
  debugLog.log('other routes')

  // App Loader
  appStarter.pageLoader()
})

// Init the router
page()
