import * as axios from 'axios'

import * as envVar from '../../env.js'
import * as userModule from '../user.js'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('modal:timeframePicker')
// debugLog.disable()

debugLog.log('modelController')

async function createGuestUser () {
  const queryUrl = [envVar.apiUrl, 'user', 'guest']

  return axios.get(queryUrl.join('/'))
    .then(function (response) {
      debugLog.log('createGuestUser guest', response)

      userModule.loginUser(response)

      // Pass data to the final .then()
      return response.data
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
    .then(function (responseData) {
      debugLog.log('createGuestUser in any case', responseData)
      return true
    })

  // fetch('api/user/guest')
  //   .then(function (response) {
  //     // Pass the data to an Object
  //     return response.json()
  //   } )
  //   .then(function (responseData) {
  //     debugLog.log(responseData)
  //     location.reload()
  //   })
  //   .catch(function (error) {
  //     debugLog.warn('error', error)
  //   })
}

/*
  params
    .modelId: mandatory
    .isPublic: optional, if being update
*/
export function updateModel (params = {}) {
  debugLog.log('update model', params)

  const queryUrl = [envVar.apiUrl, 'models', params.modelId]

  const editBody = {}

  // Change isPublic
  if (typeof params.isPublic === 'boolean') {
    editBody.isPublic = params.isPublic
  }

  // Change timeframe
  if (typeof params.periodSettings === 'object') {
    editBody.periodSettings = params.periodSettings
  }

  // Change item sorting
  if (typeof params.sortedItemIds === 'object') {
    editBody.sortedItemIds = params.sortedItemIds
  }

  return axios.put(queryUrl.join('/'), editBody)
    .then(function (axiosResponse) {
      debugLog.log('updated', axiosResponse)
      return axiosResponse.data
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

/*
  params
    .modelId: mandatory
*/
export async function copyModel (params = {}) {
  debugLog.log('copy model', params)

  // If user is not logged in, we make a guest
  if (!userModule.userData()) {
    await createGuestUser()
  }

  const queryUrl = [envVar.apiUrl, 'models', params.modelId, 'copy']
  debugLog.log('queryUrl', queryUrl)

  const postBody = {
    copyHardedCodedValues: params.copySettings.copyHardedCodedValues,
    copyFormulas: params.copySettings.copyFormulas
  }

  return axios.post(queryUrl.join('/'), postBody)
    .then(function (response) {
      debugLog.log('copyModel response', response)

      // Pass data to the final .then()
      return response.data
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
    .then(function (responseData) {
      debugLog.log('copyModel in any case', responseData)

      return responseData.data
    })
}
