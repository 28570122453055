
import * as bootstrap from 'bootstrap'

// Calculation Modules
import * as modelAppFormatting from '../modelogic/formatting.js'

// CSS Grid Module
import * as cssGridApp from '../cssgrid-app.v1/css-grid.js'

import htmlSensitivityModal from './sensitivity-modal.html'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('sensitivity:webui')
// debugLog.disable()

/*
  Evaluate scenarios
  - changed variables
  - changed values

  TODO: Sort changed values from A to Z

  Prepare the object for cssGrid
*/

/*
  @params
    .instance
*/
export function showSensitivityTable (params) {
  debugLog.log('showSensitivityTable', params)
  const targetItem = findTargetItem(params)

  showModal({
    targetItem: targetItem
  })

  const gridData = prepareGrid({
    mainParams: params,
    targetItem: targetItem
  })

  const drawParams = {
    dataset: gridData,
    targetSelectorId: 'sensitivityValueTable',
    addClasses: ['drawnTable']
  }
  cssGridApp.drawTable(drawParams)
}

function findTargetItem (params) {
  // Find the Item for which sensitivity is being analysed
  const instanceData = params.instance.data
  const targetItem = instanceData.dataset.items.find(function (oneItem) {
    return params.instance.helpers.getItemId(oneItem) === params.targetItemId
  })
  // debugLog.log('targetItem', targetItem)
  return targetItem
}

//
//
function createModal () {
  const modalContainer = document.createElement('div')
  modalContainer.innerHTML = htmlSensitivityModal

  const contentContainer = document.getElementById('content')
  contentContainer.append(modalContainer)

  const myModalEl = document.getElementById('sensitivityTableModalDiv')
  return myModalEl
}

//
//
function showModal (params) {
  // debugLog.log('showModal', params)

  // Make a modal
  let myModalEl = document.getElementById('sensitivityTableModalDiv')
  if (!myModalEl) myModalEl = createModal()

  // Open the modal
  const modalComponent = new bootstrap.Modal(myModalEl)
  if (!modalComponent) return
  modalComponent.show()

  const modalTitle = myModalEl.querySelector('h5')
  modalTitle.innerText = 'Sensitivity of: ' + params.targetItem.name
}

/*

*/
function evaluateScenarios (params) {
  const instanceData = params.instance.data
  // const targetItemId = params.targetItemId

  const sensitivityInfos = {
    scenarioIds: [],
    changesPairIds: new Set(),
    changesPairs: [],
    variableChanges: new Set()
  }

  instanceData.dataset.scenarios.forEach(function (oneScenario) {
    // debugLog.log('oneScenario', oneScenario)
    if (!oneScenario.c_isAutoScenario) return
    if (oneScenario.c_type !== 'sensitivityValues') return
    // if (oneScenario.c_params?.itemId !== targetItemId) return // To evaluate only the sensitivity scenario of the current target?

    sensitivityInfos.scenarioIds.push(oneScenario._id)

    const pairId = [oneScenario.c_params.itemId, oneScenario.c_params.stepIndex].join('@')
    if (!sensitivityInfos.changesPairIds.has(pairId)) {
      sensitivityInfos.changesPairIds.add(pairId)
      sensitivityInfos.changesPairs.push({
        itemId: oneScenario.c_params.itemId,
        stepIndex: oneScenario.c_params.stepIndex
      })
    }

    sensitivityInfos.variableChanges.add(oneScenario.c_params.variableChange)
  })

  return sensitivityInfos
}

/*
  @params
    .instance
*/
function prepareGrid (fParams) {
  debugLog.log('prepareGrid', fParams)
  const params = fParams.mainParams

  const gridData = []

  const sensitivityInfos = evaluateScenarios(params)
  debugLog.log('sensitivityInfos', sensitivityInfos)

  const initialScenario = params.instance.data.dataset.scenarios.find(function (oneScenario) {
    return oneScenario._id === params.scenarioId
  })

  // Header
  const headerRow = prepareGridHeader(sensitivityInfos)
  gridData.push(headerRow)

  // Each Value changed
  sensitivityInfos.changesPairs.forEach(function (changePair) {
    const rowData = sensitivityScenarioResults({
      instance: params.instance,
      changePair: changePair,
      initialScenario: initialScenario,
      sensitivityInfos: sensitivityInfos,
      targetItem: fParams.targetItem
    })
    gridData.push(rowData)
  })

  return gridData
}

//
//
function prepareGridHeader (sensitivityInfos) {
  const headerData = []

  // For item name
  headerData.push({
    columnPosition: 'left',
    rowPosition: 'header',
    value: 'Item'
  })
  // For item period of change
  headerData.push({
    columnPosition: 'left',
    rowPosition: 'header',
    value: 'Value Period'
  })
  // For item initial value
  headerData.push({
    columnPosition: 'left',
    rowPosition: 'header',
    value: 'Base Value'
  })

  // for every variable change values
  const changeValues = sensitivityInfos.variableChanges
  changeValues.forEach(function (oneChangeValue) {
    const oneHeaderCell = {}

    const changeValueFormatted = modelAppFormatting.formatPct({ value: oneChangeValue })

    oneHeaderCell.value = changeValueFormatted
    oneHeaderCell.columnPosition = 'center'
    oneHeaderCell.rowPosition = 'header'

    headerData.push(oneHeaderCell)
  })

  return headerData
}

//
//
function sensitivityScenarioResults (params) {
  debugLog.log('sensitivityScenarioResults', params)
  const rowData = []

  const instanceData = params.instance.data
  // This whould return multiple scenarios, but we only care about the item and period of change, so that's fine
  const changedScenario = instanceData.dataset.scenarios.find(function (oneScenario) {
    const isItem = oneScenario.c_params?.itemId === params.changePair.itemId
    const isStepIndex = oneScenario.c_params?.stepIndex === params.changePair.stepIndex

    return isItem && isStepIndex
  })
  // debugLog.log('changedScenario', changedScenario)

  const changedItem = params.instance.data.dataset.items.find(function (oneItem) {
    return oneItem._id === changedScenario.c_params?.changedItem?._id
  })
  // debugLog.log('changedItem', changedItem)

  const targetItem = params.targetItem
  const targetStepIndex = instanceData.timeseries.periodSeries.length - 1 // We target the last period currently calculated
  // debugLog.log('targetItem', targetItem)

  // For item name
  rowData.push({
    columnPosition: 'left',
    rowPosition: 'content',
    value: changedScenario.c_params?.changedItem?.name
  })
  // For item period of change
  rowData.push({
    columnPosition: 'left',
    rowPosition: 'content',
    value: instanceData.timeseries.periodSeries[changedScenario.c_params?.stepIndex]
  })
  // For item initial value
  const initialValue = changedItem.byScenario[params.initialScenario._id].c_calculatedValues[changedScenario.c_params?.stepIndex]
  rowData.push({
    columnPosition: 'left',
    rowPosition: 'content',
    value: initialValue
  })

  // for every variable change values
  const changeValues = params.sensitivityInfos.variableChanges
  changeValues.forEach(function (oneChangeValue) {
    // Find the delta scenario
    const deltaScenario = findDeltaScenario({
      instance: params.instance,
      changedItemId: params.changePair.itemId,
      changedStepIndex: params.changePair.stepIndex,
      changedValue: oneChangeValue
    })

    const sensitivityOfValue = targetItem.byScenario[deltaScenario._id].c_calculatedValues[targetStepIndex]
    // debugLog.log('sensitivityOfValue', sensitivityOfValue)

    const oneCell = {}
    oneCell.value = modelAppFormatting.formatPctTrend({ value: sensitivityOfValue })
    oneCell.columnPosition = 'center'
    oneCell.rowPosition = 'content'

    rowData.push(oneCell)
  })

  return rowData
}

function findDeltaScenario (params) {
  // debugLog.log('findDeltaScenario', params)
  const instanceData = params.instance.data

  const changedScenario = instanceData.dataset.scenarios.find(function (oneScenario) {
    const isItem = oneScenario.c_params?.itemId === params.changedItemId
    const isStepIndex = oneScenario.c_params?.stepIndex === params.changedStepIndex
    const isChangeValue = oneScenario.c_params?.variableChange === params.changedValue
    // c_type === 'sensitivityDelta'

    return isItem && isStepIndex && isChangeValue
  })

  const deltaScenario = instanceData.dataset.scenarios.find(function (oneScenario) {
    return oneScenario.c_params?.scenarioId2 === changedScenario?._id
  })
  // debugLog.log('deltaScenario', deltaScenario)

  return deltaScenario
}
