
import * as pageRouter from '../page-router.js'

import * as modelModule from './control-models.js'
import * as userModule from '../user.js'

import * as helpers from '../helpers.js'

let componentParams = {}

function generatePublicButton (oneModel) {
  let elementHtml = '<button class="btn btn-sm TARGET_CLASS TARGET_ACTION_CLASS" d-isPublic=TARGET_ISPUBLIC d-modelId="' + oneModel._id + '">TARGET_WORDING</button>'

  const targets = {
    TARGET_CLASS: ['btn-warning', 'btn-primary', 'btn-light'],
    TARGET_ISPUBLIC: [true, false, null],
    TARGET_WORDING: ['Make Public', 'Make Private', 'Loading'],
    TARGET_ACTION_CLASS: ['updateIsPublic', 'updateIsPublic', '']
  }

  const isPublic = !!oneModel.isPublic
  const targetKey = oneModel.isLoading ? 2 : 1 * isPublic

  Object.keys(targets).forEach(function (oneTargetKey) {
    elementHtml = elementHtml.replace(oneTargetKey, targets[oneTargetKey][targetKey])
  })
  return elementHtml
}

function generateModelHtml (params, oneModel) {
  const html = []

  const targetUrl = helpers.modelUrlPattern(oneModel._id)
  html.push('<a href="' + targetUrl + '">' + oneModel.name + '</a>')

  const userData = userModule.userData()
  if (userData) {
    if (params.page === 'me' && oneModel.userRights.canEdit) {
      html.push('  ~  ')
      if (oneModel.isPublic) {
        html.push('Public ')
        html.push(generatePublicButton(oneModel))
      } else {
        html.push('Private ')
        html.push(generatePublicButton(oneModel))
      }
    }
  }

  if (oneModel.userRights.canCopy) {
    html.push('  ~  ')
    html.push('<button class="btn btn-sm btn-primary copyModel" d-modelId="' + oneModel._id + '">Copy</button>')
  }

  html.push('<br>id: ' + oneModel._id)
  return html.join('')
}

function showOneModel (params, oneModel) {
  // console.log('showOneModel', params, oneModel)
  const html = []

  html.push('<li class="oneModel">')
  html.push(generateModelHtml(params, oneModel))
  html.push('</li>')
  return html.join('')
}

//
function listenUpdatePublic (clickEvent) {
  const ButtonDOMelement = clickEvent.target.closest('.updateIsPublic')
  if (!ButtonDOMelement) return

  const newIsPublic = ButtonDOMelement.getAttribute('d-isPublic')
  const modelId = ButtonDOMelement.getAttribute('d-modelId')

  const modelDOMelement = clickEvent.target.closest('.oneModel')
  if (!modelDOMelement) return

  const params = {
    modelId: modelId,
    isPublic: newIsPublic === 'true'
  }

  // Loading state
  ButtonDOMelement.outerHTML = generatePublicButton({ isLoading: true, userRights: { canEdit: true } })

  modelModule.updateModel(params)
    .then(function (updatedModel) {
      // console.log('updated', updatedModel)
      if (!updatedModel) return

      const oneModel = updatedModel.data

      // Update the listing
      const newElementContent = generateModelHtml(componentParams, oneModel)
      modelDOMelement.innerHTML = newElementContent
    })
}

//
function listenCopy (clickEvent) {
  const DOMelement = clickEvent.target.closest('.copyModel')
  if (!DOMelement) return

  const modelId = DOMelement.getAttribute('d-modelId')

  const params = {
    modelId: modelId
  }

  modelModule.copyModel(params)
    .then(function (createdModel) {
      console.log('copied?', createdModel)

      // Ideally we would just refresh the component itself
      // location.reload()

      // Go to the page of the copied model
      const createdModelId = createdModel._id
      const nextPage = [pageRouter.settings.baseRoute, pageRouter.settings.oneModel].join('').replace(':modelId', createdModelId)
      console.log('go to:', nextPage)
      page(nextPage)
    })
}

//
//
function addListeners () {
  const modelsListELement = document.getElementById('modelsList')
  if (!modelsListELement) return

  const isListenerAdded = modelsListELement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  modelsListELement.addEventListener('click', function (clickEvent) {
    console.log('click')

    listenUpdatePublic(clickEvent)
    listenCopy(clickEvent)
  })
  modelsListELement.setAttribute('isListenerAdded', true)
}

//
//
export function showModels (params = {}) {
  console.log('showModels', params)
  componentParams = params.params

  const html = []

  if (!params.models || params.models.length === 0) {
    html.push('<div class="alert alert-info" role="alert">')
    html.push('No Model available')
    html.push('</div>')
  }

  if (params.models && params.models.length > 0) {
    html.push('<ul>')

    params.models.forEach(function (oneModel) {
      html.push(showOneModel(params.params, oneModel))
    })
    html.push('<ul>')
  }

  document.getElementById('modelsList').innerHTML = html.join('')

  addListeners()
}
