// import * as axios from 'axios'
// import * as envVar from '../env.js'

import * as userModule from './user.js'

import * as socketIO from './socket.js'
import * as pageFooter from '../page-modules/footer.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('app:starter')
// debugLog.disable()

// Track that User has been loaded (So navigating from one page to another does not force a reload)
let isUserLoaded = false

//
/*
  Initial load needed before the page can execute
  params
    .loadUser: true (default) or false
    .loadModel: TODO
*/
export async function pageLoader (params = {}) {
  debugLog.log('*** pageLoader', 'isUserLoaded', isUserLoaded, params)

  socketIO.launchSockets()
  pageFooter.launchFooter()

  const loaders = []

  // Load User by default
  const isForceLoadUser = params.loadUser === true
  const isDefaultLoadUserWhenNotAlready = typeof params.loadUser === 'undefined' && !isUserLoaded
  if (isForceLoadUser || isDefaultLoadUserWhenNotAlready) {
    const loaderUser = userModule.loadUser().then(function () {
      debugLog.log('*** pageLoader inside then')
      isUserLoaded = true
      return 1
    })

    loaders.push(loaderUser)
  }

  return Promise.all(loaders)
}
