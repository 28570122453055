// Parsing of formula

import * as modelAppHelpers from './helpers.js'

import * as logicFormulaPeriods from './logic-formula-periods.js'

// import { parse, typeOf, SymbolNode } from 'mathjs'
// const math = {
//   parse: parse,
//   typeOf: typeOf,
//   SymbolNode: SymbolNode
// }
// MathJS is ~2MB
import * as math from 'mathjs'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('formula:logic')
debugLog.disable()

/*
  @formulaObj: {
    formula
  }

  Returns
  @formulaObj: {
    formula
    c_refObj: [] for each part of the formula
  }
*/
export function compileFormula (formulaObj = {}) {
  debugLog.log('compileFormula', formulaObj)
  const node = math.parse(formulaObj.formula)
  formulaObj.c_mathParsed = node

  // Reset the refObj when it had been set (when not set, it should not be: only when there are nodes isObjectNode)
  if (formulaObj.c_refObj) formulaObj.c_refObj = []

  const transformed = node.transform(function (node, path, parent) {
    debugLog.log('transform,', math.typeOf(node), node)
    debugLog.log(path)
    debugLog.log(parent)
    if (node.isObjectNode) {
      return manipulateObjectNode(formulaObj, node)
    }
    return node
  })
  formulaObj.c_mathParsedTransformed = transformed

  // Evaluate special values
  if (Number.isFinite(node.value)) {
    formulaObj.c_isSimpleValue = true
  }
  if (!formulaObj.c_refObj) {
    formulaObj.c_isHardCoded = true
  }

  formulaObj.c_compiled = transformed.compile()

  return formulaObj.c_compiled
}

/*
  Possible combinations
  itemId, isSinglePeriod : single item, single period
  itemId, !isSinglePeriod : single item, multiple periods
  itemId, !isSinglePeriod, function : single item, multiple periods, aggregation function
  itemId, refId, isSinglePeriod, scenarioId : single item, single period, in another scenario
  items, refId, isSinglePeriod, function : multiple items, single period
*/
function manipulateObjectNode (formulaObj, node) {
  debugLog.log('isObjectNode')
  debugLog.log(node)

  const refObj = {}
  refObj.compiled = node.properties // The properties as seen by MathJS

  // Analyse the period
  refObj.period = logicFormulaPeriods.parsePeriodsReference(node.properties.period.value)
  const isSinglePeriod = refObj.period.length === 1

  // For formulas across rows, we use refId to pass the results to the evaluator, and items for the filter
  // We use the RefId if it's past, or we generate one
  if (node.properties.refId) {
    refObj.refId = useNameOrValue(node, 'refId')
  } else {
    refObj.refId = modelAppHelpers.generateRandomString()
  }

  // One Item
  if (node.properties.itemId) {
    refObj.itemId = useNameOrValue(node, 'itemId')
  //
  } else if (node.properties.items) {
    // Multiple Items:
    refObj.items = useNameOrValue(node, 'items')

    if (!isSinglePeriod) debugLog.warn('a single period is needed for multiple items')
  }

  // Store the function if there is
  if (node.properties.function) {
    refObj.function = useNameOrValue(node, 'function')
  }

  // Formula to another scenario
  if (node.properties.scenarioId) {
    refObj.scenarioId = useNameOrValue(node, 'scenarioId')
    if (!refObj.itemId) debugLog.warn('a single item needs to be targeted in scenario ref')
    // if (!isSinglePeriod) debugLog.warn('a single period is needed for scenario ref') // This should not be blocking
  }

  // Store the ref object so that we can calculate the value to use based on period
  formulaObj.c_refObj = formulaObj.c_refObj || [] // Set empty array on first occurence
  formulaObj.c_refObj.push(refObj)

  return new math.SymbolNode(refObj.refId) // Pass a value which can be set at evaluation time
  // return new math.ConstantNode(value) // Pass a value directly
}

//
//
// mathJS uses either .name or .value for the different nodes
function useNameOrValue (node, propertyName) {
  // Not sure when this would happen
  // if (!node?.properties?.[propertyName]) {
  //   debugLog.warn('!! poorly passed data:', node, ' propertyName:', propertyName)
  //   return false
  // }

  if (node.properties[propertyName].name) {
    return node.properties[propertyName].name
  } else if (node.properties[propertyName].value) {
    return node.properties[propertyName].value
  }
  // Not sure when this would happen
  debugLog.warn('!! no value found for:', propertyName, ' in :', node)
  return false
}

export function evaluate (params = {}) {
  debugLog.log('evaluate()', params)
  const formulaObj = params.formulaObj
  const evalScope = params.evalScope || {}

  const evaluationObj = {
    value: null
  }

  if (!evalScope) {
    evaluationObj.isError = true
    evaluationObj.note = 'no evalScope'
    return evaluationObj
  }

  // Full parsing of MathJS
  // https://mathjs.org/docs/expressions/expression_trees.html
  // ParenthesisNode: { .content }
  // SymbolNode: {node.type, node.name}
  // ConstantNode: {node.type, node.value}
  // FunctionNode // eg. sqrt
  // OperatorNode: {node.type, node.op}
  let isEvalScopeComplete = true
  let isValueToReturn = false
  formulaObj.c_mathParsedTransformed.traverse(function (node, path, parent) {
    debugLog.log(node.type, node, parent)
    if (node.type !== 'SymbolNode') return
    if (Number.isFinite(evalScope[node.name])) return
    if (evalScope[node.name] === false) {
      // debugLog.log('false!', node.name, evalScope, evalScope[node.name])
      isValueToReturn = true

      evaluationObj.value = false
      evaluationObj.isError = true
      evaluationObj.note = 'reference data has false value ie error'
      return
    }

    if (!parent) {
      // isEvalScopeComplete = false
      isValueToReturn = true
      return
    }

    // Force value to 0 when not defined so far, but used for a + or - (ie formulas with + or - of null values will still go through)
    if (['+', '-'].includes(parent.op)) {
      evalScope[node.name] = 0
      return
    }

    debugLog.log('incorrect value')
    isEvalScopeComplete = false
  })
  if (isValueToReturn) {
    return evaluationObj
  }

  if (!isEvalScopeComplete) {
    debugLog.log('!isEvalScopeComplete ... stop here')
    evaluationObj.isError = true
    evaluationObj.note = 'Incomplete evalScope'
    return evaluationObj
  }

  // If the scope if correct, try full evaluation
  try {
    debugLog.log('try full evaluation')
    evaluationObj.value = formulaObj.c_compiled.evaluate(evalScope)
  } catch (error) {
    debugLog.warn('! Error', error.message)
    evaluationObj.isError = true
    evaluationObj.note = error.message
  }
  debugLog.log(evaluationObj, formulaObj)

  // Avoid JS float issues: using 4 decimals precisions
  if (Number.isFinite(evaluationObj.value)) {
    evaluationObj.value = parseFloat(evaluationObj.value.toFixed(4))
  }

  return evaluationObj
}
