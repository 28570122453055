
import * as axios from 'axios'

import * as envVar from '../env.js'

import form from './form.html'

import * as fileUpload from '../file-upload/file-index.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('model:creation')
// debugLog.disable()

//
export function showModelCreateForm () {
  debugLog.log('showModelCreateForm')

  const DOMelement = document.getElementById('modelCreation')
  if (!DOMelement) return

  const formElement = document.createElement('div')
  formElement.innerHTML = form

  DOMelement.innerHTML = ''
  DOMelement.append(formElement)

  addCreationListener()
  listenToFileUpload()
}

//
function addCreationListener () {
  debugLog.log('addCreationListener')
  // Create Model
  const createButton = document.getElementById('button_model_create')
  if (!createButton) return

  createButton.addEventListener('click', function (event) {
    debugLog.log('click')
    // Avoid page reload on form submission
    event.preventDefault()

    createModelAction()
  })
}

function createModelAction () {
  const fieldElement = document.getElementById('model_name')
  const pickPeriodicity = document.getElementById('pickPeriodicity')
  debugLog.log('fieldElement', fieldElement)
  debugLog.log(fieldElement.value)

  const queryUrl = envVar.apiUrl + '/models'

  const postParams = {
    name: fieldElement.value,
    periodicity: pickPeriodicity.value
  }
  axios.post(queryUrl, postParams)
    .then(function (response) {
      debugLog.log('response', response)

      // Refresh Models section
      location.reload()
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

function listenToFileUpload () {
  debugLog.log('listenToFileUpload')
  const DOMelement = document.getElementById('modelThroughUpload')
  debugLog.log('DOMelement', DOMelement)
  if (!DOMelement) return

  const filePickerElement = DOMelement.querySelector('input')
  debugLog.log('filePickerElement', filePickerElement)
  filePickerElement.addEventListener('change', function (event) {
    debugLog.log('filePicker e', event)
    fileUpload.handleFile(event)
  })
}
