
import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('cssGrid:main:v1:helper')
debugLog.disable()

//
//
export function generateAttributes (params = {}) {
  debugLog.log('generateAttributes', params)
  params.elementAttributes = []

  const objectInfo = params.info || params.data?.info
  debugLog.log('objectInfo', objectInfo)
  if (!objectInfo) return

  params.data.info.forEach(function (oneInfo) {
    for (const key in oneInfo) {
      params.elementAttributes.push(['d-' + key, oneInfo[key]])
    }
  })
}
