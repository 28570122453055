
// Calculation Modules
import * as periods from '../modelogic/periods.js'
import * as modelAppHelpers from '../modelogic/helpers.js'

import * as modelappWebUIModule from './web-ui.js'

// Web Frontend UIs
import * as moduleItemController from '../frontend/items/control-items.js'
import * as itemFormulaModule from '../frontend/itemformulas/control-itemformulas.js'

// Context Menu
import * as contextMenu from './context-menu/context-menu.js'

import * as generalHelpers from '../general-helpers.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('model:webui:listener')
// debugLog.disable()

//
export function clickListener (clickEvent) {
  // Identifies the cell where the click happened
  // Returns the first element matching, even if click happened in a child DOM element
  const actualCellElement = clickEvent.target.closest('.TLcell')
  if (!actualCellElement) return

  const cellParams = identifyCellParams(actualCellElement)

  // Get the item object if available
  cellParams.clickedItem = findClickedItem({
    items: appData.modelInstance.data.dataset.items,
    targetItemId: cellParams.itemId
  })

  if (!cellParams.clickedItem) {
    manageHeaderClick(cellParams)
  } else {
    manageItemClick(cellParams)
  }
}

// export function hoverListener (hoverEvent) {
//   debugLog.log('hoverListener')
//   // Identifies the cell where the hover happened
//   // Returns the first element matching, even if click happened in a child DOM element
//   const actualCellElement = hoverEvent.target.closest('.TLcell')
//   if (!actualCellElement) return

//   const cellParams = identifyCellParams(actualCellElement)
//   debugLog.log('cellParams', cellParams)
//   debugLog.log('actualCellElement', actualCellElement)

//   // Find the row of the item
//   // TODO: Use element.parent to go through without reading the DOM for performance?
//   const rowElement = document.querySelector('div.gridOneRow[d-itemid="'+cellParams.itemId+'"]')
//   if (!rowElement) return

//   rowElement.classList.toggle('bold')
// }

export function rowHoverListener (hoverEvent) {
  debugLog.log('rowHoverListener')

  // Identifies the row where the hover happened
  // Returns the first element matching, even if click happened in a child DOM element
  const actualRowElement = hoverEvent.target.closest('.gridOneRow')
  if (!actualRowElement) return

  actualRowElement.classList.toggle('isRowHighlight')
}

//
export function contextMenuListener (params) {
  debugLog.log('contextMenuListener', params)
  const event = params.event
  const tableJS = params.modelInstance?.context?.tableJS

  // Identifies the cell where the click happened
  // Returns the first element matching, even if click happened in a child DOM element
  const actualCellElement = event.target.closest('.TLcell')
  if (!actualCellElement) {
    // Hide the popup
    contextMenu.hideContextMenu()
    return
  }

  const cellParams = identifyCellParams(actualCellElement)

  // Avoid usual right click menu
  event.preventDefault()

  const targetCellJS = getCellJS({
    cellParams: cellParams,
    tableJS: tableJS
  })

  if (cellParams.itemId) {
    debugLog.log('contextMenu', cellParams)
    debugLog.log('event', event)
    debugLog.log('actualCellElement', actualCellElement.innerText, actualCellElement)

    const eventTriggerParams = {
      pointer: {
        x: event.x || event.clientX || event.pageX,
        y: event.y || event.clientY || event.pageY
      },
      offset: {
        left: actualCellElement.offsetLeft,
        top: actualCellElement.offsetTop,
        height: actualCellElement.offsetHeight,
        width: actualCellElement.offsetWidth
      }
    }

    contextMenu.showContextMenu(
      {
        eventTriggerParams,
        targetCellJS
      }
    )
  }
}

function getCellJS (params = {}) {
  // debugLog.log('getCellJS', params)
  const cellParams = params.cellParams
  const tableJS = params.tableJS

  if (!cellParams.cellId) return

  let targetCellJS

  tableJS.forEach(function (oneRow) {
    if (targetCellJS) return
    const rowCells = oneRow.cells || oneRow
    rowCells.forEach(function (oneCell) {
      if (targetCellJS) return

      if (oneCell.cellId === cellParams.cellId) {
        targetCellJS = oneCell
      }
    })
  })

  return targetCellJS
}

function identifyCellParams (cellElement) {
  const cellParams = {
    actualCellElement: cellElement,
    scenarioId: cellElement.getAttribute('d-scenarioId'),
    itemId: cellElement.getAttribute('d-itemId'),
    stepIndex: cellElement.getAttribute('d-stepIndex'),
    hardCoded: generalHelpers.toBoolean(cellElement.getAttribute('d-hardCoded')),
    callback: cellElement.getAttribute('d-callback'),
    cellId: cellElement.getAttribute('d-cellId')
  }
  cellParams.stepIndex = (cellParams.stepIndex === null) ? null : +cellParams.stepIndex
  // debugLog.log('cellParams', cellParams)

  return cellParams
}

//
//
function findClickedItem (params = {}) {
  const modelItems = params.items
  if (!modelItems) return

  const clickedItem = modelItems.find(function (oneItem) {
    return modelAppHelpers.getItemId(oneItem) === params.targetItemId
  })
  return clickedItem
}

function manageHeaderClick (cellParams = {}) {
  debugLog.log('clicked header row')
  cellParams.action = cellParams.actualCellElement.getAttribute('d-action')
  cellParams.target = cellParams.actualCellElement.getAttribute('d-target')

  const change = Number.isFinite(+cellParams.target) ? +cellParams.target : 1

  const timeSeries = appData.modelInstance.data.timeseries
  const timeSeriesUsedSettings = timeSeries.usedSettings
  // debugLog.log(timeSeries)

  if (cellParams.action === 'period:pick') {
    debugLog.log('period:pick')
    const availablePeriodicity = periods.availablePeriodicity()
    const currentPeriodicity = availablePeriodicity.indexOf(timeSeriesUsedSettings.periodicity)

    const newPeriodicity = currentPeriodicity + 1 >= availablePeriodicity.length ? 0 : currentPeriodicity + 1
    timeSeriesUsedSettings.periodicity = availablePeriodicity[newPeriodicity]
    appData.modelInstance.updateTimeSeriesSettings(timeSeriesUsedSettings)
    modelappWebUIModule.runAppCalculations()
  }

  if (cellParams.action === 'period:add') {
    debugLog.log('period:add')
    timeSeriesUsedSettings.periods = timeSeriesUsedSettings.periods + change
    appData.modelInstance.updateTimeSeriesSettings(timeSeriesUsedSettings)
    modelappWebUIModule.runAppCalculations()
  //
  } else if (cellParams.action === 'period:move') {
    debugLog.log('period:move')
    debugLog.log('timeSeriesUsedSettings', JSON.parse(JSON.stringify(timeSeriesUsedSettings)))
    const periodicity = timeSeriesUsedSettings.periodicity
    const moveFunction = periods.periodicityFunctions[periodicity]
    if (!moveFunction) return

    const currentPeriod = timeSeriesUsedSettings.startingView
    const newStarting = moveFunction(currentPeriod, change)
    timeSeriesUsedSettings.starting = newStarting
    timeSeriesUsedSettings.periods = timeSeriesUsedSettings.periodsView
    appData.modelInstance.updateTimeSeriesSettings(timeSeriesUsedSettings)
    modelappWebUIModule.runAppCalculations()
  }
}

//
//
function manageItemClick (cellParams = {}) {
  if (Number.isFinite(cellParams.stepIndex) && cellParams.scenarioId) {
    manageItemValueClick(cellParams)
  } else {
    manageItemItselfClick(cellParams)
  }
}

//
//
function manageItemValueClick (cellParams = {}) {
  debugLog.log('clicked period columns cells')
  itemFormulaModule.openEditingForm(cellParams)
}

//
//
function manageItemItselfClick (cellParams = {}) {
  // Clicked left column
  debugLog.log('clicked Left columns')

  debugLog.log('clicked item')

  const editingParams = {
    itemId: cellParams.itemId,
    instance: appData.modelInstance
  }
  moduleItemController.openEditingForm(editingParams)
}
