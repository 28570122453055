
import * as axios from 'axios'

import * as envVar from '../../env.js'
import * as helperModule from '../helpers.js'
import * as editorComponent from '../../formula-editor/index.js'

// Modelapp, Web UI
import * as modelappWebUIModule from '../../modelapp-ui/web-ui.js'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('formula:controller')
// debugLog.disable()

// Name DOM elements
export const createItemFormulaButtonId = 'itemformula_create_button'

let editorId
//
export function openEditingForm (params) {
  const itemId = params.itemId
  const scenarioId = params.scenarioId
  const stepIndex = params.stepIndex
  const clickedItem = params.clickedItem

  const itemScenarioData = clickedItem.byScenario[scenarioId]

  const modelInstance = appData.modelInstance

  debugLog.log(clickedItem)
  debugLog.log('new formula?', itemScenarioData.c_stepFormulasChanges[stepIndex])
  debugLog.log('applied formula?', itemScenarioData.c_stepFormulas[stepIndex])
  debugLog.log('value?', itemScenarioData.c_calculatedValues[stepIndex])

  let formula = itemScenarioData.c_stepFormulasChanges[stepIndex] ? itemScenarioData.c_stepFormulasChanges[stepIndex].formula : ''

  formula = applyDefaults({
    formula: formula
  })
  editorId = editorComponent.load({
    containerId: 'itemFormulaInput',
    items: modelInstance.data.dataset.items,
    item: modelInstance.data.dataset.items.find(function (oneItem) { return oneItem._id === itemId }),
    instance: modelInstance,
    initialFormula: formula
  })
  listenToClear()
  listenToDelete({
    modelInstance: modelInstance
  })

  const itemFormulaId = itemScenarioData.c_stepFormulasChanges[stepIndex]._id

  document.querySelector('#itemFormulaEditForm #itemFormulaId').value = itemFormulaId || ''
  document.querySelector('#itemFormulaEditForm #itemFormulaItemId').value = itemId
  document.querySelector('#itemFormulaEditForm #itemFormulaScenarioId').value = scenarioId
  document.querySelector('#itemFormulaEditForm #itemFormulaStartingPeriod').value = modelInstance.data.timeseries.periodSeries[stepIndex]

  //
  if (itemFormulaId) {
    document.getElementById(createItemFormulaButtonId).innerText = 'Edit Formula'
  } else {
    document.getElementById(createItemFormulaButtonId).innerText = 'Save new Formula'
  }

  // Show
  document.getElementById('itemFormulaEditContainer').toggleAttribute('hidden', false)
}

//
//
function createOrEdit (params) {
  debugLog.log('itemformulasController.itemformulas createOrEdit')
  const itemFormulaId = document.getElementById('itemFormulaId').value

  if (itemFormulaId) {
    edit(params)
  } else {
    create(params)
  }
}

function retrieveFormulaEditorData () {
  const editorElement = document.getElementById('itemFormulaInput').querySelector('.formulaEditor')
  const editorId = editorElement.getAttribute('id')

  const editorData = editorComponent.returnEditorData(editorId)
  debugLog.log('editorData', editorData)
  return editorData
}

let defaults = {}
function storeDefaultForNextFormula (params = {}) {
  debugLog.log('storeDefaultForNextFormula', params)
  const formulaRepeat = params.formulaRepeat

  if (Number.isFinite(formulaRepeat)) {
    defaults.formulaRepeat = formulaRepeat
  } else {
    defaults = {}
  }
}

function applyDefaults (params = {}) {
  debugLog.log('applyDefaults', params, defaults)
  let formula = params.formula
  if (formula === '' && Number.isFinite(defaults.formulaRepeat)) {
    formula = 'repeat:' + defaults.formulaRepeat + ' '
  }
  return formula
}

//
//
function create (params = {}) {
  debugLog.log('itemformulasController.itemformulas create')
  const itemFormulaObj = retrieveFormulaEditorData().formulaObj

  const itemFormula = itemFormulaObj.formula
  const formulaRepeat = itemFormulaObj.repeat
  storeDefaultForNextFormula({
    formulaRepeat: formulaRepeat
  })

  const itemId = document.querySelector('#itemFormulaEditForm #itemFormulaItemId').value
  const scenarioId = document.getElementById('itemFormulaScenarioId').value
  const modelId = helperModule.readUrl({ targetProperty: 'modelId' })

  const startingPeriod = document.getElementById('itemFormulaStartingPeriod').value

  if (itemFormula && startingPeriod && scenarioId) {
    const queryUrl = [envVar.apiUrl, 'itemformulas']

    const creationBody = {
      tempId: helperModule.generateRandomString(8), // For local dataset
      formula: itemFormula,
      periods: {
        first_period: startingPeriod
      },
      modelId: modelId,
      scenarioId: scenarioId,
      itemId: itemId
    }
    if (formulaRepeat) {
      creationBody.periods.repeat_periods = formulaRepeat
    }

    // Hide the form - Before the request is done
    document.querySelector('#itemFormulaEditContainer').toggleAttribute('hidden', true)

    axios.post(queryUrl.join('/'), creationBody)
      .then(function (response) {
        // debugLog.log('response', response)
        const createdItemFormula = response.data.data
        refreshDataset({
          fullFormula: createdItemFormula,
          tempFormula: creationBody,
          instance: params.instance
        })

        modelappWebUIModule.runAppCalculations({
          scenarioId: appData.settings.view.scenarioId
        })
      })
      .catch(function (error) {
        debugLog.error('error', error)
      })
      .then(function () {
        debugLog.log('in any case')
        // Push the item to the dataset
        // refresh the model view
      })

    // Update while waiting for response

    // Push in dataset
    creationBody.isSynced = false

    const modelInstance = params.instance
    modelInstance.formulas.add(creationBody)

    modelappWebUIModule.runAppCalculations({
      scenarioId: appData.settings.view.scenarioId
    })
  }
}

function edit (params) {
  debugLog.log('itemformulasController.itemformulas edit')
  const itemFormula = retrieveFormulaEditorData().formulaObj.formula
  const itemFormulaId = document.getElementById('itemFormulaId').value

  const startingPeriod = document.getElementById('itemFormulaStartingPeriod').value

  if (itemFormulaId && itemFormula) {
    const queryUrl = [envVar.apiUrl, 'itemformulas', itemFormulaId]

    const editBody = {
      formula: itemFormula,
      itemFormulaId: itemFormulaId,
      periods: {
        first_period: startingPeriod
      }
    }

    axios.put(queryUrl.join('/'), editBody)
      .then(function (response) {
        // debugLog.log('response', response)
        const editedItemFormula = response.data.data
        refreshDataset({
          fullFormula: editedItemFormula,
          tempFormula: editBody,
          instance: params.instance
        })

        // Hide the form
        document.querySelector('#itemFormulaEditContainer').toggleAttribute('hidden', true)

        modelappWebUIModule.runAppCalculations({
          scenarioId: appData.settings.view.scenarioId
        })
      })
      .catch(function (error) {
        debugLog.error('error', error)
      })
      .then(function () {
        debugLog.log('in any case')
        // Push the item to the dataset
        // refresh the model view
      })

    // *** Update in dataset

    // Find the current formula
    const modelInstance = params.instance
    const editedItemFormula = modelInstance.data.dataset.valueFormulas.find(function (oneFormula) {
      return oneFormula._id === itemFormulaId
    })
    // Update the value to the edited one
    editedItemFormula.formula = itemFormula
    editedItemFormula.isSynced = false

    // No need... the object is update just above
    // refreshDataset(editedItemFormula, editBody)

    modelappWebUIModule.runAppCalculations({
      scenarioId: appData.settings.view.scenarioId
    })
  }
}

/*
  @params
    .instance
    .itemFormulaId
*/
export function deleteFormula (params) {
  debugLog.log('itemformulasController.itemformulas deleteFormula')
  const itemFormulaId = params.itemFormulaId
  if (!itemFormulaId) return

  const modelInstance = params.instance
  if (!modelInstance) return

  const editedItemFormula = modelInstance.data.dataset.valueFormulas.find(function (oneFormula) {
    return oneFormula._id === itemFormulaId
  })

  const queryUrl = [envVar.apiUrl, 'itemformulas', itemFormulaId]
  const editBody = {
    itemFormulaId: itemFormulaId
  }

  axios.delete(queryUrl.join('/'), editBody)
    .then(function (response) {
      modelInstance.formulas.remove(editedItemFormula)

      modelappWebUIModule.runAppCalculations({
        scenarioId: appData.settings.view.scenarioId
      })
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })

  // *** Update in dataset
  editedItemFormula.isSynced = false
  modelappWebUIModule.runAppCalculations({
    scenarioId: appData.settings.view.scenarioId
  })
}

// Update the stored formula with the latest one
export function refreshDataset (params) {
  const createdItemFormula = params.fullFormula
  const creationParams = params.tempFormula
  const modelInstance = params.instance
  modelInstance.helpers.prepareFormulaObj(createdItemFormula)
  modelInstance.data.dataset.valueFormulas.forEach(function (oneItemFormula, arrayIndex) {
    // When NEW: we have a tempId to compare
    const matchingTempId = (creationParams.tempId && oneItemFormula.tempId === creationParams.tempId)

    // When EDITING: we have the actual itemFormulaIs
    const matchingEditId = (creationParams.itemFormulaId && oneItemFormula._id === creationParams.itemFormulaId)
    if (matchingTempId || matchingEditId) {
      modelInstance.data.dataset.valueFormulas[arrayIndex] = createdItemFormula
    }
  })
}

export function listenToCreate (params) {
  debugLog.log('listenToCreate')
  const DOMelement = document.getElementById(createItemFormulaButtonId)
  if (!DOMelement) return

  const isListenerAdded = DOMelement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  DOMelement.addEventListener('click', function (event) {
    event.preventDefault()
    createOrEdit(params)
  })
  DOMelement.setAttribute('isListenerAdded', true)

  // document.getElementById('itemFormulaInput').addEventListener('keyup', function (event) {
  //   debugLog.log('itemFormulaInput')
  //   event.preventDefault()
  //   if (event.key === 13) {
  //     createOrEdit()
  //   }
  // })
}

//
//
export function listenToClear () {
  const DOMelement = document.getElementById('itemformula_clear')
  if (!DOMelement) return

  const isListenerAdded = DOMelement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  DOMelement.addEventListener('click', function (event) {
    event.preventDefault()
    editorComponent.clearEditor({
      editorId: editorId
    })
    debugLog.log('retrieveFormulaEditorData', retrieveFormulaEditorData())
  })
  DOMelement.setAttribute('isListenerAdded', true)
}

//
/*
  @params
    .modelInstance
*/
export function listenToDelete (params) {
  const DOMelement = document.getElementById('itemformula_delete')
  if (!DOMelement) return

  // Show/hide the delete button as needed
  const itemFormulaId = document.querySelector('#itemFormulaEditForm #itemFormulaId').value
  if (itemFormulaId === '') {
    DOMelement.toggleAttribute('hidden', true)
  } else {
    DOMelement.toggleAttribute('hidden', false)
  }

  const isListenerAdded = DOMelement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  DOMelement.addEventListener('click', function (event) {
    event.preventDefault()
    const itemFormulaId = document.getElementById('itemFormulaId').value
    deleteFormula({
      instance: params.modelInstance,
      itemFormulaId: itemFormulaId
    })
  })
  DOMelement.setAttribute('isListenerAdded', true)
}
