
import * as moduleModel from './model/model.js'
import * as moduleItemController from './items/control-items.js'
import * as itemformulasModule from './itemformulas/control-itemformulas.js'

import * as modelappWebUIModule from '../modelapp-ui/web-ui.js'
import * as pageFooter from '../page-modules/footer.js'

import * as consoleLogger from '../console-logger.js'
const debugLog = new consoleLogger.DebugLog('socket')
debugLog.disable()

// Socket.io
let socketClient

export function launchSockets () {
  socketClient = socketClient || io()
  // At this time, no socket.id is set
  debugLog.log('launchSockets', socketClient.id)

  // Send a Hi to the server
  socketClient.emit('socket:fromClient', { from: 'frontend' }, function (response) {
    debugLog.log('socket:fromClient acknowledgment:', response)
    debugLog.log('launchSockets socket:fromClient socket:', socketClient.id)
  })

  socketClient.on('connect', function () {
    debugLog.log('socket onConnect -', socketClient.id)
    pageFooter.loadSocketStatus()
    joinRooms()
  })

  socketClient.on('disconnect', function () {
    debugLog.log('socket onDisconnect', socketClient.id)
    pageFooter.loadSocketStatus()
  })

  socketClient.on('socket:fromServer', function () {
    debugLog.log('launchSockets socket:fromServer', socketClient.id)
  })

  socketClient.on('room:joined', function (message) {
    joinedModelSocket(message)
    pageFooter.loadSocketStatus()
  })

  socketClient.on('learnModelUpdate', function (message) {
    learnModelUpdate(message)
  })
}

export function socketId () {
  return socketClient.id
}

export function joinRooms (params) {
  debugLog.log('socket joinRooms -> addMeToRooms', params)
  const message = {
    request: true,
    modelId: params?.modelId
  }
  debugLog.log(message)
  socketClient.emit('rooms:addMe', message, function (response) {
    debugLog.log('socket joinRooms: joined', response)
    pageFooter.loadSocketStatus()
  })
}

function joinedModelSocket (message) {
  debugLog.log('socket joinedModelSocket', message)
}

function learnModelUpdate (message) {
  // debugLog.log('socket learnModelUpdate -- reminder socketId:', socketClient.id)
  debugLog.log('socket learnModelUpdate', message)

  const modelInstance = appData.modelInstance
  if (message.emittingSocketId === socketClient.id) {
    debugLog.warn('Socket Update from myself')
  }

  if (message.updateType === 'model:update') {
    moduleModel.updateModelHeader(message.updatedModel)
  //
  // **** UPDATE ON ITEM
  //
  } else if (message.updateType === 'item:created') {
    modelInstance.items.add(message.createdItem)
    modelappWebUIModule.runAppCalculations()
  } else if (message.updateType === 'item:updated') {
    moduleItemController.refreshDataset({
      fullItem: message.updatedItem,
      tempItem: { itemId: message.updatedItem._id },
      instance: modelInstance
    })
    modelappWebUIModule.runAppCalculations()
  //
  } else if (message.updateType === 'item:deleted') {
    modelInstance.items.remove(message.deletedItem)
    modelappWebUIModule.runAppCalculations()
  //
  // **** UPDATE ON SCENARIO
  //
  } else if (message.updateType === 'scenario:created') {
    modelInstance.update.scenario.add(message.createdScenario)
    modelappWebUIModule.runAppCalculations()
  //
  // **** UPDATE ON FORMULA
  //
  } else if (message.updateType === 'formula:created') {
    // Update the data
    modelInstance.formulas.add(message.createdFormula)
    modelappWebUIModule.runAppCalculations()
  //
  } else if (message.updateType === 'formula:updated') {
    // Update the data
    itemformulasModule.refreshDataset({
      fullFormula: message.updatedFormula,
      tempFormula: { itemFormulaId: message.updatedFormula._id },
      instance: appData.modelInstance
    })
    modelappWebUIModule.runAppCalculations()
  //
  } else if (message.updateType === 'formula:deleted') {
    modelInstance.formulas.remove(message.deletedFormula)
    modelappWebUIModule.runAppCalculations()
  }
}
