
import * as axios from 'axios'
import * as bootstrap from 'bootstrap'

import * as envVar from '../../env.js'

import * as helperModule from '../helpers.js'

import * as moduleScenarioPicker from './scenario-picker.js'

import * as consoleLogger from '../../console-logger.js'
const debugLog = new consoleLogger.DebugLog('scenario:controller')
// debugLog.disable()

const modalDOMElementId = 'scenarioCreationModal'
const feedbackElementId = 'scenarioCreationModalFeedback'

function closeModal () {
  debugLog.log('closeModal')
  const myModalEl = document.getElementById(modalDOMElementId)
  const modalComponent = bootstrap.Modal.getInstance(myModalEl) // Returns a Bootstrap modal instance
  modalComponent.hide()

  // For some reason the backdrop does not disappear
  const elements = document.querySelectorAll('.modal-backdrop')
  if (!elements) return

  elements.forEach(function (oneElement) {
    oneElement.remove()
  })
  // !! Causes Error: Cannot read property 'removeChild' of null
  // TODO FIx one day
}

function onModalOpen () {
  const myModalEl = document.getElementById(modalDOMElementId)

  // Initialise the Element as a Bootstrap Modal
  const modalComponent = new bootstrap.Modal(myModalEl)
  if (!modalComponent) {
    debugLog.warn('modalComponent was not available')
    return
  }

  myModalEl.addEventListener('shown.bs.modal', function (event) {
    const DOMelement = document.getElementById('scenarioNewName')
    if (!DOMelement) return
    DOMelement.focus()
  })
}

function onScenarioCreate (response) {
  document.getElementById('scenarioNewName').value = '' // Reset form

  if (response === 401) {
    const DOMelement = document.getElementById(feedbackElementId)
    DOMelement.innerHTML = '<div class="alert alert-danger" role="alert">You need to be logged in</div>'
    return
  }

  const modelInstance = appData.modelInstance
  const scenarioCreated = response.data.data
  modelInstance.update.scenario.add({
    scenario: scenarioCreated
  })

  moduleScenarioPicker.proposeScenarios({
    instance: modelInstance
  })

  closeModal()
}

//
function createScenario () {
  const scenarioName = document.getElementById('scenarioNewName').value
  if (!scenarioName || scenarioName.length < 3) {
    const DOMelement = document.getElementById(feedbackElementId)
    DOMelement.innerHTML = '<div class="alert alert-warning" role="alert">Please type a name</div>'
    return
  }

  const queryUrl = [envVar.apiUrl, 'scenarios']

  const params = {
    name: scenarioName,
    modelId: helperModule.readUrl({ targetProperty: 'modelId' })
  }

  axios.post(queryUrl.join('/'), params)
    .then(function (response) {
      debugLog.log('response', response)
      onScenarioCreate(response)
    })
    .catch(function (error) {
      debugLog.error('error', error)
    })
}

//
export function listenCallScenarioCreate () {
  debugLog.log('listenCallScenarioCreate')
  const DOMelement = document.getElementById('scenario_create_button')
  if (!DOMelement) return

  onModalOpen()

  const isListenerAdded = DOMelement.getAttribute('isListenerAdded')
  if (isListenerAdded) return

  DOMelement.addEventListener('click', function (event) {
    event.preventDefault()
    createScenario()
  })
  DOMelement.setAttribute('isListenerAdded', true)

  document.getElementById('scenarioNewName').addEventListener('keyup', function (event) {
    event.preventDefault()
    if ([13, 'Enter'].includes(event.key)) {
      createScenario()
    }
  })

  // Avoid form submit triggering the page reload
  document.querySelector('#scenarioEditForm').addEventListener('submit', function (event) {
    debugLog.log('submitted')
    event.preventDefault()
  })
}
